import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
/*Globales*/
import {urlApi} from '../../global/Global'

const RegistrarLicencia = () => {
    
    const arr = [
        {
            name: ""
        },
    ];
    const [paises, setPaises] = useState([]);
    const [carga, setCarga] = useState(false);
    //SweetAlert2
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        
        const getPaises = async () => {
            try {
                const get_paises = await axios.get('https://restcountries.eu/rest/v2/all');
                setPaises(get_paises.data)
            } catch (error) {
                console.log(error);
            }
        }
        getPaises();
    
    }, [])

    //Sweet alert
    const sweetAlert = (title, html, allowOutsideClick = true, showloading = false, icon='') => {
            MySwal.fire({
            title: title,
            icon: icon,
            html: html,
            allowOutsideClick: allowOutsideClick,
            didOpen: () => {
                if(showloading){
                    MySwal.showLoading();
                }
            }
        })
    }

   
    const sendData = async (params, actions) => {
        setCarga(true);
        await axios.post(urlApi+'registrar', params)
            .then((response) => {
                if (response.data.respuesta === 'correcto') {
                   setCarga(false);
                   sweetAlert('Correcto!', 'Tus licencias se validaron correctamente', true, false, 'success');
                   actions.resetForm({
                    values: {
                      // the type of `values` inferred to be Blog
                        correo:"",
                        nombre:"",
                        empresa:"",
                        telefono:"",
                        licencia:arr,
                        pais: "Mexico"
                    },
                  });
                }
                else if (response.data.respuesta === 'falso') {
                    setCarga(false);
                    sweetAlert('Error!', 'Una de tus licencias no es valida', true, false, 'warning')
                }
                else if (response.data.respuesta === 'vacio') {
                    setCarga(false);
                    sweetAlert('Error!', 'No envio licencias', true, false, 'warning')
                }
                console.log(response.data);
            })
            .catch((error) => {
                setCarga(false);
                sweetAlert('Error!', 'Una de tus licencias no es valida', true, false, 'warning')
                console.log(error);
            });      
    }

    
    Yup.addMethod(Yup.array, 'unique', function (message, mapper = a => a) {
        return this.test('unique', message, function (list) {
            return list.length === new Set(list.map(mapper)).size;
        });
    });
    
    //Validador de datos
    const formSchema = Yup.object().shape({
        correo: Yup.string()
            .required('Campo requerido')
            .email('Correo electronico invalido')
            .max(255, 'Maximo de caracteres'),
        nombre: Yup.string()
            .min(5, 'Minimo 5 caracteres')
            .max(255, 'Maximo de 255 caracteres')
            .required('Campo requerido'),
        empresa: Yup.string()
            .required('Campo requerido')
            .min(5, 'Minimo 5 caracteres')
            .max(255, 'Maximo de 255 caracteres'),
        telefono:   Yup.number('Debe ser un numero')
            .required('Campo requerido')
            .typeError('Debe ser un numero')
            .positive('No puede ser un numero negativo')
            .min(8, 'Minimo 8 caracteres'),
        pais: Yup.string()
            .required('Campo requerido'),
        licencia: Yup
                .array()
                .of(
                    Yup.object().shape({
                        name: Yup.string().required("Campo requerido")
          })
        ).unique('Codigo duplicado', a => a.name)
         .required('debes tener al menos una licencia')
         .min(1, 'Debe tener al menos una licencia'),
    });
    
    return (
        <div className="container my-5">
            <h2 className="fw-bold text-uppercase">¡Ya casi terminas! registra tu licencia</h2>
            <Formik
                enableReinitialize
                initialValues={{
                    correo:"",
                    nombre:"",
                    empresa:"",
                    telefono:"",
                    licencia:arr,
                    pais: "Mexico"
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendData(values, actions)}
                render={({ values, errors, touched, handleReset }) => {
                    // console.group("formik");
                    // console.log("touched", touched);
                    // console.log("values", values);
                    // console.groupEnd("formik");
                    return (
                        <Form>
                            <div className="row">
                                <div className="card m-auto mt-5 w-50-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between">
                                            <h5 className="card-title fw-bold text-uppercase">Registrar licencia</h5>
                                        </div>
                                        <div className="row mb-3">
                                        </div>
                                        <p className="card-text text-muted">Registra tu licencia para poder hacer uso de tus aplicaciones</p>
                                        {/* Datos de usuario */}
                                        <div className="mb-3">
                                            <label htmlFor="correo" className="form-label">Correo</label>
                                            <Field type="email" className="form-control" name="correo" placeholder="name@example.com"/>
                                            <ErrorMessage
                                                name='correo'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="nombre" className="form-label">Nombre</label>
                                            <Field type="text" className="form-control" name="nombre" placeholder="John Smith"/>
                                            <ErrorMessage
                                                name='nombre'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="empresa" className="form-label">Empresa</label>
                                            <Field type="text" className="form-control" name="empresa" placeholder="FIFA"/>
                                            <ErrorMessage
                                                name='empresa'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="telefono" className="form-label">Telefono</label>
                                            <Field type="text" className="form-control" name="telefono" placeholder="334455122"/>
                                            <ErrorMessage
                                                name='telefono'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="pais" className="form-label">País</label>
                                            <Field as="select" className="form-control select-custom" name="pais">
                                                {
                                                    paises.map((element,index) => {
                                                        return  <option
                                                                    value={element.name} 
                                                                    key={index}
                                                                    >{element.name}
                                                                </option>
                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='pais'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <FieldArray
                                                name="licencia"
                                                render={({insert, remove, push}) => ( 
                                                <>
                                                    <div className="row">
                                                            <label className="col-form-label col-md-10">Licencia (No repetir)</label>
                                                            <div className="col-sm-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary mb-3 form-control-plaintext"
                                                                    onClick={() => push({ name: "" })}
                                                                >+</button>
                                                            </div>
                                                    </div>
                                                    {values.licencia.map((element, index) => (
                                                        <div key={index}>
                                                            <div className="input-group mb-3">
                                                                <Field type="text" className="form-control" name={`licencia.${index}.name`} placeholder="GJH3G54KJG547HK47LL"/>
                                                                <button className="btn btn-dark" type="button" onClick={() => remove(index)}>-</button>
                                                            </div>
                                                            {/* {errors.licencia &&
                                                                errors.licencia[index] &&
                                                                errors.licencia[index].name && (
                                                                    <div className="field-error text-danger">
                                                                        {errors.licencia[index].name}
                                                                </div>
                                                            )} */}
                                                        <ErrorMessage
                                                            name={`licencia.${index}.name`}
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />
                                                    </div>
                                                    ))}
                                                    
                                                </>
                                                )}
                                            />
                                        </div>
                                        {/* FIN Datos de usuario */}
                                        <hr/>
                                        <div className="my-4">
                                            <button type="submit" 
                                                    className="btn btn-dark w-100 fw-bold text-uppercase"
                                                    // onClick={sendDataPaypal}
                                            >Registrar licencia</button>
                                        </div>
                                        {
                                            carga &&
                                            <div className="w-100 text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            />
        </div>
    )
};

export default RegistrarLicencia
