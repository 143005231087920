import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileInvoice, faEdit } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";

const Table = ({cliente}) => {
    return (
        <div className="d-flex justify-content-around w-100">     
                <Link className="btn btn-primary"
                    to={
                        {  
                            pathname:"/clientes/detalles",
                            state:{cliente: cliente}
                        }
                    }

                >
                        <FontAwesomeIcon icon={faFileInvoice} />  <span className="fw-bold"></span>     
                </Link>
                <Link className="btn btn-success"
                     to={
                        {  
                            pathname:"/clientes/edit",
                            state:{cliente: cliente}
                        }
                    }
                >
                        <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
                </Link>
            </div>
    )
}

export default Table
