import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEdit, faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";
/*Sweet alert*/
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {urlApi} from '../../global/Global'

const Table = ({software, softwares, setSoftwares, totalRows, setTotalRows}) => {

    const [carga, setCarga] = useState(false);
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);

    const handleClick = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        let title = '';
        let html = '';
        let icon = '';
        console.log(params)
        if (params==='delete') {
            title = '¿Desactivar software?';
            html = 'Usted podra activarlo mas adelante';
            icon = 'warning';
        }else{
            title = '¿Activar software?';
            html  = 'Usted podra desactivarlo mas adelante';
            icon  = 'warning';
        }

        const json_enviar = {
            software: software,
            params  : params
        }

        MySwal.fire({
            title: title,
            html: html,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
          }).then(async (result) => {
            if (result.isConfirmed) {
                setCarga(true)
                await axios.post(urlApi+'softwares/destroy', json_enviar, {headers: headers})
                                          .then(async (response) =>  { 
                                              if (response.data.respuesta==="correcto") {
                                                let t = '';
                                                let h = '';
                                                let i = '';
                                                if (response.data.value!==null) {
                                                        t = 'Desactivado!';
                                                        h = 'Se desactivo correctamente';
                                                        i = 'success';
                                                }else{
                                                        t = 'Restaurado!';
                                                        h = 'Se restauro correctamente';
                                                        i = 'success';
                                                }
                                                MySwal.fire(
                                                    t,
                                                    h,
                                                    i
                                                )
                                                // setSoftwares(softwares.filter(sw => sw.id !== response.data.id));
                                                setSoftwares(softwares.map(sw => 
                                                    sw.id === response.data.id 
                                                    ? {...sw, deleted_at : response.data.value}
                                                    : sw
                                                ));
                                                setTotalRows(totalRows-1);
                                                console.log(response.data);
                                              }else{
                                                MySwal.fire(
                                                    'Error!',
                                                    'Ocurrio un error, intentelo nuevamente',
                                                    'error'
                                                )
                                                console.log(response);
                                              }
                                          })
                                          .catch(error => {
                                            console.log(error);
                                          })
                                          .then(() => {
                                            setCarga(false)
                                          });
  
              }//Result
            });//then
    }
    

    return (
        <>
            {
            carga ?
                <div className="w-100 text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            :
                <div className="d-flex justify-content-around w-100">     
                    <Link className="btn btn-success"
                        to={
                            {  
                                pathname:"/softwares/edit",
                                state:{software: software}
                            }
                        }

                    >
                            <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
                    </Link>
                    {
                        (software.deleted_at == null || typeof(software.deleted_at)=='undefined')?
                            <button className="btn btn-primary"
                                    onClick={(e) => handleClick('delete')}
                            >
                                    <FontAwesomeIcon icon={faToggleOn}/>    
                            </button>
                        :
                            <button className="btn btn-danger" style={{opacity: .5}}
                                    onClick={(e) => handleClick('restore')}
                            >
                                    <FontAwesomeIcon icon={faToggleOff}/>    
                            </button>
                        
                    }
                    

                </div>
        }
        </>
    )
}

export default Table
