import React, {useEffect, useState} from 'react'
// import React, {useEffect, useState, useContext} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, {createTheme} from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'
import Table from './Table'
/*Globales*/
import {urlApi} from '../../global/Global'
import Auth from '../auth/Auth'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import Loading from '../../styles/Loading'
// //Importar state de context
// import LoginContext from '../../global/login/loginContext';
 //Filtrar texto
 const FilterComponent = ({ filterText, onFilter, onClear, cargaBtn }) => (
    <div className="input-group col-md-6 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        {
            !cargaBtn ?
                <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>
                    X
                </button>
            :
            <button className="btn btn-danger" type="button" id="button-addon1" disabled>
                    X
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                <span className="visually-hidden">Loading...</span>
            </button>
        }
    </div> 
);
const Clientes = () => {
    
    const [cargaBtn, setCargaBtn] = useState(false);
    const [clientes, setClientes] = useState([]);
    const [perClientes, setperClientes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    /*Filtros*/
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = clientes.filter(item => (item.nombre && item.nombre.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.empresa && item.empresa.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.correo && item.correo.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.telefono && item.telefono.toLowerCase().includes(filterText.toLowerCase()))
                                                 );
    //Paramas de string a enviar
    let string = '';
    /*Theme principal datatable*/
    createTheme('solarized', mainTheme);
    /*CONTEXT DE USUARIO */
    // const { headers } = useContext(LoginContext);
    //DATATABLE
    const columns = [
        {
            name: 'Nombre',
            selector: 'nombre',
            sortable: true,
        },
        {
            name: 'Correo',
            selector: 'correo',
            sortable: true,
        },
        {
            name: 'Empresa',
            selector: 'empresa',
            sortable: true,
        },
        {
            name: 'Telefono',
            selector: 'telefono',
            sortable: true,
        },
        {
            name: 'Registro de cliente',
            sortable: true,
            cell : row => {
                    const arr = row.created_at.split('T');
                    return <div>{arr[0]}</div>
            }
        },
        {
            name: 'Promociones',
            sortable: true,
            cell : row => (
                    row.promociones == 1 ?
                    <div className="d-flex align-items-center">
                        <p className="mb-0">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success"/> Si
                        </p>
                    </div>
                            :
                    <div className="d-flex align-items-center">
                        <p className="mb-0">
                            <FontAwesomeIcon icon={faTimesCircle} className="text-danger"/> No
                        </p>
                    </div>
            )
        },
        {
            name: 'Opciones',
            // width: '30%',
            cell: row => <Table cliente={row}/>,
        },
      ];

    //Componente de cabecera***************************************************************/
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setClientes(perClientes);
                string = '';
            }
        };

        return <FilterComponent 
                    onFilter={e => handleChangeFilter(e.target.value)} 
                    onClear={handleClear} 
                    filterText={filterText}
                    cargaBtn={cargaBtn} 
                />;
    }, [filterText, resetPaginationToggle, cargaBtn]);

     //************************************************************************************/
     const handleChangeFilter = async (e) => {
        // console.log(e.target.checked);
        setCargaBtn(true);
        setFilterText(e);
        string += e;
        console.log(string);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'clientes/filter', {page:1, perPage:10, string:string}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setClientes(response.data.cliente);
                setTotalRows(response.data.cliente_count);
                setLoading(false);
                // setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
                setCargaBtn(false);
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                setCargaBtn(false);
            });
    }

    //Primer acción ***************************************************************************/
    useEffect(() => {
        const getClientes = async (page) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }

            await axios.post(urlApi+'clientes/getclientes', {page:page, perPage:perPage}, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setClientes(response.data.cliente);
                    setperClientes(response.data.cliente);
                    setTotalRows(response.data.cliente_count);
                    setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error.data);
                });                    
        }
        getClientes(1);
    }, [])

     //Cambio de pagina************************************************************************/
     const handlePageChange = async page => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        await axios.post(urlApi+'clientes/getclientes', {page:page, perPage:perPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setClientes(response.data.cliente);
                // setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
    };

    //Cambio de rows**************************************************************************/
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
        await axios.post(urlApi+'clientes/getclientes', {page:page, perPage:newPerPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setClientes(response.data.cliente);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };

    //REDNDER********************************************************************************/
    return (
        <>
            <Auth />
                <div className="my-5 container">
                    <div className="border rounded-3">
                        <div className="p-3 table-responsive">
                            <div className="d-flex justify-content-between align-items-center">
                                <h1 className="fw-bold text-uppercase">Clientes</h1> 
                            </div>
                            <hr/>
                            <DataTable
                                // title="Licencias"
                                columns={columns}
                                data={clientes}
                                progressPending={loading}
                                progressComponent={<Loading />}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                // selectableRows
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                                theme='solarized'
                                customStyles={customStyles}
                                striped
                                subHeader
                                subHeaderComponent={subHeaderComponentMemo}
                                persistTableHead
                            />
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Clientes
