import React, {useEffect, useState, useRef} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
/*Globales*/
import {url, urlApi} from '../../global/Global'
import Particles from 'react-particles-js';
// import Auth from '../auth/Auth';
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faShare, faTimes } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
import {__paises} from './paises';
const Comprar = (props) => {
    
    //Redirect
    let history = useHistory();
    //Props
    // const software = props.location.state.software;
    const [software, setSoftware] = useState('')
    // const software = props.location.state.software;
    const [precio, setPrecio] = useState('');
    // const precio = software.amount; 
    const [checked, setchecked] = useState(true); 
    const [carga, setcarga] = useState(false); 
    const [checkedbool, setcheckedbool] = useState(1); 
    //Estados
    const [financialGoal, setFinancialGoal] = useState(1); //Cantidad de productos
    const [total, setTotal] = useState(''); //Total a pagar
    const [totalSinDescuento, setTotalSinDescuento] = useState(''); //Total a pagar SIN DESCUENTO
    //SweetAlert2
    const MySwal = withReactContent(Swal);
    const [paises, setPaises] = useState(__paises);
    // Codigo promocional
    const [codigo, setCodigo] = useState(false);
    const promoRef = useRef();
    const btnPromoRef = useRef();
    const [btnCodigo, setBtnCodigo] = useState(false);
    const [responseValidacion, setResponseValidacion] = useState({});
    const [codigoMayorQuePrecio, setCodigoMayorQuePrecio] = useState(false);
    const [btnEnviarCodigo, setBtnEnviarCodigo] = useState(false);
    const [preguntar, setpreguntar] = useState(false)
//******************************************************************************************** */
    useEffect(() => {
        window.scrollTo(0, 0)

        const getPaises = async (params) => {
            try {
                // const get_paises = await axios.get('https://restcountries.com/v2/all');
                // const get_paises = await axios.get('https://restcountries.com/v2/all');
                // setPaises(get_paises.data)
                // setPaises(paises)
                // console.log(get_paises.data[0].name);
            } catch (error) {
                console.log(error);
            }
        }
        // getPaises();

        setSoftware(props.location.state.software);
        setPrecio(props.location.state.software.amount)
        setTotal(props.location.state.software.amount)
        setTotalSinDescuento(props.location.state.software.amount)


    }, [])

    useEffect(() => {    
        const preguntarAntesDeSalir = () =>{
            if(preguntar){
                alert('Si cierra la ventana ahora la transacción no se completara ¿Seguro que quiere salir?')
                return "Si cierra la ventana ahora la transacción no se completara ¿Seguro que quiere salir?";
            }
        }
        window.onbeforeunload = preguntarAntesDeSalir;
        
    }, [preguntar])
    
    
//******************************************************************************************** */
const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,      
    maximumFractionDigits: 2,
 });
//******************************************************************************************** */
    //Funcion sweetalert de compras
    const sweetAlert = (title, html, allowOutsideClick = true, showloading = false, icon='') => {
         MySwal.fire({
            title: title,
            icon: icon,
            html: html,
            allowOutsideClick: allowOutsideClick,
            didOpen: () => {
                if(showloading){
                    MySwal.showLoading();
                }
            }
        })
    }
//******************************************************************************************** */

    const handleChecked = (e) => {
        setchecked(!checked)
        // console.log(e.target.checked)
        if(e.target.checked){
            setcheckedbool(1);
        }else{
            setcheckedbool(0);
        }
    }
    
//******************************************************************************************** */
    const handleClick = (e) => {
        setCodigo(true);
    }

//******************************************************************************************** */
    const handleClickBtnEnviarCodigo = (e) => {
        setBtnEnviarCodigo(false);
        console.log(promoRef.current.value);
        promoRef.current.value = '';
        setTotal(totalSinDescuento);
        setResponseValidacion({});
        setCodigoMayorQuePrecio(false);
    }

//******************************************************************************************** */
    const porcentaje = (response, _fg = false, cant = 0) => {
        //tipo general
        let total_descuento;
        if (response.descripcion.individual_general === 'general') {
            if (_fg === false) {
                total_descuento = response.descripcion.cantidad*totalSinDescuento/100;
            }else{
                total_descuento = response.descripcion.cantidad*_fg/100;
            }
            setCodigoMayorQuePrecio(false);
            return total_descuento;
        }//tipo individual
        else if (response.descripcion.individual_general === 'individual') {

            if(cant == 0) cant = financialGoal;
            
            if (_fg === false) {
                total_descuento = response.descripcion.cantidad*precio/100;
            }else{
                total_descuento = (response.descripcion.cantidad*precio/100)*cant;
                console.group('INICIO porcentaje individual')
                console.log('dolares individual');
                console.log('Total descuento: '+ total_descuento);
                console.log('Precio: '+ precio);
                console.log('response.cantidad: '+ response.descripcion.cantidad);
                console.log('cant financial: '+ financialGoal);
                console.groupEnd('FIN')
                
            }
            setCodigoMayorQuePrecio(false);
            console.log('porcentaje individual');
            return total_descuento;
        }
    }

//******************************************************************************************** */
    const dolares = (response, _fg = false) => {
        let total_descuento;
        let total_sin_descuento;
        //tipo general
        if (response.descripcion.individual_general === 'general') {
            total_descuento     = response.descripcion.cantidad;
            total_sin_descuento = (precio * _fg);

            console.group('INICIO')
            console.log('dolares general')
            console.log('Total descuento: '+ total_descuento);
            console.log('cant financial: '+ _fg);
            console.groupEnd('FIN');

            if (_fg === false) {
                return total_descuento;
            }
            else{
                if (total_sin_descuento < total_descuento) {
                    console.log('descuento MAYOR que total')
                    setCodigoMayorQuePrecio(true);
                    return 0;
                }else{
                    setCodigoMayorQuePrecio(false);
                    console.log('descuento MENOR que total')
                    return total_descuento;
                }
            }
            
        }
        //tipo individual
        if (response.descripcion.individual_general === 'individual') {
            total_descuento     = (response.descripcion.cantidad * _fg);
            total_sin_descuento = (precio * _fg);
            
            console.group('INICIO')
            console.log('dolares individual');
            console.log('Total descuento: '+ total_descuento);
            console.log('Total sin descuento: '+ total_sin_descuento);
            console.log('Precio: '+ precio);
            console.log('response.cantidad: '+ response.descripcion.cantidad);
            console.log('cant financial: '+ _fg);
            console.groupEnd('FIN')

            if (total_sin_descuento < total_descuento) {
                console.log('descuento MAYOR que total')
                setCodigoMayorQuePrecio(true);
            }else{
                console.log('descuento MENOR que total')
                setCodigoMayorQuePrecio(false);
                if (_fg === false) {
                    return total_descuento;
                }else{
                    return total_descuento;
                }
            }
        }
    }
    
//******************************************************************************************** */
const handleClickValidarCodigo = async (e) => {
    // console.log(promoRef.current.value);
    setBtnCodigo(true);
    const params = {
        nombre: promoRef.current.value,
        software: software.id
    }

    await axios.post(urlApi+'descuentos/validar', params)
    .then((response) => {
                if (response.data.respuesta === 'correcto') {
                    setResponseValidacion(response.data);
                    setBtnEnviarCodigo(true);

                    //Validacion por porcentaje*******************************************************
                    if (response.data.descripcion.tipo_descuento === 'porcentaje') {
                        const total_descuento = porcentaje(response.data, totalSinDescuento);
                        console.log(total_descuento);
                        setTotal((totalSinDescuento - total_descuento));
                        
                    //Validacion por dolares*********************************************************
                    }else if(response.data.descripcion.tipo_descuento === 'dolares'){
                        const total_descuento = dolares(response.data, financialGoal);
                        console.log(total_descuento);
                        setTotal((totalSinDescuento - total_descuento));
                    }
                //Terminan validaciones*******************************************************************
                }
                if (response.data.respuesta === 'error') {
                    setResponseValidacion(response.data);
                    console.log(responseValidacion)
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                    })
                }
                if (response.data.respuesta === 'inexistente') {
                    setResponseValidacion(response.data);
                    console.log(responseValidacion)
                }
            }).catch((error) => {
                // setCarga(false);
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Ocurrio un error en la acción',
                })
                console.log(error);
            }).then(() =>{
                setBtnCodigo(false);
            });
        }
//******************************************************************************************** */

    //Funcion que previene letras
    const handleChange = (e) => {
        /*Validamos solo numeros*/
        let _financialGoal = (e.target.validity.valid) ? e.target.value : financialGoal;
        if(_financialGoal<=0) {
            _financialGoal=1;
        }
        setFinancialGoal(_financialGoal);

        //Añadimos el precio total al final
        const subtotal = _financialGoal*precio;
        setTotalSinDescuento(subtotal);
        console.log('subtotal: '+ subtotal);
        // return;
        if(Object.keys(responseValidacion).length !== 0 && responseValidacion.constructor === Object){
            if(responseValidacion.respuesta !== 'inexistente'){
                if(responseValidacion.descripcion.tipo_descuento === 'porcentaje'){
                    const total_descuento = porcentaje(responseValidacion, subtotal, _financialGoal);
                    console.log(total_descuento);
                    setTotal((subtotal - total_descuento));
                }
                if(responseValidacion.descripcion.tipo_descuento === 'dolares'){
                    const total_descuento = dolares(responseValidacion, _financialGoal);
                    console.log(total_descuento);
                    setTotal((subtotal - total_descuento));
                }
                console.log(responseValidacion.descripcion.tipo_descuento);
            }else{
                setTotal(subtotal);
            }
        }else{
            setTotal(subtotal);
        }

    }

//******************************************************************************************** */
//funcion que suma y resta con los botones acoplados
    const sumaResta = (e) => {
        e.preventDefault();
        //Suma o resta cantidad
        let _financialGoal = parseInt(financialGoal)+parseInt(e.target.value);
        setFinancialGoal(_financialGoal);
        //Si es menor que cero regresa al valor 1
        if(_financialGoal<=0){
            setFinancialGoal(1);
            _financialGoal = 1;
        }
        //Añadimos el precio total al final
        const _totalSinDescuento = _financialGoal*precio;
        setTotalSinDescuento(_totalSinDescuento);
        console.log(_totalSinDescuento);
        console.group('INICIO SUMARESTA');
        console.log('_financialGoal: '+ _financialGoal);
        console.groupEnd('FIN SUMARESTA');
        setFinancialGoal(_financialGoal);
        // setTotalSinDescuento(_totalSinDescuento);
        if(Object.keys(responseValidacion).length !== 0 && responseValidacion.constructor === Object){
            if(responseValidacion.respuesta !== 'inexistente'){
                if(responseValidacion.descripcion.tipo_descuento === 'porcentaje'){
                    const total_descuento = porcentaje(responseValidacion, _totalSinDescuento, _financialGoal);
                    console.log(total_descuento);
                    setTotal((_totalSinDescuento - total_descuento));
                }
                if(responseValidacion.descripcion.tipo_descuento === 'dolares'){
                    const total_descuento = dolares(responseValidacion, _financialGoal);
                    console.log(total_descuento);
                    setTotal((_totalSinDescuento - total_descuento));
                }
            }else{
                setTotal(_totalSinDescuento);
            }
        }else{
            setTotal(_totalSinDescuento);
        }
        
    }
//******************************************************************************************** */
const handleKeyPressEnter = (e) => {
    // console.log(e.which)
    if (e.which == 13 || e.keyCode == 13) {
        e.preventDefault();
        // console.log(btnPromoRef.current);
        btnPromoRef.current.click();
        return false;
    }
    return true;
}

//******************************************************************************************** */
    //Funcion que realiza la accion cuando se cierra la ventana
    const closeWindowPaypal = async (cliente, token, cantidad, software, total_sin_descuento = 0, descontado = 0, descuento_id = 0) => {
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        const send_data = {
            correo             : cliente.correo,
            nombre             : cliente.nombre,
            token              : token,
            cantidad           : cantidad,
            software           : software,
            total_sin_descuento: total_sin_descuento,
            descontado         : descontado,
            descuento_id       : descuento_id,
        }

        // await axios.post(urlApi+'validate', send_data, {headers: headers})
        await axios.post(urlApi+'correctopendiente', send_data, {headers: headers})
                    .then(function (response) {
                        console.log(response.data);
                        MySwal.close();
                        if(response.data.respuesta==='correcto'){
                            const th = `<p class="h3">This is your licenses: </p> `
                            let licencias = `<ul>`;
                            if(response.data.licencias.licencias.length > 0){
                                response.data.licencias.licencias.forEach(element => {
                                    licencias += `<li class="h4 text-danger">${element.codigo_activacion}</li>`;
                                });
                            }
                            licencias +=`</ul>`
                            const hd = `<p>Check your email to verify your licenses</p>`;
                            sweetAlert('Successful purchase', th + licencias + hd, true, false, 'success');
                            console.log(response.data.licencias.licencias);
                            console.log(response.data.licencias.licencias.length);
                            // console.log(response.data.licencias.licencias);
                            history.push('/payments');
                        }else if(response.data.respuesta==='pendiente'){
                            
                            sweetAlert('Successful purchase', 'Check your email to verify your licenses (Pending Shipping)', true, false, 'success');
                            history.push('/payments');
                        }else{
                            sweetAlert('Error', 'An error occurred, please try again', true, false, 'error');
                        }
                        console.log(response.data);
                    })
                    .catch(function (error) {
                        MySwal.close();
                        sweetAlert('Error', 'An error occurred, please try again)', true, false, 'error');
                        console.log(error);
                    });
    }
//********************************************************************************************** */
    //funcion que hace la llamada a la API de laravel y paypal
    const sendData = async (params) => {
        // e.preventDefault();
        setpreguntar(true);
        if(checkedbool !== 1 && checkedbool !== 0){
            return;
        }
        setcarga(true);
        params.promociones = checkedbool;
        console.log(params)
        const metodo = params.metodo;
        // setCliente(params)
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        const send_data = {
            cliente          : params,
            softwareId       : software.id,
            cantidad         : financialGoal,
            total            : total,
            totalSinDescuento: totalSinDescuento,
            descuento        : responseValidacion
        }
        console.log(send_data);
        // return;
        await axios.post(urlApi+metodo, send_data, {headers: headers})
                    .then(function (response) {
                        //**************************/
                        //****METODO DE PAYPAL*****//
                        //**************************/                    
                       if (response.data.metodo==='paypal') {
                           const subtoken = response.data.url;
                           const tokens = subtoken.split('&token=');
                           const w = window.open(response.data.url , `Pago de ${response.data.amount} PayPal` , "width=450,height=670,top=50%,left=50%,Resizable=no,Scrollbars=NO");
                           //Validar ventanas emergentes
                           if(w == null || typeof(w)=='undefined'){
                            MySwal.fire({
                                toast: true,
                                width: 500,
                                position: 'top',
                                icon: 'warning',
                                title: 'Enable pop-up windows',
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                                showClass: {
                                    popup: 'animate__animated animate__fadeInDown'
                                },
                                hideClass: {
                                    popup: 'animate__animated animate__fadeOutUp'
                                }
                            });
                            setcarga(false);
                            return;
                           }
                           sweetAlert('Making purchase', 'This will take a while', false, true);
                           setcarga(false);
                           var interval = window.setInterval(function() {
                               try {
                                   if (w == null || w.closed) {
                                       window.clearInterval(interval);
                                       setpreguntar(false);
                                       if(response.data.amount_sd !== null || typeof(response.data.amount_sd)!=='undefined'){
                                            closeWindowPaypal(params, tokens[1], financialGoal, software.id, response.data.amount_sd, response.data.amount_descon, response.data.descuento);
                                        }else{
                                            closeWindowPaypal(params, tokens[1], financialGoal, software.id);
                                        }
                                   }
                               }
                               catch (e) {
                               }
                           }, 1000);
                           return w;                        
                        }else if(response.data.metodo==='mercadopago'){
                            //**************************/
                            //**METODO DE MERCADOPAGO**//
                            //**************************/
                            sweetAlert('Metodo de pago', 'Mercago pago', true, false, 'success');
                        }else if(response.data.metodo==='stripe'){
                            //**************************/
                            //****METODO DE STRIPE*****//
                            //**************************/
                            console.log(response.data);
                            const token = response.data.token;
                           const w = window.open(response.data.url , `Pago de stripe` , "width=450,height=670,top=50%,left=50%,Resizable=no,Scrollbars=NO");
                           //Validar ventanas emergentes
                           if(w == null || typeof(w)=='undefined'){
                                MySwal.fire({
                                    toast: true,
                                    width: 500,
                                    position: 'top',
                                    icon: 'warning',
                                    title: 'Enable pop-up windows',
                                    showConfirmButton: false,
                                    timer: 5000,
                                    timerProgressBar: true,
                                    showClass: {
                                        popup: 'animate__animated animate__fadeInDown'
                                    },
                                    hideClass: {
                                        popup: 'animate__animated animate__fadeOutUp'
                                    }
                                });
                                setcarga(false);
                                return;
                            }
                            sweetAlert('Realizando compra', 'Tomara solo unos minutos', false, true);
                            setcarga(false);
                            var interval = window.setInterval(function() {
                                try {
                                    if (w == null || w.closed) {
                                        window.clearInterval(interval);
                                        setpreguntar(false);
                                        if(response.data.amount_sd !== null || typeof(response.data.amount_sd)!=='undefined'){
                                             closeWindowPaypal(params, token, financialGoal, software.id, response.data.amount_sd, response.data.amount_descon, response.data.descuento);
                                         }else{
                                             closeWindowPaypal(params, token, financialGoal, software.id);
                                         }
                                    }
                                }
                                catch (e) {
                                }
                            }, 1000);
                            return w; 
                        }
                    })//FIN DEL ENVIO CON AXIOS
                    .catch(function (error) {
                        sweetAlert('ERROR', 'metodo de pago inexistente', true, false, 'error');
                        console.log(error);
                    });
    }
//************************************************************************************************** */
    //Validador de datos
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formSchema = Yup.object().shape({
        correo: Yup.string()
            .required('Campo requerido')
            .email('Correo electronico invalido')
            .max(50, 'Maximo de caracteres'),
        nombre: Yup.string()
            .min(5, 'Minimo 5 caracteres')
            .max(50, 'Maximo de 255 caracteres')
            .required('Campo requerido'),
        empresa: Yup.string()
            .max(50, 'Maximo de 255 caracteres'),
        telefono:   Yup.string('Debe ser un numero')
            .required('Campo requerido')
            .matches(phoneRegExp, 'Numero de telefono no valido')
            .max(8, 'minimo')
            .max(12, 'maximo 12'),
        pais: Yup.string()
            .required('Campo requerido'),
        metodo: Yup.string()
            .required('Campo requerido'),
        promociones: Yup.number(),        
                // .required('Campo requerido'),
        });
            
//************************************************************************************************** */
        return (
        <div className="banner">
            <div className="container p-4">
                <h2 className="fw-bold text-uppercase d-none">¡Ya casi terminas! Completa tu orden</h2>
                <span style={{fontSize: '0.00001px'}} id="compras">-</span>
                <span style={{fontSize: '0.00001px'}} id="nosotros">-</span>
                <span style={{fontSize: '0.00001px'}} id="contacto">-</span>
                <Formik
                    enableReinitialize
                    initialValues={{
                        correo:"",
                        nombre:"",
                        empresa:"",
                        telefono:"",
                        pais: "Mexico",
                        metodo: "stripe",
                        // promociones: 1
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values) => sendData(values)}
                >
                    <Form>
                        <div className="row">
                            <div className="card m-auto mt-5 w-50-100" style={{backgroundColor: '#ffe4bf'}}>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="card-title text-uppercase">{software.name}</h5>
                                        <h5 className="text-primary">${software.amount}</h5>
                                    </div>
                                    <h6 className="card-subtitle mb-2 text-muted text-uppercase">Professional license</h6>
                                    <div className="row mb-3">

                                        <label htmlFor="inputEmail3" className="col-sm-8 col-form-label fw-bold">Quantity:</label>
                                        <div className="col-sm-4 input-group inpute-group">
                                            <div className="input-group-prepend">
                                                <button className="btn btn-dark operacion" type="button" value="-1" onClick={(e) => sumaResta(e)}>-</button>
                                            </div>
                                                <input 
                                                    type="text" 
                                                    pattern="[0-9]*" 
                                                    onChange={handleChange} 
                                                    value={financialGoal} 
                                                    className="form-control text-center"
                                                />
                                            
                                            <div className="input-group-append">
                                                <button className="btn btn-dark operacion" type="button" value="1" onClick={(e) => sumaResta(e)}>+</button>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="card-text text-muted d-none">Llene los datos correspondientes para poder adquirir sus licencias.</p>
                                    <p className="card-text text-muted">Fill in the corresponding data to be able to acquire your licenses.</p>
                                    <p className="card-text text-muted d-none">Campo obligatorios (*)</p>
                                    <p className="card-text text-muted">Required fields (*)</p>
                                    {/* Datos de usuario */}
                                    <div className="mb-3">
                                        <label htmlFor="correo" className="form-label">Email (*)</label>
                                        <Field type="email" className="form-control" name="correo" placeholder="name@example.com"/>
                                        <ErrorMessage
                                            name='correo'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="form-label">Fisrtname (*)</label>
                                        <Field type="text" className="form-control" name="nombre" placeholder="-"/>
                                        <ErrorMessage
                                            name='nombre'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="empresa" className="form-label">Company</label>
                                        <Field type="text" className="form-control" name="empresa" placeholder="-"/>
                                        <ErrorMessage
                                            name='empresa'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="telefono" className="form-label">Phone (*)</label>
                                        <Field type="tel" className="form-control" name="telefono" placeholder="#"/>
                                        <ErrorMessage
                                            name='telefono'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pais" className="form-label">Country (*)</label>
                                        <Field as="select" className="form-control select-custom" name="pais">
                                            {
                                                paises.map((element,index) => {
                                                    return  <option
                                                                value={element.name} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='pais'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label fw-bold text-uppercase">Payment method</label>
                                        <div className="form-check d-flex align-items-center d-none">
                                            <Field className="form-check-input me-2" type="radio" name="metodo" value="charge" id="flexRadioDefault1"/>
                                            <div className="w-100">
                                                <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor="flexRadioDefault1">
                                                    <span className="cursor-pointer">Paypal</span>
                                                    <img className="cursor-pointer " src={url+'storage/paypal.png'} width='200' alt="logo-paypal"/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="form-check d-flex align-items-center">
                                            <Field className="form-check-input me-2" type="radio" name="metodo" value="stripe" id="flexRadioDefault2"/>
                                            <div className="w-100">
                                                <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor="flexRadioDefault2">
                                                    <span className="cursor-pointer">Credit or debit card </span>
                                                    <div className="d-flex justify-content-around align-items-center">
                                                        <img className="id" src={url+'storage/_tarjetas.png'} height='50'alt="logo-stripe"/>
                                                        <img className="cursor-pointer" src={url+'storage/stripe.png'} height='80' alt="logo-stripe"/>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="form-check d-flex align-items-center">
                                            <Field className="form-check-input me-2" type="radio" name="metodo" value="mercado-pago" id="flexRadioDefault2"/>
                                            <div className="w-100">
                                                <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor="flexRadioDefault2">
                                                    <span className="cursor-pointer">Mercado pago</span>
                                                    <img className="cursor-pointer" src={url+'storage/mercadopago.png'} width='200' alt="logo-mercado-pago"/>
                                                </label>
                                            </div>
                                        </div> */}
                                        {/* <div className="form-check d-flex align-items-center d-none">
                                            <Field className="form-check-input me-2" type="radio" name="metodo" value="otro" id="flexRadioDefault3"/>
                                            <div className="w-100">
                                                <label className="form-check-label d-flex align-items-center justify-content-between" htmlFor="flexRadioDefault3">
                                                    <span className="cursor-pointer">Otro</span> 
                                                    <img className="cursor-pointer" src={url+'storage/otro.png'} width='200' alt="logo-otro"/>
                                                </label>
                                            </div>
                                        </div> */}
                                        <ErrorMessage
                                            name='metodo'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                    <hr/>
                                    <div className="row align-items-center">
                                        <div className="col-md-6" onClick={handleClick}>
                                            <p className="text-primary cursor-pointer mb-0">Promotion Code</p>
                                        </div>
                                        <div className={codigo ? "col-md-6 animate__animated animate__fadeIn" : "col-md-6 d-none"}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control"  
                                                name="descuento" 
                                                placeholder="promo" 
                                                aria-label="Recipient's username" 
                                                aria-describedby="button-addon2" 
                                                ref={promoRef}
                                                disabled={btnEnviarCodigo}
                                                onKeyPress={handleKeyPressEnter}
                                                />
                                            {
                                                !btnEnviarCodigo ? 
                                                <button className="btn btn-dark" type="button" 
                                                        id="button-addon2" 
                                                        data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" 
                                                        title="Validar codigo"
                                                        onClick={handleClickValidarCodigo}
                                                        disabled={btnCodigo}
                                                        ref={btnPromoRef}
                                                        ><span className="fw-bold" style={{marginRight: '5px'}}>Apply</span>
                                                        <FontAwesomeIcon icon={faShare}/>  
                                                        { btnCodigo &&
                                                            <>
                                                                <span className="spinner-border spinner-border-sm" style={{marginLeft: '1rem'}} role="status" aria-hidden="true"></span>
                                                                <span className="visually-hidden">Loading...</span>
                                                            </>
                                                        }                                                    
                                                </button>
                                                :
                                                <button className="btn btn-primary" type="button" 
                                                    id="button-addon2" 
                                                    data-bs-toggle="tooltip" 
                                                    data-bs-placement="top" 
                                                    title="Eliminar codigo"
                                                    onClick={handleClickBtnEnviarCodigo}
                                                    >
                                                    <FontAwesomeIcon icon={faTimes}/>  
                                                    {/* { btnCodigo &&
                                                        <>
                                                            <span className="spinner-border spinner-border-sm" style={{marginLeft: '1rem'}} role="status" aria-hidden="true"></span>
                                                            <span className="visually-hidden">Loading...</span>
                                                        </>
                                                    } */}
                                                    
                                            </button>
                                            }
                                            
                                        </div>  
                                        
                                            <ErrorMessage
                                                name='descuento'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>
                                    {
                                        (Object.keys(responseValidacion).length !== 0 && responseValidacion.constructor === Object) &&
                                        <div className="row">
                                                    <div className="col-md-6">
                                                    {
                                                            responseValidacion.title === 'Valido' &&
                                                            <div className="bg-secondary bg-gradient rounded p-3 text-center">
                                                                <span className="text-white">{responseValidacion.descripcion.descripcion}</span>
                                                            </div>
                                                    }
                                                    </div>
                                                    <div className="col-md-6">
                                                        {
                                                            responseValidacion.title !== 'Valido' ?
                                                                <>
                                                                    <span className="text-danger fw-bold text-uppercase">{responseValidacion.title}</span>
                                                                    <p className="text-dark">{responseValidacion.message}</p>
                                                                </>
                                                            :
                                                                <>
                                                                    <span className="text-success fw-bold text-uppercase">{responseValidacion.title}</span>
                                                                    <p className="text-dark">{responseValidacion.message}</p>
                                                                </>
                                                        }
                                                        
                                                    </div>
                                        </div>
        
                                    }
                                    
                                    <div className="col-md-12">
                                            <hr/>
                                            <div className="form-check">
                                                <input 
                                                    type="checkbox" 
                                                    className="form-check-input" 
                                                    id="promociones" 
                                                    name="promociones"
                                                    checked={checked} 
                                                    onChange={(e) => handleChecked(e)} 
                                                />
                                                <label className="form-check-label" htmlFor="promociones">
                                                    I accept, receive promotions and exclusive content
                                                </label>
                                            </div>
                                            <ErrorMessage
                                                name='promociones'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    {/* FIN Datos de usuario */}
                                    <hr/>
                                    {
                                        codigoMayorQuePrecio &&
                                        <div className="mb-4 animate__animated animate__fadeIn">
                                            <div className="col-md-12 bg-danger bg-gradient rounded p-1 text-white fw-bold text-center">
                                                <p className="mb-0 text-uppercase">Restricción</p>
                                                <p className="mb-0">No puede usar un cupon con descuento mayor al total</p>
                                            </div>
                                        </div>
                                        
                                    }
                                    {
                                        (Object.keys(responseValidacion).length !== 0 && responseValidacion.constructor === Object) &&
                                        
                                        responseValidacion.respuesta === 'correcto' &&
                                            <div className="d-flex justify-content-end">
                                                <p className="h4 text-secondary text-decoration-line-through">(USD) ${totalSinDescuento}</p>
                                            </div>
                                    }
                                    <div className="d-flex justify-content-between">
                                        <p className="h2 fw-bold text-uppercase">Total to pay</p>
                                        <p className="h2 text-info">(USD) ${formatter.format(total)}</p>
                                    </div>
                                    <p className="card-text text-muted mx-5 fs--8">Total includes taxes.</p>
                                    <div className="my-4">
                                        {
                                            codigoMayorQuePrecio ?
                                                <button type="submit" 
                                                    className="btn btn-dark w-100 fw-bold text-uppercase"
                                                    disabled={
                                                        true
                                                    }
                                                >Go to payment</button>
                                            :
                                                    <button type="submit" 
                                                        className="btn btn-dark w-100 fw-bold text-uppercase"
                                                        disabled={
                                                            carga
                                                        }
                                                >Go to payment</button>
                                        }
                                        
                                    </div>
                                    {
                                        carga &&
                                        <div className="w-100 text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default Comprar
