import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";


const Table = ({registrado, registrados, setregistros, totalRows, setTotalRows}) => {


    // const handleClick = async () => {
    //     const log = await AsyncStorage.getItem('@login');
    //     const log_json = JSON.parse(log);
    //     const headers = {
    //         'Authorization': log_json.token_type+' '+log_json.access_token,
    //         'Content-Type': 'application/json',
    //         'X-Requested-With': 'XMLHttpRequest'
    //     }
    // }
    

    return (
        <div className="d-flex justify-content-around w-100">     
            <Link className="btn btn-success"
                to={
                    {  
                        pathname:"/registrados/edit",
                        state:{registrado: registrado}
                    }
                }

            >
                    <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
            </Link>
            {/* <button className="btn btn-danger"
                    // onClick={handleClick}
            >
                    <FontAwesomeIcon icon={faTrash} />  <span className="fw-bold"></span>     
            </button> */}
        </div>

    )
}

export default Table
