import React, {useEffect} from 'react'

const Auth = () => {
    
    useEffect(() => {
        
    }, []);

    return (
        <div>
            
        </div>
    )
}

export default Auth
