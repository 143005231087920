import React from 'react'
import getExcel from './getExcel'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Pago = ({Toast}) => {
     /************* Pago ******************/
     const handleClickPago = async (params) => {
        // console.log(params);
        const send_data ={
            params: params,
            req: 1
        }
        getExcel('reportes/pagosexportar', send_data, 'Pagos', Toast);
    }

    //Validador de datos
    const formSchema = Yup.object().shape({

        desde: Yup.string()
            .required('Campo requerido'),
        hasta: Yup.string()
            .required('Campo requerido'),
        // minimo: Yup.number()
        //     .typeError('El costo debe ser un numero')
        //     .min(0)
        //     .required('Campo requerido'),
        // maximo: Yup.number()
        //     .typeError('El costo debe ser un numero')
        //     .min(1)
        //     .max(9999)
        //     .required('Campo requerido'),
    });

    return (
        <div className="card text-center m-1 mb-5 p-0 w-32-pc">
            
            <Formik
                enableReinitialize
                initialValues={{
                    desde:'',
                    hasta: '',
                    // minimo: 0,
                    // maximo: 0
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => handleClickPago(values, actions)}
            >
                <Form className="d-flex flex-column h-100">
                    <div className="card-header">
                        <strong className="text-uppercase">Reporte de Pagos</strong>
                        
                    </div>
                    <div className="card-body">
                        <div className="col-md-12">
                            <label htmlFor="desde" className="form-label fw-bold text-uppercase">Desde:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="desde" name="desde"/>
                            </div>
                            <ErrorMessage
                                name='desde'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="hasta" className="form-label fw-bold text-uppercase">hasta:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="hasta" name="hasta"/>
                            </div>
                            <ErrorMessage
                                name='hasta'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        {/* <hr/>
                        <p className="text-start fw-bold text-uppercase">Precio</p>
                        <div className="mb-12">
                            <label htmlFor="minimo" className="form-label fw-bold text-uppercase">Mayor a:</label>
                            <Field type="text" className="form-control" name="minimo" placeholder="0"/>
                            <ErrorMessage
                                name='minimo'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <div className="mb-12">
                            <label htmlFor="maximo" className="form-label fw-bold text-uppercase">Menor a</label>
                            <Field type="text" className="form-control" name="maximo" placeholder="9999"/>
                            <ErrorMessage
                                name='maximo'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div> */}
                    </div>
                    <div className="card-footer text-muted my-auto">
                        <button type="submit" className="btn btn-dark text-uppercase fw-bold">Obtener reporte</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Pago
