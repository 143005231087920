import React, {useState} from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";
/*Sweet alert*/
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {urlApi} from '../../global/Global'

const Table = ({user, totalRows, setTotalRows, users, setUsers}) => {
/*Sweet alert*/
const MySwal = withReactContent(Swal);

    const [carga, setCarga] = useState(false);

    const handleClick = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        MySwal.fire({
            title: '¿Borrar usuario?',
            html: 'Una vez eliminado no podra deshacer esta acción',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
            setCarga(true);
            await axios.post(urlApi+'users/destroy', user, {headers: headers})
                                      .then(async (response) =>  { 
                                          if (response.data.respuesta==="correcto") {
                                            MySwal.fire(
                                                'Eliminado!',
                                                'Se elimino correctamente',
                                                'success'
                                            )
                                            setUsers(users.filter(sw => sw.id !== response.data.id));
                                            setTotalRows(totalRows-1);
                                            console.log(response.data);
                                          }else{
                                            MySwal.fire(
                                                'Error!',
                                                'Ocurrio un error, intentelo nuevamente',
                                                'error'
                                            )
                                          }
                                      })
                                      .catch(error => {
                                        console.log(error);
                                      })
                                      .then(() =>{
                                        setCarga(false);
                                      })

          }//Result
        });//then
    }
    
    return (
        <>
            {
                carga ?
                    <div className="w-100 text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                :
                    <div className="d-flex justify-content-around w-100">     
                        <Link className="btn btn-success"
                            to={
                                {  
                                    pathname:"/users/edit",
                                    state:{user: user}
                                }
                            }

                        >
                                <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
                        </Link>
                        <button className="btn btn-danger"
                                onClick={handleClick}    
                        >
                                <FontAwesomeIcon icon={faTrash} />  <span className="fw-bold"></span>     
                        </button>
                    </div>
            }
        </>
    )
}

export default Table
