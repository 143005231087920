import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

/*Vista tabla*/
import Table from './Table';
/*Globales*/
import {urlApi} from '../../global/Global';
/* Agregar caracter */
import Loading from '../../styles/Loading'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import {
    NavLink
} from "react-router-dom";
const FilterComponent = ({ filterText, onFilter, onClear, cargaBtn }) => (
    <div className="input-group col-md-6 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        {
            !cargaBtn ?
                <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>
                    X
                </button>
            :
            <button className="btn btn-danger" type="button" id="button-addon1" disabled>
                    X
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                <span className="visually-hidden">Loading...</span>
            </button>
        }
    </div> 
);

const Descuentos = () => {

    const [cargaBtn, setCargaBtn] = useState(false);
    const [descuentos, setDescuentos] = useState([]);
    const [perdescuentos, setPerdescuentos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    const [carga, setCarga] = useState(false);
    const [activados, setActivados] = useState([1]);


    console.log(descuentos);
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);
    /*Filtros*/
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    //Paramas de string a enviar
    let string = '';
    //DATATABLE
    const columns = [
        {
            name: 'Codigo',
            // selector: 'nombre',
            sortable: true,
            cell: row =>{
                return (row.estado == 1)?
                    <p className="p-0">{row.nombre}</p>
                :
                    <p className="p-0 text-muted">{row.nombre} (desactivado)</p>

            }
            // width: '30%',
        },
        {
            name: 'Tipo de descuento',
            // selector: 'tipo_descuento',
            sortable: true,
            cell: row =>{
                return (row.estado == 1)?
                    <p className="p-0">{row.tipo_descuento}</p>
                :
                    <p className="p-0 text-muted">{row.tipo_descuento}</p>

            }
            // width: '30%',
        },
        {
            name: 'Descripción',
            // selector: 'descripcion',
            sortable: true,
            cell: row =>{
                return (row.estado == 1)?
                    <p className="p-0">{row.descripcion}</p>
                :
                    <p className="p-0 text-muted">{row.descripcion}</p>

            }
            
            // width: '30%',
        },
        {
            name: 'Cantidad',
            // selector: 'cantidad',
            sortable: true,
            cell: row =>{
                return (row.estado == 1)?
                    <p className="p-0">
                        {row.cantidad}
                        {
                            row.tipo_descuento == 'porcentaje' ?
                            '%'
                            :
                            '$'
                        }
                    </p>
                :
                    <p className="p-0 text-muted">
                        {row.cantidad}
                        {
                            row.tipo_descuento == 'porcentaje' ?
                            '%'
                            :
                            '$'
                        }
                    </p>

            }
            // width: '30%',
        },
        {
            name: 'Ind o gen',
            // selector: 'individual_general',
            sortable: true,
            cell: row =>{
                return (row.estado == 1)?
                    <p className="p-0">{row.individual_general}</p>
                :
                    <p className="p-0 text-muted">{row.individual_general}</p>

            }
            // width: '30%',
        },
        {
            name: 'Estatus',
            sortable: true,
            // width: '30%',
            cell: row => (
                
                row.estado == 1 ?
                    <p className="p-0">Activado</p>
                        :
                    <p className="p-0 text-muted">Desactivado</p>
            )
        },
        {
            name: 'Auto- matización',
            sortable: true,
            // width: '30%',
            cell: row => (
                row.estado == 1 ?
                    row.automatizacion == 1 ?
                        <p className="p-0">Activado</p>
                            :
                        <p className="p-0">Desactivado</p>
                        :
                    row.automatizacion == 1 ?
                        <p className="p-0 text-muted">Activado</p>
                            :
                        <p className="p-0 text-muted">Desactivado</p>
            )
        },
        {
            name: 'SW habilitado',
            sortable: true,
            // width: '11%',
            cell: row =>(
                  <ul className="m-0 p-0 py-2">
                      {
                          row.softwares.map((element, i) =>{
                              return <li className="mb-0 text-capitalize" key={i}>
                                          {element.name}
                                      </li>
                          })
                      }
                  </ul> 
            )
        },
        {
            name: 'Fecha registro',
            // selector: 'created_at',
            sortable: true,
            cell: row => {
                const fecha = row.created_at.split('T');
                if (row.estado == 1) {
                    return ( 
                        <div className="d-flex flex-column w-100">
                            <p className="p-0 my-1 d-inline w-100">Fecha: {fecha[0]} </p>
                            <p className="p-0 my-1 d-inline w-100">Hora: {fecha[1].substr(0,8)}</p>
                        </div>
                    )
                }else{
                    return ( 
                        <div className="d-flex flex-column w-100">
                            <p className="p-0 my-1 d-inline w-100 text-muted">Fecha: {fecha[0]} </p>
                            <p className="p-0 my-1 d-inline w-100 text-muted">Hora: {fecha[1].substr(0,8)}</p>
                        </div>
                    )
                }
                
            }
        },
        {
            name: 'Opciones',
            // width: '10%',
            cell: row => <Table 
                            descuento={row}
                            descuentos={descuentos}
                            setDescuentos={setDescuentos}
                            totalRows={totalRows}
                            setTotalRows={setTotalRows}
                         />,
        },
      ];

       //Componente de cabecera
        const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                    setDescuentos(perdescuentos);
                    string = '';
                }
            };

            return <FilterComponent 
                        onFilter={e => handleChangeFilter(e.target.value)} 
                        onClear={handleClear} 
                        filterText={filterText} 
                        cargaBtn={cargaBtn}
                    />;
        }, [filterText, resetPaginationToggle, cargaBtn]);

        //*********************************************************************************
        const getDescuentos = async (page, activos) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }

            await axios.post(urlApi+'descuentos', {page:page, perPage:perPage, activo: activos}, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    
                    setDescuentos(response.data.descuento);
                    setPerdescuentos(response.data.descuento);
                    setTotalRows(response.data.desc_count);
                    setLoading(false);
                    console.log(response.data.descuento);
                })
                .catch((error) => {
                    console.log(error);
                });                    
        }
        //Primer acción*************************************************************************
        useEffect(() => {
        
            getDescuentos(1, [1]);

        }, [])

    //***************************************************************************************** */

    const handleClickActivos = (e) => {
        console.log(e.target.checked)
        if(e.target.checked == true){
            getDescuentos(1, [0,1]);
            setActivados([0,1]);
        }else{
            getDescuentos(1, [1]);
            setActivados([1]);
        }
        
    }

    //***************************************************************************************** */
    const handleChangeFilter = async (e) => {
        // console.log(e.target.checked);
        setCargaBtn(true);
        setFilterText(e);
        string += e;
        console.log(string);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'descuentos/filter', {page:1, perPage:10, activo: activados, string:string}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setDescuentos(response.data.descuento);
                setTotalRows(response.data.desc_count);
                setLoading(false);
                // setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
                setCargaBtn(false);
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                setCargaBtn(false);
            });
    }

    //Cambio de pagina **************************************************************************************** */
    const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'descuentos', {page:page, perPage:perPage, activo: activados}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setDescuentos(response.data.descuento);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() =>{
                setCarga(false);
            });
    };

    //Cambio de rows ****************************************************************************************
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'descuentos', {page:page, perPage:newPerPage, activo: activados}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setDescuentos(response.data.descuento);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };


    return (
        <div>
            <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="fw-bold text-uppercase">descuentos </h1> 
                            <div className="col-md-3">
                                <NavLink className="btn btn-dark w-100 mb-3" exact to="/descuentos/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Agregar descuento</span>
                                </NavLink>
                            </div>
                        </div> 
                        <div className="d-flex justify-content-end">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" onClick={handleClickActivos}/>
                                <label className="form-check-label text-uppercase fw-bold" htmlFor="defaultCheck1" >
                                    Mostrar desactivados
                                </label>
                            </div>
                        </div>
                        <hr/>
                        <DataTable
                            // title="Licencias"
                            columns={columns}
                            data={descuentos}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            striped
                        />
                        {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Descuentos;
