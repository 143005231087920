import React, {useEffect, useState} from 'react';
// import React, {useEffect, useState, useContext} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
/* Agregar caracter */
import {agregarCaracter} from '../../global/agregarCaracter';
//Importar state de context
// import LoginContext from '../../global/login/loginContext';
const Detalles = (props) => {

    const cliente = props.location.state.cliente;
    const [licencias, setLicencias] = useState([]);
    const [cero, setCero] = useState(false);
    const [softwares, setSoftwares] = useState([]);
    /*CONTEXT DE USUARIO */
    //const { headers } = useContext(LoginContext);
    const getLicencia = async () => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'licencias/getlicenciacliente', cliente.licencias, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                if (response.data.respuesta==='cero') {
                    console.log(response.data);
                    setCero(true);
                }else{
                    setLicencias(response.data)
                    console.log(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });      
    }

    const getSoftwares = async () => {
        try {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            const get_softwares = await axios.get(urlApi+'softwares/wt', {headers: headers});
            setSoftwares(get_softwares.data);
        } catch (error) {
            console.log(error);
        }
    }
    
    
    useEffect(() => {
        getLicencia();
        getSoftwares();
    }, [])


    return (

        <div className="mt-5 container">
            <div className="border rounded-3">
                <div className="p-3 table-responsive">
                    <div className="overflow-auto">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="fw-bold text-uppercase">Cliente: {cliente.nombre}</h1> 
                        </div>
                        <h3 className="fw-bold text-muted">Correo de cliente: {cliente.correo}</h3> 
                    </div> 
                    <hr/>
                    <div className="row mb-3">
                        <div className="col-md-6 border-end mb-3">
                            <h5><strong className="text-uppercase mb-2">Informacion del cliente</strong></h5>
                            <p className="mb-0"><strong>Nombre: </strong>{cliente.nombre}</p>
                            <p className="mb-0"><strong>Correo: </strong>{cliente.correo}</p>
                            <p className="mb-0"><strong>Empresa: </strong>{cliente.empresa}</p>
                            <p className="mb-0"><strong>Telefono: </strong>{cliente.telefono}</p>
                        </div>

                        <div className="col-md-6 mb-3">
                            <h5><strong className="text-uppercase mb-2">Informacion general</strong></h5>
                            <p className="mb-0"><strong>Licencias adquiridas: </strong> {licencias.length}</p>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <h2 className="fw-bold">Licencias</h2>
                    </div>
                    {
                    !cero ?
                    
                    <div className="row col-cel w-100">
                        <div className="col-li-1 mb-4">
                            <div className="list-group overflow-auto height-300 border border-secondary rounded" id="list-tab" role="tablist">
                            {
                                cliente.licencias.map((element, index) =>{
                                    return index===0 ?
                                            <a key={index} className="list-group-item list-group-item-action active" id={"list-"+index+"-list"} data-bs-toggle="list" href={"#list-"+index} role="tab" aria-controls={index}>
                                                {agregarCaracter(element.codigo_activacion, '-', 4)}
                                            </a>
                                            :
                                            <a key={index} className="list-group-item list-group-item-action" id={"list-"+index+"-list"} data-bs-toggle="list" href={"#list-"+index} role="tab" aria-controls={index}>
                                                {agregarCaracter(element.codigo_activacion, '-', 4)}
                                            </a>

                                })   
                                
                            }

                            </div>
                        </div>
                        <div className="col-li-2 mb-4">
                            <div className="tab-content border border-secondary rounded overflow-auto height-300" id="nav-tabContent">
                                    {
                                    licencias.length !== 0 ?
                                        licencias.map((element, index) =>{
                                            return index===0 ? 
                                            <div key={index} className="tab-pane fade show active" id={"list-"+index} role="tabpanel" aria-labelledby={"list-"+index+"-list"}>
                                                <ul>
                                                    <li><strong>Lote perteneciente: </strong>{element.lote}</li>
                                                    <li><strong>Codigo de activación: </strong>{agregarCaracter(element.codigo_activacion, '-', 4)}</li>
                                                    {
                                                        typeof element.payments[0] !== 'undefined' ?
                                                        <>
                                                        <li><strong>¿Pago online? </strong>{element.webpay_activation}
                                                            <ul>
                                                                <li><strong>PayID: </strong>{element.payments[0].payment_id}</li>
                                                                <li><strong>Pago de paypal: </strong>{element.payments[0].payment_status}</li>
                                                            </ul>
                                                        </li>
                                                        </>
                                                        :
                                                        <>
                                                            <li><strong>¿Pago online? </strong>{element.webpay_activation}
                                                                <ul>
                                                                    <li><strong>Licencia obtenida sin pago online</strong></li>
                                                                </ul>
                                                            </li>
                                                        </>
                                                    }
                                                    <li><strong>Estado: </strong>{element.estado.name}</li>
                                                        Softwares compatibles:
                                                    <ul>
                                                        {
                                                            element.softwares.map((el, index) =>{
                                                                return <li key={index}>{el.name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                    
                                                        {
                                                            element.estado_id===4 &&
                                                                <li>
                                                                    <div className="bg-success px-1 rounded border border-secondary text-white w-75"> 
                                                                        <span className="fw-bold">Software activado: </span>
                                                                        {softwares.map((el, index) =>{
                                                                            return el.id === element.registro.software_id &&
                                                                            <span key={index}>{el.name}</span>
                                                                        })
                                                                        
                                                                    }
                                                                    </div>
                                                                    {
                                                                        typeof element.payments[0] !== 'undefined' ?
                                                                        <ul>
                                                                            <li>
                                                                                <strong>Precio de licencia (al momento de la compra): </strong>  
                                                                                <span>${element.payments[0].pivot.precio}</span>
                                                                            </li>
                                                                        </ul>
                                                                        :
                                                                        <ul>
                                                                            <li>
                                                                                <strong>Licencia obtenida vía offline </strong>  
                                                                            </li>
                                                                        </ul>
                                                                    }
                                                                    <ul>
                                                                        <li>
                                                                            <strong>Mac address: </strong>
                                                                            <span>{element.registro.mac_address}</span>
                                                                        </li>
                                                                        <li>
                                                                            <strong>Versión: </strong>
                                                                            <span>{element.registro.version}</span>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                        }
                                                     
                                                </ul>
                                            </div>
                                            :
                                            <div key={index} className="tab-pane fade" id={"list-"+index} role="tabpanel" aria-labelledby={"list-"+index+"-list"}>
                                                <ul>
                                                    <li><strong>Lote perteneciente: </strong>{element.lote}</li>
                                                    <li><strong>Codigo de activación: </strong>{agregarCaracter(element.codigo_activacion, '-', 4)}</li>
                                                    {
                                                        typeof element.payments[0] !== 'undefined' ?
                                                        <>
                                                            <li><strong>¿Pago online? </strong>{element.webpay_activation}
                                                                <ul>
                                                                    <li><strong>PayID: </strong>{element.payments[0].payment_id}</li>
                                                                    <li><strong>Pago de paypal: </strong>{element.payments[0].payment_status}</li>
                                                                </ul>
                                                            </li>
                                                        </>
                                                        :
                                                        <>
                                                            <li><strong>¿Pago online? </strong>{element.webpay_activation}
                                                                <ul>
                                                                    <li><strong>Licencia obtenida sin pago online</strong></li>
                                                                </ul>
                                                            </li>
                                                        </>
                                                    }
                                                    <li><strong>Estado: </strong>{element.estado.name}</li>
                                                        Softwares compatibles:
                                                    <ul>
                                                        {
                                                            element.softwares.map((el, index) =>{
                                                                return <li key={index}>{el.name}</li>
                                                            })
                                                        }
                                                    </ul>
                                                        {
                                                            element.estado_id===4 &&
                                                                <li>
                                                                    <div className="bg-success px-1 rounded border border-secondary text-white w-75"> 
                                                                        <span className="fw-bold">Software activado: </span>
                                                                        {softwares.map((el, index) =>{
                                                                            return el.id == element.registro.software_id &&
                                                                            <span key={index}>{el.name}</span>
                                                                        })
                                                                        
                                                                    }
                                                                    </div>
                                                                    {
                                                                        typeof element.payments[0] !== 'undefined' ?
                                                                        <ul>
                                                                            <li>
                                                                                <strong>Precio de licencia (al momento de la compra): </strong>  
                                                                                <span>${element.payments[0].pivot.precio}</span>
                                                                            </li>
                                                                        </ul>
                                                                        :
                                                                        <ul>
                                                                            <li>
                                                                                <strong>Licencia obtenida vía offline </strong>  
                                                                            </li>
                                                                        </ul>
                                                                    }

                                                                    <ul>
                                                                        <li>
                                                                            <strong>Mac address: </strong>
                                                                            <span>{element.registro.mac_address}</span>
                                                                        </li>
                                                                        <li>
                                                                            <strong>Versión: </strong>
                                                                            <span>{element.registro.version}</span>
                                                                        </li>
                                                                    </ul>
                                                                </li>
                                                        }
                                                </ul>
                                            </div>
                                        })   
                                    :
                                    <div className="w-100 text-center mt-25pc">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    }
                            </div>
                        </div>
                    </div>
                    :
                    <h4>No hay licencias a mostrar</h4>
                    }
                </div>
            </div>
        </div>
    )
}

export default Detalles
