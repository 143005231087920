import React, {useState} from 'react'
import getExcel from './getExcel'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Cliente = ({Toast}) => {
    /************* Cliente ******************/

    const [promociones, setpromociones] = useState([
        {id:0, name: 'Sin correo promocional'},
        {id:1, name: 'Con correo promocional'},
        {id:2, name: 'Ambos'},
    ]);

    const handleClickCliente = async (params) => {
        const send_data ={
            params: params,
            req: 1
        }
        getExcel('reportes/clientesexportar', send_data, 'Clientes', Toast);
    }

    //Validador de datos
    const formSchema = Yup.object().shape({

        // desde: Yup.string()
        //     .required('Campo requerido'),
        // hasta: Yup.string()
        //     .required('Campo requerido'),
        promocion: Yup.number()
            .required('Campo requerido'),
       
    });

    return (
        <div className="card text-center m-1 mb-5 p-0 w-32-pc">
            
            <Formik
                enableReinitialize
                initialValues={{
                    // desde:'',
                    // hasta: '',
                    promocion: 1
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => handleClickCliente(values, actions)}
            >
                <Form className="d-flex flex-column h-100">
                    <div className="card-header">
                        <strong className="text-uppercase">Reporte de Clientes</strong>
                        
                    </div>
                    <div className="card-body">
                        {/* <div className="col-md-12">
                            <label htmlFor="desde" className="form-label fw-bold text-uppercase">Desde:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="desde" name="desde"/>
                            </div>
                            <ErrorMessage
                                name='desde'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="hasta" className="form-label fw-bold text-uppercase">hasta:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="hasta" name="hasta"/>
                            </div>
                            <ErrorMessage
                                name='hasta'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div> */}
                        <div className="col-md-12">
                            <label htmlFor="promocion" className="form-label fw-bold text-uppercase text-center">Correo promocional</label>
                            <Field as="select" className="form-control select-custom fw-bold text-capitalize" name="promocion">
                                {
                                    promociones.map((element, index) =>{
                                        return  <option
                                                    value={element.id} 
                                                    key={index}
                                                    
                                                    >{element.name}
                                                </option>
                                    })
                                }
                            </Field>
                            <ErrorMessage
                                name='promocion'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                    </div>
                    <div className="card-footer text-muted my-auto">
                        <button type="submit" className="btn btn-dark text-uppercase fw-bold">Obtener reporte</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Cliente
