import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileInvoice, faExclamation } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";

const Table = ({payment, cliente}) => {
    return (
        <div className="d-flex justify-content-around w-100">     
            {/* <Link className="btn btn-primary"
                to={
                    {  
                        pathname:"/pagos/edit",
                        state:{payment: payment}
                    }
                }

            >
                    <FontAwesomeIcon icon={faFileInvoice} />  <span className="fw-bold"></span>     
            </Link> */}
            {
                typeof cliente === 'undefined' ?

                    <Link className="btn btn-danger"
                    to={
                        {  
                            pathname:"/pagos/resolver",
                            state:{payment: payment}
                        }
                    }

                    ><FontAwesomeIcon icon={faExclamation} />  <span className="fw-bold"></span>     
                    </Link>

                :

                    <Link className="btn btn-primary"
                    to={
                        {  
                            pathname:"/pagos/edit",
                            state:{payment: payment}
                        }
                    }

                    ><FontAwesomeIcon icon={faFileInvoice} />  <span className="fw-bold"></span>     
                    </Link>
            }

        </div>
    )
}

export default Table
