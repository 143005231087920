import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
/*Vistas */
import Checkbox from '../layouts/Checkbox'
const AddSoftware = () => {

    const arr = [
        {
            name: ""
        },
    ];
    const prox = [
        {id: 0, name: 'No, mostrar como "Próximamente"'},
        {id: 1, name: 'Si, mostrar como "Comprar ahora"'}
    ]
    const [carga, setCarga] = useState(false);
    const [image, setImage] = useState('');
    const [so, setSo] = useState([]);
    const MySwal = withReactContent(Swal);
    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }
    const [option, setOption] = useState([]);
    // let option = new Array();
/***************************************************************************************** */
    useEffect(() => {
        
        const getSistemasOperativos = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            await axios.get(urlApi+'softwares/so', {headers: headers})
                 .then(response=>{
                     setSo(response.data);
                     console.log(response.data);
                 })
                 .catch(error => {
                     console.log(error);
                 })
        }

        for (let i = 0; i < 100; i++) {
            setOption(option => [...option, i]);
        }        

        getSistemasOperativos();
    }, [])    
    
/***************************************************************************************** */

    const sendSoftware = async (params, actions) => {

        console.log(params);
        // return;
        if (image==='') {
                document.getElementById("formFileSm").value = "";
                setImage('');
                MySwal.fire({
                    icon: 'info',
                    title: 'Debe subir una imagen (vacio)',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                    toast.addEventListener('mouseenter', MySwal.stopTimer)
                    toast.addEventListener('mouseleave', MySwal.resumeTimer)
                    }
                })
            return;
        }else{
            const type = image.type.split('/')
            console.log(image.type);
            console.log(type[0]);
            if(type[0]!=='image'){
                document.getElementById("formFileSm").value = "";
                setImage('');
                MySwal.fire({
                    icon: 'info',
                    title: 'Debe subir una imagen (archivo compatible)',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                    toast.addEventListener('mouseenter', MySwal.stopTimer)
                    toast.addEventListener('mouseleave', MySwal.resumeTimer)
                    }
                })
                console.log('pendejo');
                return;
            }
        }
        setCarga(true);
        let formData = new FormData();
        formData.append('archivo', image);
        formData.append('data', JSON.stringify(params));
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios({
            method: 'post',
            url: urlApi+'softwares',
            data: formData,
            headers: headers
          }).then((response) => {
                //Actualiza estados del formulario
                if(response.data.respuesta ==='correcto'){
                    setCarga(false);
                    actions.resetForm({
                        values: {
                          // the type of `values` inferred to be Blog
                            name:"",
                            description:"",
                            costo:"",
                        },
                      });
                    document.getElementById("formFileSm").value = "";
                    setImage('');
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else{
                    setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                }
                console.log(response.data);
            })
            .catch((error) => {
                setCarga(false);
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Ocurrio un error en la acción',
                  })
                console.log(error);
            });     
    }

//Validador de datos************************************************************************
    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo requerido'),
        description: Yup.string()
            .required('Campo requerido'),
        proximamente: Yup.number()
            .required('Campo requerido'),
        costo: Yup.number()
            .typeError('El costo debe ser un numero')
            .positive('Minino 1.00')
            .required('Campo requerido'),
        lista: Yup
            .array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required("Campo requerido")
        })
        ).required('debes tener al menos una licencia')
         .min(1, 'Debe tener al menos un elemento en la lista'),
        orden: Yup.number()
            .required('Campo requerido'),
        so: Yup.array()
            .required('Campo requerido')
    });
//RENDER ***********************************************************************************
    return (
        <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Añadir software</h2>
            <Formik
                enableReinitialize
                initialValues={{
                    name:"",
                    description:"",
                    costo:"",
                    proximamente:0,
                    lista: arr,
                    orden: 0,
                    so: []
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendSoftware(values, actions)}
                render={({ values, errors, touched, handleReset }) => {
                return (
                    <Form>
                        <div className="row">
                            <div className="card m-auto mt-5 w-50-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="card-title text-uppercase">Añadir software</h5>
                                    </div>
                                    <div className="row mb-3">
                                    </div>
                                    <p className="card-text text-muted">Llene los campos para añadir un software</p>
                                    {/* Datos de usuario */}
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Nombre</label>
                                        <Field type="text" className="form-control" name="name" placeholder="PDF Label Cut"/>
                                        <ErrorMessage
                                            name='name'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">Descripción</label>
                                        <Field as="textarea" className="form-control" name="description" placeholder="Cus PDFS" rows="3"></Field>
                                        <ErrorMessage
                                            name='description'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="costo" className="form-label">Costo</label>
                                        <Field type="text" className="form-control" name="costo" placeholder="19.20"/>
                                        <ErrorMessage
                                            name='costo'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="proximamente" className="form-label">¿Mostrar boton de "Comprar ahora"?</label>
                                        <Field as="select" className="form-select" name="proximamente">
                                            {
                                                prox.map((element,index) => {
                                                    return <option
                                                                value={element.id} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='proximamente'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="orden" className="form-label">Orden</label>
                                        <Field as="select" className="form-select" name="orden">
                                            {
                                                option.map((x, k) => {
                                                    return <option key={k} value={`${x}`} >{x}</option>       
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='orden'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="so" className="form-label fw-bold text-uppercase">Compatibilidad de sistema operativo</label>
                                        {
                                            so.map((value, index) =>{
                                            return <div className="form-check" key={index}>
                                                <Checkbox className="form-check-input" value={value.id} id={index+'as'} name="so"/>
                                                <label className="form-check-label text-capitalize" htmlFor={index+'as'}>
                                                    {value.nombre}
                                                </label>
                                            </div>
                                            })
                                        }
                                        
                                        <ErrorMessage
                                            name='so'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="formFileSm" className="form-label">Imagen de software</label>
                                        <input className="form-control form-control-sm" accept="image/x-png,image/gif,image/jpeg" id="formFileSm" type="file" name="image" onChange={handleImage}/>
                                    </div>
                                    <hr/>
                                    <div className="mb-3">
                                        <FieldArray
                                            name="lista"
                                            render={({insert, remove, push}) => ( 
                                            <>
                                                <div className="row">
                                                        <label className="col-form-label col-md-10">Listado descripción</label>
                                                        <div className="col-sm-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary mb-3 form-control-plaintext"
                                                                onClick={() => push({ name: "" })}
                                                            >+</button>
                                                        </div>
                                                </div>
                                                {values.lista.map((element, index) => (
                                                    <div key={index}>
                                                        <div className="input-group mb-3">
                                                            <Field type="text" className="form-control" name={`lista.${index}.name`} placeholder={`${index}.-¡Licencia de por vida`}/>
                                                            <button className="btn btn-dark" type="button" onClick={() => remove(index)}>-</button>
                                                        </div>
                                                        {/* {errors.lista &&
                                                            errors.lista[index] &&
                                                            errors.lista[index].name && (
                                                                <div className="field-error text-danger">
                                                                    {errors.lista[index].name}
                                                            </div>
                                                        )} */}
                                                    <ErrorMessage
                                                        name={`lista.${index}.name`}
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                                ))}
                                                
                                            </>
                                            )}
                                        />
                                    </div>

                                    {/* FIN Datos de usuario */}
                                    <hr/>
                                    <div className="my-4">
                                        <button type="submit" 
                                                className="btn btn-dark w-100 fw-bold text-uppercase"
                                                // onClick={sendDataPaypal}
                                        >Añadir software</button>
                                    </div>
                                    {
                                        carga &&
                                        <div className="w-100 text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                ); 
            }}
            />
        </div>
    )
}

export default AddSoftware
