import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'
import Table from './Table'
/*Globales*/
import {urlApi} from '../../global/Global'
import {
    NavLink
} from "react-router-dom";
import moment from 'moment';
import Loading from '../../styles/Loading'

 //Filtrar texto
const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="input-group col-md-12 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>X</button>
    </div> 
);

const Ftp = () => {

    
    const [ftp, setFtp] = useState([]); //datos de FTP
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    const [carga, setCarga] = useState(false);

    /*Filtros*/
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = ftp.filter(item => (item.contacto && item.contacto.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.cliente && item.cliente.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.correo && item.correo.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.ftp_user && item.ftp_user.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.observaciones && item.observaciones.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.fecha_expiracion && item.fecha_expiracion.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.fecha && item.fecha.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.ftp_pass && item.ftp_pass.toLowerCase().includes(filterText.toLowerCase()))
                                                 );
  
    /*Saber si hay activos*/
    const [expirados, setexpirados] = useState(false);
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);
    //DATATABLE
    const columns = [
        {
            name: 'Usuario FTP',
            // selector: 'ftp_user',
            sortable: true,
            cell : row => {
                return <div data-bs-toggle="tooltip" data-bs-placement="top" title={row.ftp_user} role="tooltip">{row.ftp_user}</div>
            }
            // 
        },
        {
            name: 'Pass FTP',
            selector: 'ftp_pass',
            sortable: true,
        },
        {
            name: 'Contacto',
            selector: 'contacto',
            sortable: true,
        },
        {
            name: 'Creación',
            // selector: 'contacto',
            sortable: true,
            cell : row => {
                const arr = row.created_at.split('T');
                return <div>{arr[0]}</div>
            }
        },
        {
            name: 'Activación',
            // selector: 'contacto',
            sortable: true,
            cell : row => {
                const arr = row.fecha.split('T');
                return <div>{arr[0]}</div>
            }
        },
        {
            name: 'Expiración (A-M-D)',
            // selector: 'contacto',
            sortable: true,
            cell : row => {
                
                const hoy = moment();
                const exp = moment(row.fecha_expiracion);
                const arr = row.fecha_expiracion.split('T');
                const dif = exp.diff(hoy, 'days');
                
                    if(dif <= 60 && dif > 0){
                        return <div className="bg-warning p-2 my-1 border border-secondary rounded-3">
                                <p className="fw-bold text-uppercase mb-0">Por expirar</p>
                                {arr}
                               </div>
                    }
                    else if(dif <= 0){
                        return <div className="bg-danger p-2 my-1 border border-secondary rounded-3">
                                <p className="fw-bold text-uppercase mb-0">Expirado</p>
                                {arr}
                                </div>
                    }else{
                        return <div>{arr}</div>
                    }
            }
        },
        {
            name: 'Opciones',
            // width: '30%',
            cell: row => <Table ftp={row}/>,
        },
      ];
    //Componente de cabecera
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);


    //Primer acción
    useEffect(() => {
        const getFtp = async () => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_ftp = await axios.get(urlApi+'ftp', {headers: headers});
                setFtp(get_ftp.data.ftp);
                setTotalRows(get_ftp.data.ftp_count);
                setLoading(false);
                console.log(get_ftp.data.ftp);
            } catch (error) {
                console.log(error);
            }
        }

        // const enableTooltip = () => {
        //     var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
        //     var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        //     return new bootstrap.Tooltip(tooltipTriggerEl)
        //     });

        // }

        getFtp();
        // enableTooltip();
    }, [])

    const handleChangeExpirados = async (e) => {
        // console.log(e.target.checked);
        setexpirados(e.target.checked);        
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'ftp/getftps', {page:1, perPage:10, expirados:!expirados}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setFtp(response.data.ftp);
                setTotalRows(response.data.ftp_count);
                setPerPage(10);
                setLoading(false);
                setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    //Cambio de pagina
    const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'ftp/getftps', {page:page, perPage:perPage, expirados:expirados}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setFtp(response.data.ftp);
                setTotalRows(response.data.ftp_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() =>{
                setCarga(false);
            });
    };

    //Cambio de rows
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'ftp/getftps', {page:page, perPage:newPerPage, expirados:expirados}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setFtp(response.data.ftp);
                setTotalRows(response.data.ftp_count);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };


    return (
        <>
            <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="row">
                            <h1 className="fw-bold text-uppercase col-md-9">Usuarios FTP</h1> 
                            <div className="col-md-3">
                                <NavLink className="btn btn-dark w-100" exact to="/ftp/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Registrar FTP</span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-check col-md-12 d-flex align-items-center">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => handleChangeExpirados(e)}/>
                                <label className="form-check-label text-uppercase fw-bold" style={{marginLeft: '.5rem'}} htmlFor="flexCheckDefault">
                                    Mostrar solo expirados y por expirar
                                </label>
                            </div>
                        </div>
                        <hr/>
                        {
                            !expirados ?
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            paginationRowsPerPageOptions={[10, 30, 50, 100]}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            striped
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            // subHeaderComponent={actionsMemo}
                            persistTableHead
                        />
                            :
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            paginationRowsPerPageOptions={[10, 30, 50, 100]}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            striped
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                        />
                        }
                        {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Ftp
