import React, {useEffect, useRef} from 'react'

const Message = () => {

    const modal    = useRef(null);
    const modalCls = useRef(null);

    const no_mostrar = () => { 
        console.log('adasd');
        let expiresdate =  new Date('2068', '1', '02', '11', '20');
        let cookievalue = "no_mostrar_val_payments";
        document.cookie = "no_mostrar_payments=" + encodeURIComponent( cookievalue ) + ";expires=" + expiresdate.toUTCString()+'; SameSite=Strict';
        modalCls.current.click();
    }

    useEffect(() => {
        const first = () => { 
            if(document.cookie.indexOf('no_mostrar_payments=')==-1){
                modal.current.click();
            }else{
                console.log(document.cookie);
            } 
        }

      first();

    }, [])
    
   

    return (
    <>
        <button ref={modal} type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#exampleModal"></button>

        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h2 className='fw-bold mb-2'>¡Atención, usuarios de Label Dictate!</h2>
                        <button ref={modalCls} type="button" className="d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                        {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                    </div>
                    <div className="modal-body">
                                                
                        <p className='text-justify'>
                            <b>Nos complace anunciarles un cambio importante que se implementará a partir del segundo semestre de 2023.</b> Con el objetivo de mejorar nuestra oferta y brindarte aún más beneficios, estamos evolucionando nuestra forma de activación de licencias.
                        </p>
                                            
                        <p className='text-justify'>
                            <b>¿Qué cambia?</b> Pasamos de un sistema de licencia por dispositivo, que permitía un máximo de tres activaciones, a un esquema de suscripción por aplicación. Las aplicaciones que se incorporarán a este nuevo modelo son: Text2 Barcode, Suite Mobile POS, PDF Label Cut y Web2 Barcode.
                        </p>
                                            
                        <p className='text-justify'>
                            <b>¿Por qué el cambio?</b> Estamos comprometidos en ofrecerte un mejor servicio y ventajas adicionales. Este cambio te permitirá tener acceso a mejores precios y a un sistema centralizado para administrar tus suscripciones contratadas a través del nuevo Portal Label Dictate: https://portal.labeldictate.com. Importante mencionar que solo las aplicaciones que se actualicen con este nuevo método de suscripción a partir de julio de 2023 podrán disfrutar de futuras actualizaciones sin costo adicional. En cuanto a las versiones liberadas antes de julio de 2023, seguirán funcionando de acuerdo a las capacidades de esas versiones. Este cambio también te permitirá disfrutar de las innovadoras herramientas que Label Dictate tiene preparadas para ti.
                        </p>                        
                        <p className='text-justify'>
                            <b>¿Y si soy un usuario actual?</b> Si eres usuario de las versiones actuales que no manejan suscripciones, ¡no te preocupes! Podrás seguir utilizando tu aplicación y tus activaciones restantes como lo haces ahora. Cuando hayas agotado tus activaciones, tendrás la opción de adquirir la nueva versión y disfrutar de todos los beneficios y actualizaciones futuras.
                        </p>
                                            
                        <p className='text-justify'>
                            <b>¡Acompáñanos en esta transición!</b> Estos cambios se implementarán solo en las nuevas versiones que se lancen a partir de julio de 2023.
                        </p>
                                                
                        <p className='text-justify'>
                            Estamos emocionados por este cambio y confiamos en que mejorará tu experiencia con Label Dictate. Como siempre, gracias por ser parte de nuestra comunidad.
                        </p>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={() => no_mostrar()}>No volver a mostrar</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Message