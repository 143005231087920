import React, {useEffect, useState} from 'react'
import { HorizontalBar } from '@reactchartjs/react-chart.js'

const HorizontalBarChartFechas = ({fechas}) => {


      const [fec_arr, setfec_arr] = useState([])
      const [sum_arr, setsum_arr] = useState([])

      useEffect(() => {
          console.log(fechas)
          
          const getFechas = async () => {
            await fechas.map(e => {

              setfec_arr(fec_arr => [
                  ...fec_arr,
                  e.months
                ])

              setsum_arr(sum_arr => [
                  ...sum_arr,
                  e.sums
                ])

            })
          }
          
          getFechas();
        
      }, [fechas])


        const data = {
          labels: fec_arr,
          datasets: [
            {
              label: 'Creaciones',
              data: sum_arr,
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
              ],
              borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
              ],
              borderWidth: 1,
            },
          ],
        }
        
        const options = {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }
  
        return (
          <>
            <div className='header'>
              <h1 className='title'>Creaciones</h1>
            </div>
            <HorizontalBar data={data} options={options} />
          </>
        )
}
export default HorizontalBarChartFechas