import React, {useEffect, useState, useRef} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
/*Globales*/
import {url, urlApi} from '../../global/Global'
import Particles from 'react-particles-js';
// import Auth from '../auth/Auth';
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faShare, faTimes } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
const Provisional = (props) => {

/***************************************************************************************************/
    const [id, setid] = useState(props.match.params.id);
    const [paises, setPaises] = useState([]);
    const [carga, setcarga] = useState(false); 
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    //Redirect
    let history = useHistory();
/***************************************************************************************************/

    useEffect(() => {
        
        const getPaises = async (params) => {
            try {
                const get_paises = await axios.get('https://restcountries.eu/rest/v2/all');
                setPaises(get_paises.data)
                console.log(get_paises.data[0].name);
            } catch (error) {
                console.log(error);
            }
        }
        getPaises();
        
    }, [])

/***************************************************************************************************/

    const sendData = async (params) => {
        params.software = id;
        setcarga(true)
        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'promocional', params, {headers: headers})
                    .then(function (response) {
                        if(response.data.respuesta === 'correcto'){
                            MySwal.fire({
                                icon: 'success',
                                title: 'Corrrect',
                                text: 'Action done correctly',
                              })
                            history.push('/payments');
                        }
                        console.log(response.data);                        
                    })
                    .catch(function (error) {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'Action error',
                          })
                        console.log(error);
                    }).then(function (response) {
                        setcarga(false);
                    });
    }
    

/***************************************************************************************************/

    if(!props.mantenimiento){
        history.push('/payments');
    }
/***************************************************************************************************/

    //Validador de datos
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formSchema = Yup.object().shape({
        correo: Yup.string()
            .required('Campo requerido')
            .email('Correo electronico invalido')
            .max(50, 'Maximo de caracteres'),
        nombre: Yup.string()
            .min(5, 'Minimo 5 caracteres')
            .max(50, 'Maximo de 255 caracteres')
            .required('Campo requerido'),
        empresa: Yup.string()
            .max(50, 'Maximo de 255 caracteres'),
        telefono:   Yup.string('Debe ser un numero')
            .required('Campo requerido')
            .matches(phoneRegExp, 'Numero de telefono no valido')
            .max(8, 'minimo')
            .max(12, 'maximo 12'),
        pais: Yup.string()
            .required('Campo requerido'),
        });

/***************************************************************************************************/

    return (
        <div className="container m-0 p-0 mw-100">
            <div className="banner-pro">
                <div className="container">
                    <h2 className="fw-bold text-uppercase d-none">¡Ya casi terminas! Completa tu orden</h2>
                    <span style={{fontSize: '0.00001px'}} id="compras">-</span>
                    <span style={{fontSize: '0.00001px'}} id="nosotros">-</span>
                    <span style={{fontSize: '0.00001px'}} id="contacto">-</span>
                    <Formik
                        enableReinitialize
                        initialValues={{
                            correo:"",
                            nombre:"",
                            empresa:"",
                            telefono:"",
                            pais: "Mexico",
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values) => sendData(values)}
                    >
                        <Form>
                            <div className="card m-auto  w-50-100" style={{backgroundColor: '#ffe4bf'}}>
                                <div className="card-body">
                                    <h6 className="card-subtitle my-2 text-uppercase">Professional license</h6>

                                    <p className="card-text text-muted">To request your professional license valid until June 2022, please fill in the following information</p>
                                    <p className="card-text text-muted">Required fields (*)</p>
                                    {/* Datos de usuario */}
                                    <div className="mb-3">
                                        <label htmlFor="correo" className="form-label">Email (*)</label>
                                        <Field type="email" className="form-control" name="correo" placeholder="name@example.com"/>
                                        <ErrorMessage
                                            name='correo'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="nombre" className="form-label">Fisrtname (*)</label>
                                        <Field type="text" className="form-control" name="nombre" placeholder="-"/>
                                        <ErrorMessage
                                            name='nombre'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="empresa" className="form-label">Company</label>
                                        <Field type="text" className="form-control" name="empresa" placeholder="-"/>
                                        <ErrorMessage
                                            name='empresa'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="telefono" className="form-label">Phone (*)</label>
                                        <Field type="tel" className="form-control" name="telefono" placeholder="#"/>
                                        <ErrorMessage
                                            name='telefono'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pais" className="form-label">Country (*)</label>
                                        <Field as="select" className="form-control select-custom" name="pais">
                                            {
                                                paises.map((element,index) => {
                                                    return  <option
                                                                value={element.name} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='pais'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                    {/* FIN Datos de usuario */}
                                    <hr/>
                                    
                                    <div className="my-4">
                                        
                                        <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"                                            
                                        >Get now</button>                                        
                                        
                                    </div>
                                    {
                                        carga &&
                                        <div className="w-100 text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Provisional
