import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'

const EditRegistrado = (props) => {

    const regi = props.location.state.registrado;
    const [softwares, setSoftwares] = useState([]);
    const [carga, setCarga] = useState(false);
    // console.log(regi);
    /*CONTEXT DE USUARIO */
    // const { headers } = useContext(LoginContext);
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const getSoftwares = async () => {
            try {
                const get_softwares = await axios.get(urlApi+'softwares');
                setSoftwares(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }
        getSoftwares();
    }, [])

    const sendRegistrado = async (params) => {
        //Si se carga una imagen
       
        //Envio normal de datos
        setCarga(true);
        const send_data = {
            params: params,
            id: regi.id
        }
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.put(urlApi+'registrados/update', send_data, {headers: headers})
          .then((response) => {
                setCarga(false);
                console.log(response.data)
                //Actualiza estados del formulario
                if(response.data.respuesta ==='correcto'){
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else{
                    setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                }
                
            }).catch((error) => {
                setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                console.log(error);
            });     
    }

    //Validador de datos
    const formSchema = Yup.object().shape({
        mac: Yup.string()
            .required('Campo requerido'),
        version: Yup.string()
            .required('Campo requerido'),
        software: Yup.number()
            .typeError('El costo debe ser un numero')
            .required('Campo requerido'),
    });

    return (
        <div className="container my-5">
        <h2 className="fw-bold text-uppercase">Editar registro</h2>
        <Formik
            enableReinitialize
            initialValues={{
                mac:regi.mac_address,
                version:regi.version,
                software:regi.software.id
            }}
            validationSchema={formSchema}
            onSubmit={(values) => sendRegistrado(values)}
        >
            <Form>
                <div className="row">
                    <div className="card m-auto mt-5 w-50-100">
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <h5 className="card-title text-uppercase">Editar registro</h5>
                            </div>
                            <div className="row mb-3">
                                <span className="fw-bold text-muted text-uppercase">Licencia:
                                    <span className="card-text text-muted"> {regi.licencia.codigo_activacion}</span>
                                </span>
                            </div>
                            <p className="card-text text-muted">Llene los campos para editar el registro</p>
                            {/* Datos de usuario */}
                            <div className="mb-3">
                                <label htmlFor="mac" className="form-label">MAC address</label>
                                <Field type="text" className="form-control" name="mac" placeholder="PDF Label Cut"/>
                                <ErrorMessage
                                    name='mac'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="version" className="form-label">Version</label>
                                <Field type="text" className="form-control" name="version" placeholder="PDF Label Cut"/>
                                <ErrorMessage
                                    name='version'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="software" className="form-label">Software</label>
                                <Field as="select" className="form-select" name="software" placeholder="PDF Label Cut">
                                    {
                                        softwares.map((element,index) => {
                                            return  <option
                                                        value={element.id} 
                                                        key={index}
                                                        >{element.name}
                                                    </option>
                                        })
                                    }
                                </Field>
                                <ErrorMessage
                                    name='software'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                            
                            {/* FIN Datos de usuario */}
                            <hr/>
                            
                            <div className="my-4">
                                <button type="submit" 
                                        className="btn btn-dark w-100 fw-bold text-uppercase"
                                        // onClick={sendDataPaypal}
                                >Actualizar registro</button>
                            </div>
                            {
                                carga &&
                                <div className="w-100 text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    </div>
    )
}

export default EditRegistrado
