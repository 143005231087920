const _url = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/' : 'https://payments.labeldictate.com/';
const _urlApi = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/api/' : 'https://payments.labeldictate.com/api/';
// const _url = process.env.NODE_ENV !== 'production' ? 'http://192.168.1.18/licencias/' : 'https://payments.labeldictate.com/';
// const _urlApi = process.env.NODE_ENV !== 'production' ? 'http://192.168.1.18/licencias/api/' : 'https://payments.labeldictate.com/api/';
// const _url = process.env.NODE_ENV !== 'production' ? 'http://25.57.43.31:8000/' : 'https://payments.labeldictate.com/';
// const _urlApi = process.env.NODE_ENV !== 'production' ? 'http://25.57.43.31:8000/api/' : 'https://payments.labeldictate.com/api/';
export const urlApi = _urlApi;
export const url = _url;






            
