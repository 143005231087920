import React, { useContext, useState } from 'react'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useHistory } from "react-router-dom";
/*Globales*/
import {urlApi} from '../../global/Global'
import LoginContext from '../../global/login/loginContext';
/*img*/
import labelDictate from '../../img/labeldictate_2.png'

const Login = ({setExist, setUser}) => {
    
    const [carga, setCarga] = useState(false);
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    /*Redireccion */
    const history = useHistory();
    /*CONTEXT DE USUARIO */
    const { agregarUser } = useContext(LoginContext);

    const login = async (params, actions) => {
        setCarga(true);
        const headers = {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        }
      
        await axios.post(urlApi+'auth/login', params, {headers: headers})
                    .then(async (response) =>{
                        const jsonValue = JSON.stringify(response.data);
                        setCarga(false);
                        await AsyncStorage.setItem(
                                '@login',
                                jsonValue
                            );
                            setExist(true);
                            setUser(response.data);
                            agregarUser(response.data);
                            MySwal.fire({
                                icon: 'success',
                                title: 'Bienvenido',
                                text: response.data.name,
                            });
                            history.push('/licencias');
                    }).catch((error) => {
                        setCarga(false);
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'No existen coincidencias',
                            });
                            // console.log(error);
                    });
    }

    //Validador de datos
    const formSchema = Yup.object().shape({
        email: Yup.string()
            .required('Campo requerido')
            .email('Debe ser un correo'),
        password: Yup.string()
            .required('Campo requerido'),
    });

    return (
        <>
           <Formik
                enableReinitialize
                initialValues={{
                    email:"",
                    password:"",
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => login(values, actions)}
            >
                <Form className="mb-5">       
                    <div className="row no-bg-gutter">
                            <div className="card m-auto mt-5 w-32-pc">
                                <h5 className="card-header">Iniciar sesión</h5>
                                <div className="card-body pb-5">
                                    <div className="row text-center">
                                        <div className="w-100">
                                            
                                            <img className="img-fluid" src={labelDictate} style={{width: '300px'}}/>
                                        </div>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label htmlFor="exampleInputEmail1" className="text-uppercase fw-bold">Correo Electronico</label>
                                        <Field 
                                            type="email" 
                                            className="form-control" 
                                            id="exampleInputEmail1" 
                                            aria-describedby="emailHelp"
                                            name="email"
                                        />
                                        <ErrorMessage
                                            name='email'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                        <small id="emailHelp" className="form-text text-muted">Nunca comparta su correo con nadie mas.</small>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleInputPassword1" className="text-uppercase fw-bold">Contraseña</label>
                                        <Field 
                                            type="password" 
                                            className="form-control" 
                                            id="exampleInputPassword1" 
                                            name="password"
                                        />
                                         <ErrorMessage
                                            name='password'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>
                                    
                                <div className="card-footer pt-5" style={{backgroundColor: '#fcecd7', borderTop: 'none'}}>
                                    <div className="col-md-12 my-4">
                                        <button className="btn btn-dark fw-bold text-uppercase w-100" type="submit">Iniciar sesión</button>
                                    </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                                </div>
                            </div>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

export default Login
