import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

/*Vista tabla*/
import Table from './Table';

import {
    NavLink
} from "react-router-dom";
/*Globales*/
import { urlApi } from '../../global/Global'
import Loading from '../../styles/Loading'
 //Filtrar texto
 const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="input-group col-md-12 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>X</button>
    </div> 
);
const Softwares = () => {

    const [softwares, setSoftwares] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    const [carga, setCarga] = useState(false);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = softwares.filter(item => (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.amount.toString() && item.amount.toString().toLowerCase().includes(filterText.toLowerCase()))
                                                 );
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);
    //DATATABLE
    const columns = [
        {
            name: 'Nombre',
            // selector: 'name',
            sortable: true,
            
            cell: row =>{
                return (row.deleted_at == null || typeof(row.deleted_at)=='undefined')?
                // console.log(row.deleted_at)
                <p className="p-0">{row.name}</p>
                :
                <p className="p-0 text-muted">{row.name} (desactivado)</p>

            }
        },
        {
            name: '$ Costo $',
            // selector: 'amount',
            sortable: true,
            cell: row =>{
                return (row.deleted_at == null || typeof(row.deleted_at)=='undefined')?
                // console.log(row.deleted_at)
                <p className="p-0">{row.amount}</p>
                :
                <p className="p-0 text-muted">{row.amount} (desactivado)</p>

            }
            // width: '30%',
        },
        {
            name: 'Boton',
            // selector: 'amount',
            sortable: true,
            cell: row =>{
                return (row.deleted_at == null || typeof(row.deleted_at)=='undefined') ?
                // console.log(row.deleted_at)
                    (
                        row.proximamente === 0 ?
                            <p className="p-0">Próximamente</p>
                        :
                            <p className="p-0">Comprar ahora</p>
                    )
                :
                    (
                        row.proximamente === 1 ?
                            <p className="p-0 text-muted">Próximamente (desactivado)</p>
                        :
                            <p className="p-0 text-muted">Comprar ahora (desactivado)</p>
                    )

            }
            // width: '30%',
        },
        {
            name: 'Orden',
            // selector: 'amount',
            sortable: true,
            cell: row =>{
                return (row.deleted_at == null || typeof(row.deleted_at)=='undefined')?
                // console.log(row.deleted_at)
                <p className="p-0">{row.orden}</p>
                :
                <p className="p-0 text-muted">{row.orden} (desactivado)</p>

            }
            // width: '30%',
        },
        {
            name: 'SO',
            // selector: 'amount',
            sortable: true,
            cell: row =>(
                (row.deleted_at == null || typeof(row.deleted_at)=='undefined')?
                <ul className="m-0 p-0 py-2">
                    {
                        row.sistemasoperativos.map((e, k) =>{
                            return <li className="p-0 text-capitalize" key={k}>{e.nombre}</li>
                        })
                    }
                </ul>
                :
                <ul className="m-0 p-0 py-2">
                    {
                        row.sistemasoperativos.map((e, k) =>{
                            return <li className="p-0 text-capitalize text-muted" key={k}>{e.nombre} (desactivado)</li>
                        })
                    }
                </ul>
            )
            // width: '30%',
        },
        {
            name: 'Opciones',
            // width: '10%',
            cell: row => <Table 
                            software={row}
                            setSoftwares={setSoftwares}
                            softwares={softwares}
                            totalRows={totalRows}
                            setTotalRows={setTotalRows}
                         />,
        },
      ];
    //Componente de cabecera
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);


    //Primer acción
        useEffect(() => {
            const getSoftwares = async (page) => {
                setLoading(true);
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }

                await axios.post(urlApi+'software/getsw', {page:page, perPage:perPage}, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        setSoftwares(response.data.software);
                        setTotalRows(response.data.soft_count);
                        setLoading(false);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });                    
            }
            getSoftwares(1);
        }, [])

    //Cambio de pagina
    const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'software/getsw', {page:page, perPage:perPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setSoftwares(response.data.software);
                // setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            })
            .then(() =>{
                setCarga(false);
            })
    };

    //Cambio de rows
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'software/getsw', {page:page, perPage:newPerPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setSoftwares(response.data.software);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };

    return (
        <div>
            <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="row">
                            <h1 className="fw-bold text-uppercase col-md-9">Softwares</h1> 
                            <div className="col-md-3 text-align-res">
                                <NavLink className="btn btn-dark w-100" exact to="/softwares/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Registrar software</span>
                                </NavLink>
                            </div>
                        </div> 
                        <hr/>
                        <DataTable
                            // title="Licencias"
                            columns={columns}
                            data={filteredItems}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            striped
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                        />
                        {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Softwares
