import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

/*Vista tabla*/
import Table from './Table';
/*Globales*/
import {urlApi} from '../../global/Global';
/* Agregar caracter */
import {agregarCaracter} from '../../global/agregarCaracter';
import Loading from '../../styles/Loading'

const FilterComponent = ({ filterText, onFilter, onClear, cargaBtn }) => (
    <div className="input-group col-md-6 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        {
            !cargaBtn ?
                <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>
                    X
                </button>
            :
            <button className="btn btn-danger" type="button" id="button-addon1" disabled>
                    X
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                <span className="visually-hidden">Loading...</span>
            </button>
        }
    </div> 
);

const Registrados = () => {

    const [cargaBtn, setCargaBtn] = useState(false);
    const [registrados, setRegistrados] = useState([]);
    const [perRegistrados, setPerRegistrados] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    const [carga, setCarga] = useState(false);

    console.log(registrados);
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);
    /*Filtros*/
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    // const filteredItems = registrados.filter(item => (item.mac_address && item.mac_address.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.version && item.version.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.licencia.codigo_activacion && item.licencia.codigo_activacion.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.cod_car && item.cod_car.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.software.name && item.software.name.toLowerCase().includes(filterText.toLowerCase()))
    //                                              );
    //Paramas de string a enviar
    let string = '';
    //DATATABLE
    const columns = [
        {
            name: 'MAC address',
            selector: 'mac_address',
            sortable: true,
            // width: '30%',
        },
        {
            name: 'Versión',
            selector: 'version',
            sortable: true,
            // width: '30%',
        },
        {
            name: 'Licencia',
            sortable: true,
            // width: '30%',
            cell: row => {
                return <span data-bs-toggle="tooltip" data-bs-placement="bottom" title={agregarCaracter(row.licencia.codigo_activacion, '-', 4)}>
                    {agregarCaracter(row.licencia.codigo_activacion, '-', 4)}
                </span>
            }
        },
        {
            name: 'Software',
            selector: 'software.name',
            sortable: true,
            // width: '30%',
        },
        {
            name: 'Cliente',
            selector: 'cliente.correo',
            sortable: true,
            // width: '30%',
        },
        {
            name: 'Fecha registro',
            // selector: 'created_at',
            sortable: true,
            cell: row => {
                const fecha = row.created_at.split('T');
                return ( 
                    <div className="d-flex flex-column w-100">
                        <p className="p-0 my-1 d-inline w-100">Fecha: {fecha[0]} </p>
                        <p className="p-0 my-1 d-inline w-100">Hora: {fecha[1].substr(0,8)}</p>
                    </div>
                )
            }
        },
        {
            name: 'Opciones',
            // width: '10%',
            cell: row => <Table 
                            registrado={row}
                            setRegistrados={setRegistrados}
                            registrados={registrados}
                            totalRows={totalRows}
                            setTotalRows={setTotalRows}
                         />,
        },
      ];

       //Componente de cabecera
        const subHeaderComponentMemo = React.useMemo(() => {
            const handleClear = () => {
                if (filterText) {
                    setResetPaginationToggle(!resetPaginationToggle);
                    setFilterText('');
                    setRegistrados(perRegistrados);
                    string = '';
                }
            };

            return <FilterComponent 
                        onFilter={e => handleChangeFilter(e.target.value)} 
                        onClear={handleClear} 
                        filterText={filterText} 
                        cargaBtn={cargaBtn}
                    />;
        }, [filterText, resetPaginationToggle, cargaBtn]);

        //Primer acción
        useEffect(() => {
            const getRegistrados = async (page) => {
                setLoading(true);
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }

                await axios.post(urlApi+'registrados', {page:page, perPage:perPage}, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        response.data.registro.map((el, index) =>{
                            response.data.registro[index].cod_car = agregarCaracter(el.licencia.codigo_activacion,'-',4)
                        })
                        setRegistrados(response.data.registro);
                        setPerRegistrados(response.data.registro);
                        setTotalRows(response.data.registro_count);
                        setLoading(false);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error.data);
                    });                    
            }
            getRegistrados(1);
        }, [])

    //***************************************************************************************** */
    const handleChangeFilter = async (e) => {
        // console.log(e.target.checked);
        setCargaBtn(true);
        setFilterText(e);
        string += e;
        console.log(string);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'registrados/filter', {page:1, perPage:10, string:string}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setRegistrados(response.data.registro);
                setTotalRows(response.data.registro_count);
                setLoading(false);
                // setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
                setCargaBtn(false);
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                setCargaBtn(false);
            });
    }

    //Cambio de pagina **************************************************************************************** */
    const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'registrados', {page:page, perPage:perPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.registro.map((el, index) =>{
                    response.data.registro[index].cod_car = agregarCaracter(el.licencia.codigo_activacion,'-',4)
                })
                setRegistrados(response.data.registro);
                // setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() =>{
                setCarga(false);
            });
    };

    //Cambio de rows
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'registrados', {page:page, perPage:newPerPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.registro.map((el, index) =>{
                    response.data.registro[index].cod_car = agregarCaracter(el.licencia.codigo_activacion,'-',4)
                })
                setRegistrados(response.data.registro);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };


    return (
        <div>
            <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="fw-bold text-uppercase">Registrados </h1> 
                        </div> 
                        <hr/>
                        <DataTable
                            // title="Licencias"
                            columns={columns}
                            data={registrados}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                            striped
                        />
                        {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registrados
