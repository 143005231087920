import React from 'react'

 /*Custom loading*/

 const loading = () => {
      
    const randomnumber = Math.floor(Math.random() * (1 - 0 + 1)) + 0;
    const arr = new Array();
    arr[0] = 'border';
    arr[1] = 'grow';

    return (
        <div className="w-100 text-center my-5 text-white">
            <div className={`spinner-`+arr[randomnumber]} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
  };

export default loading
