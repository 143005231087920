import React, {useState, useEffect} from 'react'
import {
    Link
} from "react-router-dom";
  /*Globales*/
import {url as urlno} from '../../global/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinux, faWindows, faApple, faAndroid, faAppStoreIos } from '@fortawesome/free-brands-svg-icons';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from "react-router-dom";
const Softwares = ({software, index, mantenimiento}) => {


    const image = urlno+'storage/'+software.image;
    const url = '/main/';
    const pro = '/promocional/';
    const [contador, setContador] = useState(index);
    const [listas, setListas] = useState([]);
    //SweetAlert2
    const MySwal = withReactContent(Swal);
    //Redirect
    let history = useHistory();
/*********************************************************************************************/
    useEffect(() => {

        const separar = (params) => {
            if (software.lista!=='') {
                setListas(JSON.parse(software.lista));
                console.log(JSON.parse(software.lista));
            }
        }        
       
        separar();
    }, [])

/*********************************************************************************************/

    const handleClickGetNow = () => {
        MySwal.fire({
            title: 'Maintenance!',
            text: "At the moment the system is under maintenance, to offer you a better service you can request a professional license valid until June 2022 at no cost. In case of requesting the professional license please click on the continue button.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continue'
          }).then((result) => {
            if (result.isConfirmed) {
                history.push(pro+software.id);
            }else{
                return false;
            }
          })
    }
    

/*********************************************************************************************/

    return (
        <div className="col-lg-4 d-flex align-items-stretch text-center holi mb-5">
            <div className="card bg-nowhite d-flex flex-column h-100">
                <div className="card-header d-flex align-items-center justify-content-center">
                    
                    {
                        software.proximamente == 1 ?
                        
                            !mantenimiento ?
                                <Link className="py-1" to={url+software.id}>
                                    <img src={image} className="card-img-top align-self-center img-fluid" alt={software.name} style={{height: "100px", width: 'auto'}}/>
                                </Link>     
                            :
                                <div className="py-1">
                                    <img src={image} className="card-img-top align-self-center img-fluid" alt={software.name} style={{height: "100px", width: 'auto'}}/>
                                </div>     
                        
                            
                        :
                            <div className="py-1">
                                <img src={image} className="card-img-top align-self-center img-fluid" alt={software.name} style={{height: "100px", width: 'auto'}}/>
                            </div>
                    }
                </div>
                <div className="card-body h-100 mb-3">
                    <div className="body-80">
                        <h5 className="card-title fw-bold text-uppercase">{software.name}</h5>
                        
                        <p className="card-text text-start">{software.description}</p>
                            <ul className="text-start ul">
                                {   
                                    listas.map((e, i) =>{
                                        return <li key={i}>{e.name}</li>
                                    })
                                    
                                }
                            </ul>
                    </div>
                    <div className="body-20">
                        <h6 className="fw-bold text-uppercase">Only compatible with</h6>
                        <div className="d-flex justify-content-evenly">
                        {
                            software.sistemasoperativos.map((e,k) =>{
                                    switch (e.nombre) {
                                        case 'windows':
                                            return <div key={k}>
                                                        <FontAwesomeIcon icon={faWindows} color={e.color} size="3x" style={{marginRight: 10, marginLeft: 10}}/>
                                                        <p>Windows</p>
                                                   </div>
                                        case 'mac':
                                            return  <div key={k}> 
                                                        <FontAwesomeIcon icon={faApple} color={e.color} size="3x" style={{marginRight: 10, marginLeft: 10}}/>
                                                        <p>Mac OS</p>
                                                    </div>

                                        case 'linux':
                                            return  <div key={k}> 
                                                        <FontAwesomeIcon icon={faLinux} color={e.color} size="3x" style={{marginRight: 10, marginLeft: 10}}/>
                                                        <p>Linux</p>
                                                    </div>

                                        case 'android':
                                            return  <div key={k}> 
                                                        <FontAwesomeIcon icon={faAndroid} color={e.color} size="3x" style={{marginRight: 10, marginLeft: 10}}/>
                                                        <p>Android</p>
                                                    </div>
                                        
                                        case 'ios':
                                            return  <div key={k}> 
                                                        <FontAwesomeIcon icon={faAppStoreIos} color={e.color} size="3x" style={{marginRight: 10, marginLeft: 10}}/>
                                                        <p>IOS</p>
                                                    </div>
                                    
                                        default:
                                            break;
                                    }
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className="card-footer">   
                {
                    software.proximamente==1 ?
                    <>
                        <p className="card-text fw-bold h3"><span className="h5">USD$ </span>{software.amount}</p>
                        {
                            !mantenimiento ?
                            <Link className={mantenimiento ? `btn btn-dark text-uppercase fw-bold mb-3 disabled` :  `btn btn-dark text-uppercase fw-bold mb-3`} 
                                to={
                                    {  
                                        pathname:"/comprar/"+software.id,
                                        state:{software: software}
                                    }
                                }
                                >Buy now
                            </Link>
                            :
                            // <button 
                            //     type='button'
                            //     onClick={handleClickGetNow}
                            //     className={`btn btn-dark text-uppercase fw-bold mb-3`}
                            //     >Get now
                            // </button>
                            <button className={mantenimiento ? `btn btn-dark text-uppercase fw-bold mb-3 disabled` :  `btn btn-dark text-uppercase fw-bold mb-3`} 
                                >Buy now
                            </button>
                        }
                        
                    </>
                    :
                    <>
                        <p className="card-text fw-bold h3"><span className="h5"></span>---</p>
                        <button type="button" className="btn btn-danger text-uppercase fw-bold mb-3" disabled>
                            Cooming soon!
                        </button>
                    </>
                }

                   
                </div>
            </div>
        </div>
    )
}

export default Softwares
