import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';

/*Globales*/
import {urlApi} from '../../global/Global'


const EditCliente = (props) => {

    //Props
    const cliente = props.location.state.cliente;
    const [carga, setCarga] = useState(false);
    const [promos, setpromos] = useState(false);
    const [promoValue, setpromoValue] = useState(cliente.promociones);
    //SweetAlert2
    const MySwal = withReactContent(Swal);
    const [paises, setPaises] = useState([]);
    
    useEffect(() => {
        console.log(cliente);
        const getPaises = async (params) => {
            try {
                const get_paises = await axios.get('https://restcountries.com/v2/all');
                setPaises(get_paises.data)
                console.log(get_paises.data[0].name);
            } catch (error) {
                console.log(error);
            }
        }

        const setNamePais = () => {
            if(cliente.pais === '-'){
                cliente.pais = 'Mexico'
            }
            if(cliente.promociones === 1){
                setpromos(true);
                setpromoValue(1);
            }
        }        

        getPaises();
        setNamePais();
    }, [])

    //Handle click
    const handleClick = (e) => {
        setpromos(!promos);
        if(e.target.checked){
            setpromoValue(1);
        }else{
            setpromoValue(0);
        }
    }
    

    const sendData = async (params) => {
        setCarga(true);

        const send_data = {
            params: params,
            id: cliente.id
        }

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.put(urlApi+'clientes/update', send_data, {headers: headers})
            .then((response) => {
                if (response.data.respuesta==='correcto') {
                    setCarga(false);
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else{
                    setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error',
                      })
                    }
                })
                .catch((error) =>{
                    setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error',
                    })
                    console.log(error);
            })
    }
    

     //Validador de datos
     const formSchema = Yup.object().shape({
        correo: Yup.string()
            .required('Campo requerido'),
        nombre: Yup.string()
            .required('Campo requerido'),
        empresa: Yup.string()
            .required('Campo requerido'),
        telefono: Yup.string()
            .required('Campo requerido'),
        pais: Yup.string()
            .required('Campo requerido'),
        promociones: Yup.number()
            .required('Campo requerido'),
    });

    return (
        <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Clientes</h2>
            <Formik
                enableReinitialize
                initialValues={{
                    correo:cliente.correo,
                    nombre:cliente.nombre,
                    empresa:cliente.empresa,
                    telefono:cliente.telefono,
                    pais: cliente.pais,
                    promociones: promoValue
                }}
                validationSchema={formSchema}
                onSubmit={(values) => sendData(values)}
            >
                <Form>
                    <div className="row">
                        <div className="card m-auto mt-5 w-50-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between mb-4">
                                    <h5 className="card-title text-uppercase">Editar cliente</h5>
                                </div>
                                {/* Datos de usuario */}
                                <div className="mb-3">
                                    <label htmlFor="correo" className="form-label">Correo (*)</label>
                                    <Field type="email" className="form-control" name="correo" placeholder="name@example.com"/>
                                    <ErrorMessage
                                        name='correo'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label">Nombre (*)</label>
                                    <Field type="text" className="form-control" name="nombre" placeholder="John Smith"/>
                                    <ErrorMessage
                                        name='nombre'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="empresa" className="form-label">Empresa</label>
                                    <Field type="text" className="form-control" name="empresa" placeholder="FIFA"/>
                                    <ErrorMessage
                                        name='empresa'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="telefono" className="form-label">Telefono (*)</label>
                                    <Field type="text" className="form-control" name="telefono" placeholder="334455122"/>
                                    <ErrorMessage
                                        name='telefono'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="pais" className="form-label">País (*)</label>
                                    <Field as="select" className="form-control select-custom" name="pais">
                                        {
                                            paises.map((element,index) => {
                                                return  <option
                                                            value={element.name} 
                                                            key={index}
                                                            >{element.name}
                                                        </option>
                                            })
                                        }
                                    </Field>
                                    <ErrorMessage
                                        name='pais'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="col-md-12">
                                        <hr/>
                                        <div className="form-check">
                                            <input 
                                                type="checkbox"
                                                className="form-check-input" 
                                                value={promoValue} 
                                                id="promociones" 
                                                name="promociones"
                                                checked={promos}
                                                onChange={(e) => handleClick(e)}
                                                />
                                            <label className="form-check-label text-uppercase fw-bold" htmlFor="promociones">
                                                Recibir y aceptar promociones para este cliente
                                            </label>
                                        </div>
                                        <ErrorMessage
                                            name='promociones'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                {/* FIN Datos de usuario */}
                                <hr/>
                               
                                <div className="my-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                            // onClick={sendData}
                                    >Actualizar usuario</button>
                                </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default EditCliente
