import React, {useState, useContext, useEffect, useRef} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// import Auth from '../auth/Auth';
import { useHistory } from "react-router-dom";

import {
  NavLink
} from "react-router-dom";
/*Globales*/
import {urlApi} from '../../global/Global'
import LoginContext from '../../global/login/loginContext';
//Google analytics
import ReactGA from 'react-ga';
const Header = ({setUser, setExist, user, exist, headers}) => {
     //yields: "/js" (where snippets run)
    /*mostrar login */
    const [unblock, setUnblock] = useState(false);
    const [easter, seteaster] = useState(false);
    const [claseAnimation, setclaseAnimation] = useState('d-none');
    let arr_txt = '';
    const viewLogin = process.env.NODE_ENV !== 'production' ? true : false;
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    let history = useHistory();
    /*CONTEXT DE USUARIO */
    const { eliminarUser } = useContext(LoginContext);
    let easterEggHidden = false;
    let easterEgg  = 0;
    let easterEgg2 = 0;
    let easterEgg3 = 0;
    const refEaster = useRef();
    const refNav    = useRef();
    const refCont   = useRef();
    const refAbout  = useRef();

    useEffect(() => {
      const x = history.listen(location => ReactGA.pageview(location.pathname));
      console.log(x);
      console.log(refEaster.current);
      document.body.addEventListener('keydown', (e) =>{
        console.log(typeof e.key);
        if(e.key !== undefined){
          arr_txt += e.key.toLocaleLowerCase();
        }
          if (arr_txt === 'unblocklogin') {
              setUnblock(true);
          }
          else if (arr_txt === 'createdby') {
              seteaster(true);
              refEaster.current.addEventListener('animationend', () => {
                setclaseAnimation('animate__animated animate__bounceOut animate__delay-1s 1s');
                seteaster(false);
              });
          }
          
      });

      const easterEggFunction = (params) => {
        if (easterEgg >= 5 && easterEgg2 >= 5 && easterEgg3 >= 5 && easterEggHidden === false) {
          seteaster(true);
          easterEggHidden=true;
          refEaster.current.addEventListener('animationend', () => {
            setclaseAnimation('animate__animated animate__bounceOut animate__delay-1s 1s');
            seteaster(false);
          });
        }
      }
      

      refNav.current.addEventListener('click', (e) =>{
        easterEgg++;
        console.log(easterEgg);
        easterEggFunction();
      });
      refCont.current.addEventListener('click', (e) =>{
        easterEgg2++;
        console.log(easterEgg2);
        easterEggFunction();

      });
      refAbout.current.addEventListener('click', (e) =>{
        easterEgg3++;
        console.log(easterEgg3);
        easterEggFunction();

      });

      

      
        
    }, [])

    const logout = async (e) => {
        e.preventDefault();
        // console.log(user);
        let headers = '';
        try {
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          if(log_json !==null){
            headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          }
          
          
        } catch (error) {
          console.log(error);
        }
       
    
        MySwal.fire({
          title: '¿Cerrar sesión?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si',
          cancelButtonText: 'No'
        }).then(async (result) => {
          if (result.isConfirmed) {
                await axios.get(urlApi+'auth/logout', {headers: headers})
                        .then(async (response) =>  { 
                          // await AsyncStorage.removeItem(
                          //   '@login'
                          //   );
                          // setUser('');
                          // setExist(false);
                          // eliminarUser('');
                          // history.push('/payments')
                          console.log('correcto');
                        })
                        .catch(error => {
                          console.log(error);
                        })
                        .then(async () =>{
                          await AsyncStorage.removeItem(
                            '@login'
                            );
                          setUser('');
                          setExist(false);
                          eliminarUser('');
                          history.push('/payments')
                        });
            }//Result
          });//then
                        
    }

    return (
      <>
        <p 
          ref={refEaster} 
          id="easter" 
          className={!easter ? claseAnimation : `animate__animated  animate__bounceIn`}>
            Created by Keven Maria k3
        </p>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark sticky-top">
              <div className="container">
                <NavLink className="navbar-brand" to="/payments"  ref={refNav}>Label Dictate Technologies LLC</NavLink>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded='false' aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse`} id="navbarNav">
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                      <NavLink className="nav-link d-none" exact to="/licencias/registrar">Registrar licencia</NavLink>
                    </li>
                  { (exist && user.role===1) && 
                    <>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/users">Usuarios</NavLink>
                      </li>
                    </>
                  }

                  { (exist && (user.role===1 || user.role===2)) &&
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/licencias">Licencias</NavLink>
                      </li>
                  }
                  
                  { (exist && user.role===1) && 
                    <>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/softwares">Softwares</NavLink>
                      </li>
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          Ftp
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuLink">
                          <li>
                            <NavLink className="dropdown-item" to="/ftp" exact={true}>Listado FTPs</NavLink>
                          </li>
                          <li>
                            <NavLink className="dropdown-item" to="/ftp/dashboard">Dashboard</NavLink>
                          </li>
                        </ul>
                      </li>
                      
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/clientes">Clientes</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/pagos">Pagos</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/registrados">Registrados(MAC)</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/reportes">Reportes</NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/descuentos">Descuentos</NavLink>
                      </li>
                    </>
                  }
                  </ul>
                    <ul className="navbar-nav ml-auto">
                      {
                        !exist ?  
                          <>
                            <li className='nav-item'>
                              <a className="nav-link" href="#compras" >Buy</a>
                            </li>
                            <li className='nav-item'>
                              <a className="nav-link" href="#nosotros" ref={refAbout}>About us</a>
                            </li>
                            <li className='nav-item'>
                              <a className="nav-link" href="#contacto" ref={refCont}>Contact</a>
                            </li>
                            <li className={unblock ? `nav-item animate_animated animate__bounceIn` : `nav-item d-none`}>
                              <NavLink className="nav-link" to="/login" >Login</NavLink>
                            </li>
                          </>  
                            :
                          <> 
                            <li className="nav-item">
                              <a href="/#" className="nav-link" 
                                onClick={(e) => logout(e)}
                                >
                                Logout</a>
                            </li>
                          </> 
                      }
                    </ul>
              </div>
            </div> 
          </nav>
          </>
    )
}

export default Header;