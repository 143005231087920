import React, {useState, useEffect} from 'react'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {urlApi} from '../../global/Global'
/*Charts*/
import  VerticalBarExp from './charts/VerticalBarExp'
import  HorizontalBarChartFechas from './charts/HorizontalBarChartFechas'

const DashboardFtp = () => {


    const [countExp, setcountExp] = useState({});
    const [fechas, setfechas] = useState([]);

    useEffect(() => {


        const getDashboard = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_ftp = await axios.get(urlApi+'ftp/dashboard', {headers: headers});
                setcountExp(get_ftp.data.filtros);
                setfechas(get_ftp.data.fechas);
                console.log(get_ftp.data);
            } catch (error) {
                console.log(error);
            }
        }

        getDashboard();
    }, [])


    return (
        <div className="my-5 container">
            <div className="border rounded-3">
                <div className="p-3 table-responsive">
                    <div className="row">
                        <h1 className="fw-bold text-uppercase col-md-9">Dashboard FTP</h1> 
                        <div className="col-md-3">
                            {/* <NavLink className="btn btn-dark w-100" exact to="/ftp/create">
                                <FontAwesomeIcon icon={faPlusCircle}/>
                                <span className="fw-bold text-uppercase"> Registrar FTP</span>
                            </NavLink> */}
                        </div>
                    </div>
                    <hr/>

                    <div className="container">

                        <div className="row">
                            <div className="col-md-6">
                                <VerticalBarExp
                                    countExp={countExp}
                                />
                            </div>
                            <div className="col-md-6">
                                <HorizontalBarChartFechas
                                    fechas={fechas}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default DashboardFtp
