import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";

const Table = ({ftp}) => {

    return (
            <div className="d-flex justify-content-around w-100">     
                <Link className="btn btn-success"
                    to={
                        {  
                            pathname:"/ftp/edit",
                            state:{ftp: ftp}
                        }
                    }

                >
                        <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
                </Link>
                {/* <button className="btn btn-danger">
                        <FontAwesomeIcon icon={faTrash} />  <span className="fw-bold"></span>     
                </button> */}
            </div>
    )
}

export default Table