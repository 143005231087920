import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

/*Vista tabla*/
import Table from './Table';

import {
    NavLink
} from "react-router-dom";
/*Globales*/
import {urlApi} from '../../global/Global'
import Loading from '../../styles/Loading'
 //Filtrar texto
 const FilterComponent = ({ filterText, onFilter, onClear }) => (
    <div className="input-group col-md-12 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>X</button>
    </div> 
);
const Users = () => {

   

    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0); //Total de registros del FTP
    const [perPage, setPerPage] = useState(10); //cuantos pot pagina
    const [carga, setCarga] = useState(false);

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = users.filter(item => (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.email && item.email.toLowerCase().includes(filterText.toLowerCase()))
                                                 );
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);
    //DATATABLE
    const columns = [
        {
            name: 'Nombre',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Correo',
            selector: 'email',
            sortable: true,
        },
        {
            name: 'Rol',
            sortable: true,
            cell: row =>  {
                const arr = [];
                for (let i = 0; i < row.roles.length; i++) {
                        arr.push(row.roles[i].name+'- ')
                }
                return (
                    <div>{arr}</div>
                )
                
            }
        },
        {
            name: 'Opciones',
            // width: '30%',
            cell: row => <Table 
                            user={row}
                            totalRows={totalRows}
                            setTotalRows={setTotalRows}
                            users={users}
                            setUsers={setUsers}
                        />,
        },
    ];

    //Componente de cabecera
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    //Primer acción
    useEffect(() => {
        const getUsers = async (page) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }

            await axios.post(urlApi+'users/getusers', {page:page, perPage:perPage}, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setUsers(response.data.user);
                    setTotalRows(response.data.user_count);
                    setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error.data);
                });                    
        }
        getUsers(1);
    }, [])

     //Cambio de pagina
     const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'users/getusers', {page:page, perPage:perPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setUsers(response.data.user);
                // setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            })
            .then(() =>{
                setCarga(false);
            })
    };

    //Cambio de rows
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'users/getusers', {page:page, perPage:newPerPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setUsers(response.data.user);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
      };

    return (
        <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="row">
                            <h1 className="fw-bold text-uppercase col-md-9">Usuarios</h1> 
                            <div className="col-md-3 text-align-res">
                                <NavLink className="btn btn-dark w-100" exact to="/users/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Registrar usuario</span>
                                </NavLink>
                            </div>
                        </div> 
                        <hr/>
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            progressPending={loading}
                            progressComponent={<Loading />}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // selectableRows
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            theme='solarized'
                            customStyles={customStyles}
                            striped
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            persistTableHead
                        />
                        {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
    )
}

export default Users
