import { AGREGAR_USER, ELIMINAR_USER } from '../types'

// eslint-disable-next-line
export default (state, action) =>{
    switch(action.type){
        case AGREGAR_USER:
            const headers = {
                'Authorization': action.payload.token_type+' '+action.payload.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            return {
                    user: action.payload,
                    exist: true,
                    headers: headers
                }

        case ELIMINAR_USER:
            return {
                user: '',
                exist: false,
                headers: ''
            }

        default:
            return state;
    }
}