import React, {Fragment, useEffect, useState} from 'react'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import Checkbox from '../layouts/Checkbox'
/*Globales*/
import {urlApi} from '../../global/Global'

const EditLicencia = (props) => {

    //Sweet alert
    const MySwal = withReactContent(Swal);
    
    const lic                       = props.location.state.licencia;
    const [carga, setCarga]         = useState(false);
    const [estados, setEstados]     = useState([]);
    const [softwares, setSoftwares] = useState([]);
    const [arrayLice, setArrayLice] = useState([])
    const webpay                    = ['NO', 'SI'];
    const [payment, setpayments]    = useState('');
    const [pay, setpay]             = useState(0);

    useEffect(() => {
        //Obtener licencias
        window.scrollTo(0,0);
        console.log(lic)
        const getLicencia = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            await axios.post(urlApi+'licencias/show', lic.id, {headers: headers})
                .then((response) => {
                    setEstados(response.data.estado);
                    if(response.data.payment != 0){
                        setpay(response.data.payment.software_id);
                    }
                    setpayments(response.data.payment);   
                    console.log(response.data.payment);                 
                    //Actualiza estados del formulario
                })
                .catch((error) => {
                    console.log(error.data);
                });
        }

        const getSoftwares = async () => {
            try {
                const get_softwares = await axios.get(urlApi+'softwares');
                setSoftwares(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }

        const arrayLic = () => {
            lic.softwares_without_trash.map(element =>(
                // console.log(element.id)
                setArrayLice(arrayLice =>[
                    ...arrayLice,
                    element.id
                ])
            ))
        }

        
        getLicencia();
        getSoftwares();
        arrayLic();
    }, [])

    //funcion solo para numeros
    const onlyNumbers = (params) => {
        const re = /^\d*\.?\d*$/;
        if (!re.test(params)) {
            return false;
        }
    }
    
    
    //Funcion de alertas
    const sweetAlert = (title, html, allowOutsideClick = true, showloading = false, icon='') => {
        MySwal.fire({
           title: title,
           icon: icon,
           html: html,
           allowOutsideClick: allowOutsideClick,
           didOpen: () => {
               if(showloading){
                   MySwal.showLoading();
               }
           }
       })
   }


    //Envia los valores a la API/BACKEND
    const sendValues = async (params) => {
        console.log(params);
        if(params.webpay == 'SI' && params.estado == 1){
            sweetAlert('Licencia con compra en linea', 'Usted esta colocando una licencia con compra en estado disponible, lo correcto es ponerlo en estado registrado', true, false, 'warning');                        
            return false;
        }

        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        if(pay != 0){
            params['cliente_payment'] = payment;
        }

        const send_array ={
            id: lic.id,
            licencia: params,
            cliente: lic.clientes[0]
        }

        await axios.put(urlApi+'licencias/update', send_array, {headers: headers})
                .then((response) => {
                    if (response.data.respuesta==='correcto') {
                        setCarga(false);
                        sweetAlert('Correcto', 'Se realizo la acción correctamente', true, false, 'success');                        
                    }else{
                        setCarga(false);
                        sweetAlert('Error!', 'Ocurrio un error en la acción', true, false, 'error');
                    }
                    console.log(response.data);
                })
                .catch((error) => {
                    setCarga(false);
                    sweetAlert('Error!', 'Ocurrio un error en la acción', true, false, 'error');
                    console.log(error.data);
                });
    }
    

    //Validar datos de formulario
    const formSchema = Yup.object().shape({
        estado: Yup.string()
            .required('Campo requerido'),
        payment: Yup.string()
            .required('Campo requerido'),
        webpay: Yup.string()
            .required('Campo requerido'),
        software: Yup.array()
            .required('Campo requerido'),
        vigencia: Yup.string()
            .required('Campo requerido'),
        activaciones: Yup.number()
            .positive('Debe ser mayor a 0')
            .integer('El campo debe ser un número entero')
            .typeError('El campo debe ser un numero y mayor a 0')
            .min(0, 'Valor minimo 0.')
            .max(3, 'Valor Máximo 3.')
            .required('Campo requerido')
    });


    return (
        <div className="container my-5">
            <h2 className="text-uppercase fw-bold">Editar licencia</h2>
            <Formik
                enableReinitialize
                initialValues={{
                    estado: lic.estado_id,
                    software: arrayLice,
                    webpay: lic.webpay_activation,
                    vigencia: lic.vigencia,
                    activaciones: lic.activaciones,
                    payment: pay
                }}
                validationSchema={formSchema}
                onSubmit={(values) => sendValues(values)}
            >
                <Form>
                    <div className="row">
                        <div className="card m-auto mt-5 w-50-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title text-uppercase">Licencia</h5>
                                    <h5 className="card-title text-uppercase fw-bold">#{lic.id}</h5>
                                </div>
                                <h6 className="card-title text-muted">Lote: {lic.lote}</h6>
                                <h6 className="card-subtitle mb-2 fw-bold">No. activación: {lic.codigo_activacion}</h6>
                                <div className="row mb-3">
                                </div>
                                <p className="card-text text-muted">
                                    <span className="text-danger text-uppercase fw-bold">¡Advertencia! </span>
                                    <ul className="mt-2">
                                        <li>
                                            <p>Si la licencia fue comprada via online (paypal, stripe, etc) no necesitas colocar la licencia en disponible, unicamente debes cambiarla a REGISTRADO</p>
                                        </li>
                                        <li>
                                            <p>Si la licencia NO fue comprada y fue adquirida junto con la impresora la licencia debera ser modificada a DISPONIBLE</p>
                                        </li>
                                    </ul>
                                    <p>Modifique las licencias con cuidado, puede tener resultados inesperados</p>
                                </p>
                                {/* Datos de usuario */}
                                <div className="mb-3">
                                    <label htmlFor="estado" className="form-label">Estado</label>
                                    <Field as="select" className="form-select" aria-label="Default select example" name="estado" id="estado">
                                        {
                                            estados.map((element, index) =>{
                                                return <option value={element.id} key={index}>{element.name}</option>
                                            })
                                        }

                                    </Field>
                                    <ErrorMessage
                                        name='estado'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                <label htmlFor="webpay" className="form-label">¿Pago web?</label>
                                <Field as="select" className="form-select" aria-label="Default select example" name="webpay" id="webpay">
                                    {
                                        webpay.map((element, index) => {
                                                return <option value={element} key={index}>{element}</option>
                                        })
                                    }
                                </Field>
                                    <ErrorMessage
                                        name='webpay'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="vigencia" className="form-label fw-bold text-uppercase">Fecha de vigencia</label>
                                    <div className="col-12">
                                        <Field className="form-control" type="date" id="vigencia" name="vigencia"/>
                                    </div>
                                    <ErrorMessage
                                        name='vigencia'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="activaciones" className="form-label">Activaciones</label>
                                    <Field className="form-control" name="activaciones" id="activaciones"/>
                                    <ErrorMessage
                                        name='activaciones'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                {
                                    pay != 0 ?
                                    <div className="mb-3">
                                        <label htmlFor="payment" className="form-label fw-bold">Software adquirido al momento de la compra (Migrar compra)</label>
                                        <Field as="select" className="form-select" aria-label="Default select example" name="payment" id="payment">
                                            {
                                                softwares.map((element, index) =>{
                                                    return <option value={element.id} key={index}>{element.name} - ID: {element.id}</option>
                                                })
                                            }

                                        </Field>
                                        <ErrorMessage
                                            name='payment'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                    :
                                    <Field as='hidden' name="payment" id="payment"/>

                                }

                                <div className="col-md-12">
                                    <label htmlFor="software" className="form-label fw-bold text-uppercase">Software</label>
                                    {
                                        softwares.map((value, index) =>{
                                           return lic.softwares_without_trash.includes(index) ?
                                           <div className="form-check" key={index}>
                                                <Checkbox checked="true" className="form-check-input" value={value.id} id={'sw-'+index} name="software"/>
                                                <label className="form-check-label" htmlFor={'sw-'+index}>
                                                    {value.name}
                                                </label>
                                            </div>
                                            :
                                           <div className="form-check" key={index}>
                                                <Checkbox className="form-check-input" value={value.id} id={'sw-'+index} name="software"/>
                                                <label className="form-check-label" htmlFor={'sw-'+index}>
                                                    {value.name}
                                                </label>
                                            </div>
                                         })

                                        
                                    }
                                    <ErrorMessage
                                        name='software'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>
                                {/* FIN Datos de usuario */}
                                <hr/>
                                <div className="d-flex justify-content-between">
                                    <p className="h2 fw-bold text-uppercase"></p>
                                </div>
                                {
                                    lic.clientes.length > 0 &&
                                    <>
                                        <div className="w-100 text-dark rounded bg-gray p-2 mb-2">
                                            <h5 className="text-uppercase fw-bold m-0">Propietario</h5>
                                            <hr className="my-1"/>
                                            <p><strong>Nombre: </strong>{lic.clientes[0].nombre}</p>
                                            <p><strong>Correo: </strong>{lic.clientes[0].correo}</p>
                                            <p><strong>Empresa: </strong>{lic.clientes[0].empresa}</p>
                                            <p><strong>Telefono: </strong>{lic.clientes[0].telefono}</p>
                                            <p><strong>Pais: </strong>{lic.clientes[0].pais}</p>
                                        </div>
                                        <hr/>
                                    </>

                                }
                                
                                {
                                    lic.registro!=null &&
                                    <div className="w-100 text-dark rounded bg-gray p-2">
                                        <h5 className="text-uppercase fw-bold m-0">Registro activado</h5>
                                        <hr className="my-1"/>
                                        <p><strong>Mac address: </strong>{lic.registro.mac_address}</p>
                                        <p><strong>Version: </strong>{lic.registro.version}</p>
                                        {
                                            softwares.map((element, index) => (
                                                element.id == lic.registro.software_id &&
                                                    <p key={index}><strong>Software activado: </strong>{element.name}</p>
                                                
                                            ))
                                        }
                                    </div>
                                }

                                {
                                    (lic.activacions != undefined && lic.activacions.length > 0) &&
                                    <div className='overflow-auto mt-3  rounded' >
                                        <ol className="list-group list-group-numbered" style={{height: '200px'}}>
                                            {
                                                lic.activacions.map((e,i) =>{
                                                    return <li key={'activaciones-'+i} className="list-group-item list-group-item-secondary">
                                                                <div>
                                                                <span>{i+1}.- </span>
                                                                    <span className='fw-bold'>Software: </span>
                                                                    {
                                                                        (e.software_id != null)  
                                                                        ? e?.software?.name
                                                                        : 'Sin registro'
                                                                    }

                                                                </div>
                                                                <div>
                                                                    <span className='fw-bold'>{e.accion}: </span>
                                                                    {                                                                    
                                                                        <span>{e.created_at.substring(0,10)} /// (Año/Mes/Dia)</span>
                                                                    }

                                                                </div>
                                                            </li>    
                                                })
                                            }
                                        </ol>
                                    </div>
                                }
                                <div className="my-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                    >Actualizar licencia</button>
                                </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default EditLicencia
