import React, {useEffect, useState, useRef} from 'react'
const Contacto = ({main}) => {
    
    const [offset, setOffset] = useState(0);
    const [style, setStyle] = useState(false);
    const [styleContacto, setStyleContacto] = useState(false);

    const refEncuentra = useRef();
    const refContacto = useRef();

    useEffect(() => {
        
        const { offsetTop, clientHeight } = refEncuentra.current;
        const ostContact = refContacto.current.offsetTop;
        const cliContact = refContacto.current.clientHeight;
        window.onscroll = () => {
            setOffset(window.scrollY)
            if (window.scrollY >= offsetTop - clientHeight) {
                setStyle(true);
            }else{
                setStyle(false);
            }
            // console.log(offsetTop +' * '+ clientHeight)
            
            /*CONTACTO*/
            if (window.scrollY >= ostContact - cliContact) {
                // console.log('b')
                setStyleContacto(true);
            }else{
                setStyleContacto(false);
            }
           
        }

        
        
    }, [offset]);
    // console.log(offset); 

    return (
        <>   
            <div className="banner-encuentra" ref={refEncuentra}  id="nosotros">
                <div className={style ? "container animate__animated animate__fadeInDown" : "container animate__animated animate__fadeOutUp"} >
                    <h2 className="fw-bold text-capitalize text-white mb-3 mt-5 text-center">Dear Label Dictate User</h2>
                    <p className="text-white h4">
It's important for you to know that each activation code for our applications is designed to be used up to a maximum of three times on a single device. Once all activations are used up, the code will be disabled, which will necessitate purchasing a new license to continue enjoying our applications.
If you need to use our solutions on more than one device, we suggest acquiring an additional license. This procedure helps us maintain the integrity of our solutions and ensures that your user experience is safe and of high quality.
We appreciate your understanding.</p>
                    {/* <p className="text-white h4">Somos una empresa de desarrollo de software y soluciones especializados en la impresión y generación de códigos de barras. Con la misión de generar productos fáciles de usar que ayuden a nuestros clientes a utilizar más las tecnologías de automatización de captura de datos sin complicaciones.</p> */}
                </div>
            </div>
            
            <div className="banner-2"  id="contacto">
                <div className={styleContacto ? "container text-center animate__animated animate__fadeIn animate__delay-2s 2s" : "container text-center ranimate__animated animate__fadeOut"}>
                    <h2  ref={refContacto} className="text-uppercase text-white">Any questions? Contact us at
                    {/* <h2  ref={refContacto} className="text-uppercase text-white">¿Tienes dudas? Contactanos a  */}
                        <p className="text-lowercase"> 
                            <strong className="h-r fw-bold">contact@labeldictate.com</strong>
                        </p>
                    </h2>
                </div>
            </div>
        </>
    )
}

export default Contacto
