import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

// /*Vista tabla*/
import Table from './Table';
/*Globales*/
import {urlApi} from '../../global/Global';
import Loading from '../../styles/Loading';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';          
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'        
 //Filtrar texto
 const FilterComponent = ({ filterText, onFilter, onClear, cargaBtn }) => (
    <div className="input-group col-md-6 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        
        {
            !cargaBtn ?
                <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>
                    X
                </button>
            :
            <button className="btn btn-danger" type="button" id="button-addon1" disabled>
                    X
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                <span className="visually-hidden">Loading...</span>
            </button>
        }
        
        
    </div> 
);

const Pagos = () => {
    const MySwal = withReactContent(Swal);
    const [limpio, setlimpio] = useState(false)
    const [cargaBtn, setCargaBtn] = useState(false);
    const [payments, setPayments]   = useState([]);
    const [perPayments, setPerPayments]   = useState([]);
    const [loading, setLoading]     = useState(false);
    const [totalRows, setTotalRows] = useState(0);      //Total de registros del FTP
    const [perPage, setPerPage]     = useState(10);     //cuantos pot pagina
    const [carga, setCarga]         = useState(false);

    /*Filtros*/
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    // const filteredItems = payments.filter(item => (item.payment_id && item.payment_id.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.payer_email && item.payer_email.toLowerCase().includes(filterText.toLowerCase()))
    //                                              || (item.clientes[0].correo && item.clientes[0].correo.toLowerCase().includes(filterText.toLowerCase()))
    //                                              );
    //Paramas de string a enviar
    let string = '';
    /*Thema principal datatable*/   
    createTheme('solarized', mainTheme);
    const columns = [
        {
            name: 'Pay-ID',
            selector: 'payment_id',
            sortable: true,
        },
        {
            name: 'Cliente',
            // selector: 'cl',
            sortable: true,
            cell: row => {
                    if(typeof row.clientes[0] !== 'undefined'){
                        return <div>{row.clientes[0].correo}</div>
                    }else{
                        return <div>---</div>
                    }
                    
            }
        },
        {
            name: 'Email de pago',
            selector: 'payer_email',
            sortable: true,
        },
        {
            name: '$ Costo $',
            selector: 'amount',
            sortable: true,
        },
        {
            name: 'Fecha de pago',
            // selector: 'created_at',
            sortable: true,
            cell: row => {
                const fecha = row.created_at.split('T');
                return ( 
                    <div className="d-flex flex-column w-100">
                        <p className="p-0 my-1 d-inline w-100">Fecha: {fecha[0]} </p>
                        <p className="p-0 my-1 d-inline w-100">Hora: {fecha[1].substr(0,8)}</p>
                    </div>
                )
            }
        },
        {
            name: 'Opciones',
            // width: '30%',
            cell: row => (
                    <>
                        <Table 
                            payment={row}
                            cliente={row.clientes[0]}
                        />
                        
                    </>
                
            ),
        },
      ];

//Componente de cabecera***************************************************************************
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setPayments(perPayments);
                string = '';
            }
        };
    
        return <FilterComponent 
                    onFilter={e => handleChangeFilter(e.target.value)} 
                    onClear={handleClear} 
                    filterText={filterText} 
                    cargaBtn={cargaBtn}
                />;
    }, [filterText, resetPaginationToggle, cargaBtn]);

//Primer acción***************************************************************************************
    useEffect(() => {
        
        const getSoftwares = async (page) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }

            await axios.post(urlApi+'payments/getpayments', {page:page, perPage:perPage}, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setPayments(response.data.payment);
                    setPerPayments(response.data.payment);
                    setTotalRows(response.data.pay_count);
                    setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error.data);
                });                    
        }
        getSoftwares(1);
    }, [])

//Cambio con filtro***********************************************************************************
    const handleChangeFilter = async (e) => {
        // console.log(e.target.checked);
        setCargaBtn(true);
        setFilterText(e);
        string += e;
        console.log(string);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'payments/getpaymentsfilter', {page:1, perPage:10, string:string}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario

                setPayments(response.data.payment);
                setTotalRows(response.data.pay_count);
                setLoading(false);
                // setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
                setCargaBtn(false);
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                setCargaBtn(false);
            });
    }
    
//Cambio de pagina***********************************************************************************
    const handlePageChange = async page => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'payments/getpayments', {page:page, perPage:perPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setPayments(response.data.payment);
                // setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            })
            .then(() =>{
                setCarga(false);
            })
    };

//Cambio de rows**************************************************************************************
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'payments/getpayments', {page:page, perPage:newPerPage}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                setPayments(response.data.payment);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
      };
/**LIMPIAR USUARIOS TEMPORALES */
const cleanTempUsers = async (params) => {
        setlimpio(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.get(urlApi+'payments/clean', {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                console.log(response.data);
                if (response.data.respuesta === 'correcto') {
                    MySwal.fire({
                        icon: 'success',
                        title: 'Limpiado',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      });
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'Vuelva a intentarlo',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      });
                }
            })
            .catch((error) => {
                console.log(error);
                MySwal.fire({
                    icon: 'error',
                    title: 'Vuelva a intentarlo',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  });
            })
            .then(()=>{
                setlimpio(false);
            })
      
}


    return (
        <div className="my-5 container">
            <div className="border rounded-3">
                <div className="p-3 table-responsive">
                    <div className="d-flex justify-content-between align-items-center">
                        <h1 className="fw-bold text-uppercase">Pagos</h1>
                        <div className="col-md-3">
                            <button className="btn btn-dark w-100 mb-3" type="button" disabled={limpio} onClick={() => cleanTempUsers()}>
                                {
                                    !limpio ?
                                        <FontAwesomeIcon icon={faBroom}/>
                                    :
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                }
                                
                                
                                <span className="fw-bold text-uppercase"> Limpiar temporales</span>
                            </button>
                        </div> 
                    </div> 
                    <hr/>
                    <DataTable
                        // title="Licencias"
                        columns={columns}
                        data={payments}
                        progressPending={loading}
                        progressComponent={<Loading />}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        // selectableRows
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        theme='solarized'
                        customStyles={customStyles}
                        striped
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        persistTableHead
                    />
                    {
                        carga &&
                        <div className="w-100 text-center mt-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Pagos
