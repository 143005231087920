import React, {useEffect, useState} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
const EditFtp = (props) => {

    const ftp = props.location.state.ftp;
    const [softwares, setSoftwares] = useState([]);
    const [carga, setCarga] = useState(false);

    /*Sweet alert*/
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        const getSoftwares = async (params) => {
            try {
                const get_softwares = await axios.get(urlApi+'softwares');
                setSoftwares(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }
        getSoftwares();
    }, [])


    const sendFtp = async (params) => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const send_data = {
            ftp: params,
            id: ftp.id
        }
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.put(urlApi+'ftp/update', send_data, {headers: headers})
            .then((response) => {
                setCarga(false);
                //Actualiza estados del formulario
                if(response.data.respuesta ==='correcto'){
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                }
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error.data);
            });
    }
    
    //YUP
    const formSchema = Yup.object().shape({
        cliente: Yup.string()
            .required('Campo requerido'),
        codigoactivacion: Yup.string()
            .required('Campo requerido'),
        codigoregistro: Yup.string()
            .required('Campo requerido'),
        contacto: Yup.string()
            .required('Campo requerido'),
        correo: Yup.string()
            .required('Campo requerido')
            .email('Necesita ser correo'),
        ftpuser: Yup.string()
            .required('Campo requerido'),
        ftppass: Yup.string()
            .required('Campo requerido'),
        tipolicencia: Yup.string()
            .required('Campo requerido'),
        version: Yup.string()
            .required('Campo requerido'),
        observaciones: Yup.string()
            .required('Campo requerido'),
        fecha: Yup.string()
            .required('Campo requerido'),
        fechaexpiracion: Yup.string()
            .required('Campo requerido'),
        software: Yup.number()
            .required('Campo requerido'),
    });

    return (
        <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Editar datos de FTP</h2>
            <Formik
                initialValues={{
                    cliente: ftp.cliente,
                    codigoactivacion: ftp.codigo_activacion,
                    codigoregistro: ftp.codigo_registro,
                    contacto: ftp.contacto,
                    correo: ftp.correo,
                    ftpuser: ftp.ftp_user,
                    ftppass: ftp.ftp_pass,
                    tipolicencia: ftp.tipo_licencia,
                    version: ftp.version,
                    observaciones: ftp.observaciones,
                    fecha: ftp.fecha,
                    fechaexpiracion: ftp.fecha_expiracion,
                    software: ftp.software_id

                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendFtp(values, actions)}
            >
                <Form>
                    <div className="row">
                        <div className="card m-auto mt-5">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title text-uppercase">Editar FTP</h5>
                                </div>                                {/* Datos de usuario */}
                                <div className="row g-3">
                                    <div className="col-md-3">
                                        <label htmlFor="cliente" className="form-label fw-bold text-uppercase">Cliente</label>
                                        <Field className="form-control select-custom" name="cliente"/>
                                        <ErrorMessage
                                            name='cliente'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="codigoactivacion" className="form-label fw-bold text-uppercase">codigo de activacion</label>
                                        <Field className="form-control select-custom" name="codigoactivacion"/>
                                        <ErrorMessage
                                            name='codigoactivacion'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="codigoregistro" className="form-label fw-bold text-uppercase">codigo de registro</label>
                                        <Field className="form-control select-custom" name="codigoregistro"/>
                                        <ErrorMessage
                                            name='codigoregistro'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="contacto" className="form-label fw-bold text-uppercase">Contacto</label>
                                        <Field className="form-control select-custom" name="contacto"/>
                                        <ErrorMessage
                                            name='contacto'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="correo" className="form-label fw-bold text-uppercase">Correo</label>
                                        <Field type="email" className="form-control select-custom" name="correo"/>
                                        <ErrorMessage
                                            name='correo'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="fecha" className="form-label fw-bold text-uppercase">Fecha activacion</label>
                                        <div className="col-12">
                                            <Field className="form-control" type="date" id="fecha" name="fecha"/>
                                        </div>
                                        <ErrorMessage
                                            name='fecha'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="fechaexpiracion" className="form-label fw-bold text-uppercase">Fecha expiracion</label>
                                        <div className="col-12">
                                            <Field className="form-control" type="date" id="fechaexpiracion" name="fechaexpiracion"/>
                                        </div>
                                        <ErrorMessage
                                            name='fechaexpiracion'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="ftpuser" className="form-label fw-bold text-uppercase">Usuario de FTP</label>
                                        <Field type="email" className="form-control select-custom" name="ftpuser"/>
                                        <ErrorMessage
                                            name='ftpuser'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="ftppass" className="form-label fw-bold text-uppercase">Contraseña de FTP</label>
                                        <Field type="text" className="form-control select-custom" name="ftppass"/>
                                        <ErrorMessage
                                            name='ftppass'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="tipolicencia" className="form-label fw-bold text-uppercase">Tipo de licencia</label>
                                        <Field type="text" className="form-control select-custom" name="tipolicencia"/>
                                        <ErrorMessage
                                            name='tipolicencia'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="version" className="form-label fw-bold text-uppercase">Versión</label>
                                        <Field type="text" className="form-control select-custom" name="version"/>
                                        <ErrorMessage
                                            name='version'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="software" className="form-label fw-bold text-uppercase">Software</label>
                                        <Field as="select" className="form-control select-custom" name="software">
                                            {
                                                softwares.map((element,index) => {
                                                    return  <option
                                                                value={element.id} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='software'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>
               
                                <div className="row mt-3">
                                    <div className="mb-3">
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold text-uppercase">Obervaciones</label>
                                        <Field name="observaciones" as="textarea" className="form-control" id="exampleFormControlTextarea1" rows="3"></Field>
                                    </div>
                                </div>

                                {/* FIN Datos de usuario */}
                                <hr/>

                                <div className="my-4 col-md-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                            // onClick={sendDataPaypal}
                                    >Actualizar registro</button>
                                </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default EditFtp
