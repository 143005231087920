import React, {useEffect, useState} from 'react'

const Mantenimiento = ({mantenimiento, fechaMan}) => {

    const [fecha, setFecha] = useState(fechaMan);

    useEffect(() => {
            const mantenimientoFunction = (params) => {
                
                let end = new Date(fechaMan); 
                // let end = new Date();
                // let minutes_mod = end.getMinutes();
                // end.setMinutes(minutes_mod + 10); 
        
                let _second = 1000;
                let _minute = _second * 60;
                let _hour   = _minute * 60;
                let _day    = _hour * 24;
                let timer;
            
                function showRemaining() {
                    let now      = new Date();
                    let distance = end - now;
                    if (distance < 0) {
            
                        clearInterval(timer);
                        document.getElementById('countdown').innerHTML = 'seguida!';
            
                        return;
                    }
                    let days    = Math.floor(distance / _day);
                    let hours   = ("0"+Math.floor((distance % _day) / _hour)).slice(-2);
                    let minutes = ("0"+Math.floor((distance % _hour) / _minute)).slice(-2);
                    let seconds = ("0"+Math.floor((distance % _minute) / _second)).slice(-2);
            
                    // document.getElementById('countdown').innerHTML = days + ' dias, ';
                    document.getElementById('countdown').innerHTML = hours + ':';
                    document.getElementById('countdown').innerHTML += minutes + ':';
                    document.getElementById('countdown').innerHTML += seconds + ' ';
                }
            
                timer = setInterval(showRemaining, 1000);
            }

            if(mantenimiento){
                mantenimientoFunction();
            }
            
    }, [])

    return (
        
            // mantenimiento &&
            // <div className="container d-none">
            //     <div className="mantenimiento text-center">
            //         <span className="font-weight-bold text-center h5">
            //             <span className="text-danger text-uppercase fw-bold h4">Compras deshabilitadas</span> 
            //             <p>Selecciona la aplicación para obtener una licencia promocional</p>
                        
            //             <span id="countdown"></span>
            //         </span>
            //     </div>
            // </div>
            mantenimiento &&
            <div className="container">
                <div className="mantenimiento text-center">
                    <span class="font-weight-bold text-center h5"><span className="text-danger text-uppercase fw-bold h4">Disabled purchases.</span> The site is under maintenance  <span id="countdown"></span></span>
                </div>
            </div>
        
        
    )
}

export default Mantenimiento
