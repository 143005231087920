import React, {useEffect, useState} from 'react'
import getExcel from './getExcel'
import { Formik, Field, Form, ErrorMessage } from 'formik';

import * as Yup from 'yup';
const Ftp = ({Toast}) => {
    
    /************* FTP ******************/
    const [ftps, setftps] = useState([
        {id:1, name: 'Obtener activos'},
        {id:2, name: 'Obtener expirados'},
        {id:3, name: 'Obtener por expirar'},
        {id:4, name: 'Obtener todos'},
    ]);

    useEffect(() => {

       console.log(ftps);
    }, [])

    const handleClickFtp = async (params) => {
        console.log(params);
        const send_data ={
            params: params,
            req: 1
        }
        getExcel('reportes/ftpexportar', send_data, 'Ftps', Toast);
    }

     //Validador de datos
     const formSchema = Yup.object().shape({

        // desde: Yup.string()
        //     .required('Campo requerido'),
        // hasta: Yup.string()
        //     .required('Campo requerido'),
        ftp: Yup.number()
            .required('Campo requerido'),
    });
    
    return (
        <div className="card text-center m-1 mb-5 p-0 w-32-pc">
            <Formik
                enableReinitialize
                initialValues={{
                    // desde:'',
                    // hasta: '',
                    ftp:1
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => handleClickFtp(values, actions)}
            >
                <Form className="d-flex flex-column h-100">
                    <div className="card-header">
                        <strong className="text-uppercase">Reporte de FTP</strong>
                    </div>
                    <div className="card-body">
                        {/* <div className="col-md-12">
                            <label htmlFor="desde" className="form-label fw-bold text-uppercase">Desde:</label>
                            <div className="col-10">
                                <Field className="form-control" type="date" id="desde" name="desde"/>
                            </div>
                            <ErrorMessage
                                name='desde'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="hasta" className="form-label fw-bold text-uppercase">hasta:</label>
                            <div className="col-10">
                                <Field className="form-control" type="date" id="hasta" name="hasta"/>
                            </div>
                            <ErrorMessage
                                name='hasta'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div> */}
                        {/* <hr/> */}
                        <div className="col-md-12">
                            <label htmlFor="ftp" className="form-label fw-bold text-uppercase text-center">ftp</label>
                            <Field as="select" className="form-control select-custom fw-bold text-capitalize" name="ftp">
                                {
                                    ftps.map((element, index) =>{
                                        return  <option
                                                    value={element.id} 
                                                    key={index}
                                                    
                                                    >{element.name}
                                                </option>
                                    })
                                }
                            </Field>
                            <ErrorMessage
                                name='ftp'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        {/* <div className="col-md-12">
                            <label htmlFor="ftp" className="form-label fw-bold text-uppercase text-center">ftp</label>
                            {
                                ftps.map((value, index) =>{
                                    return <div className="form-check text-start" key={index}>
                                        <Checkbox className="form-check-input" value={value.id} id={index} name="ftp"/>
                                        <label className="form-check-label text-capitalize" htmlFor={index}>
                                            {value.name}
                                        </label>
                                    </div>
                                })
                            }
                            <ErrorMessage
                                name='ftp'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div> */}
                    </div>
                    <div className="card-footer text-muted">
                        <button type="submit" className="btn btn-dark text-uppercase fw-bold">Obtener reporte</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Ftp
