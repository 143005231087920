const customStyles = {
    contextMenu: {
        style: {
          borderRadius: '100px',
          color: 'red'
        },
      },
    rows: {
      style: {
        borderRight: '1px solid #373b3e', // override the row height
        '&:hover': {
          backgroundColor: '#535759',
        },
      },
      stripedStyle: {
        color: '#FFF',
        backgroundColor: '#191818',
      },
    },
    // header: {
    //   style: {
    //     backgroundColor: '#AAA',
    //     borderRadius: '10px 10px 0 0'
    //   },
    // },
    subHeader: {
      style: {
        borderRadius: '5px 5px 0 0'
      },
    },
    headCells: {
        style: {
            borderRight: '0.1px solid #373b3e', // override the row height
            borderTop: '0.1px solid #373b3e', // override the row height
        },
      
    },
    cells: {
        style: {
            borderRight: '0.1px solid #373b3e', // override the row height
        }
    },
  };

export default customStyles;