import React from 'react'
/*CHARTS*/
import { Pie } from '@reactchartjs/react-chart.js'

const Chart = ({licencias}) => {
    console.log(licencias);
    /*CHART */
    const data = {
        labels: ['Disponible-'+licencias[1], 'Desactivado-'+licencias[2], 'Registrado-'+licencias[3], 'Activado-'+licencias[4]],
        datasets: [
          {
            label: '# of Votes',
            data: [licencias[1], licencias[2], licencias[3], licencias[4]],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
            ],
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
            ],
            borderWidth: 1,
          },
        ],
      }

    return (
        <>
            <div className='header'>
                <h6 className='title text-uppercase fw-bold'>Cantidades de licencias</h6>
            </div>
            <Pie data={data} />
        </>
    )
}

export default Chart
