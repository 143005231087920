import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
/*Globales*/
import {urlApi} from '../../global/Global'
import {
    NavLink
} from "react-router-dom";
const Pendientes = () => {
    
    // const [carga, setCarga] = useState(false);
    //Pendientes
    const [pendientes, setPendientes] = useState([]);
    const [carga, setcarga] = useState(false)
    const getPendientes = async () => {
        try {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            const get_pendientes = await axios.get(urlApi+'licencias/getpendientes', {headers: headers});
            console.log(get_pendientes.data);
            setPendientes(get_pendientes.data);
            setcarga(true);
        } catch (error) {
            console.log(error);
        }
    }
    
    
    //primer funcion
    useEffect(() => {
        getPendientes();
    }, [])


    return (
        <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="row">
                            <h1 className="fw-bold text-uppercase col-md-9">Pendientes de envio</h1> 
                            <div className="col-md-3">
                                <NavLink className="btn btn-dark w-100 mb-3" exact to="/licencias/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Agregar licencias</span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 mb-3">
                                {/* <h4 className="fw-bold text-uppercase text-muted">Disponibles: (Pago online)</h4>  */}
                            </div>
                        </div>

                        <hr/>
                        {
                        !carga ?
                        <div className="w-100 text-center my-4">
                            <div className="spinner-border" role="status"  style={{width: "5rem", height: "5rem"}}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        pendientes.length<=0 ?
                            <h2 className="text-uppercase text-center py-5">No hay envios pendientes</h2>
                        :
                            <div className="row col-cel w-100">
                                <div className="col-li-1 mb-4">
                                    <div className="list-group overflow-auto height-300 border border-secondary rounded" id="list-tab" role="tablist">
                                        {
                                            pendientes.map((el, i) =>{
                                                return i === 0 ? 
                                                    <a key={i} className="list-group-item list-group-item-action active" id={"list-"+i+"-list"} data-bs-toggle="list" href={"#list-"+i} role="tab" aria-controls={i}>{el.correo}</a>
                                                :
                                                    <a key={i} className="list-group-item list-group-item-action" id={"list-"+i+"-list"} data-bs-toggle="list" href={"#list-"+i} role="tab" aria-controls={i}>{el.correo}</a>
                                                })
                                        }
                                    </div>
                                </div>
                                <div className="col-li-2 mb-4">
                                    <div className="tab-content border border-secondary rounded overflow-auto height-300" id="nav-tabContent">

                                        {
                                            pendientes.map((el, i) =>{
                                            return i === 0 ? 
                                                <div key={i} className="tab-pane fade show active" id={"list-"+i} role="tabpanel" aria-labelledby={"list-"+i+"-list"}>
                                                    <ul>
                                                        <li>
                                                            Cantidad de licencias pendiente: <strong>{el.cantidad}</strong>
                                                        </li>
                                                        <li>
                                                            PAY - ID: <strong>{el.payment_id}</strong>
                                                        </li>
                                                        <li>
                                                            Software: <strong>{el.name}</strong>
                                                        </li>
                                                        <li>
                                                            Correo de pago: <strong>{el.payer_email}</strong>
                                                        </li>
                                                        <li>
                                                            Pago: <strong>${el.amount}</strong>
                                                        </li>
                                                    </ul>
                                                </div>
                                            :
                                                <div key={i} className="tab-pane fade" id={"list-"+i+""} role="tabpanel" aria-labelledby={"list-"+i+"-list"}>
                                                    <ul>
                                                        <li>
                                                            Cantidad de licencias pendiente: <strong>{el.cantidad}</strong>
                                                        </li>
                                                        <li>
                                                            PAY - ID: <strong>{el.payment_id}</strong>
                                                        </li>
                                                        <li>
                                                            Software: <strong>{el.name}</strong>
                                                        </li>
                                                        <li>
                                                            Correo de pago: <strong>{el.payer_email}</strong>
                                                        </li>
                                                        <li>
                                                            Pago: <strong>${el.amount}</strong>
                                                        </li>
                                                    </ul>
                                                </div>                                            
                                            })
                                        }    
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
    )
}

export default Pendientes
