import React, {useState, useEffect} from 'react'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
import getExcel from './getExcel'
import { Formik, Field, Form, ErrorMessage } from 'formik';

/*Vistas */
import Checkbox from '../layouts/Checkbox'

import * as Yup from 'yup';
const Licencia = ({Toast}) => {

    /************* Licencias ******************/
    const [softwares, setSoftwares] = useState([]);
    const [estados, setEstados] = useState([]);


    useEffect(() => {
        

        const getSoftwares = async () => {
            await axios.get(urlApi+'softwares')
                    .then(response =>{
                        setSoftwares(response.data);
                        console.log(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
        }

        const getEstados = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }

            await axios.get(urlApi+'reportes/getestados', {headers: headers})
                    .then(response =>{
                        setEstados(response.data);
                        console.log(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
        }
            
        getSoftwares();
        getEstados();
    }, [])

    const sendLicencias = (params) => {
        // console.log(params);
        // return;
        const send_data ={
            params: params,
            req: 1
        }
        getExcel('reportes/licenciasexportar', send_data, 'Licencias', Toast);
        console.log(params)
    }
    

    //Validador de datos
    const formSchema = Yup.object().shape({

        software: Yup.array()
            .required('Campo requerido'),
        estado: Yup.array()
            .required('Campo requerido'),
        desde: Yup.string()
            .required('Campo requerido'),
        hasta: Yup.string()
            .required('Campo requerido'),
        lote: Yup.string()
    });

    return (
        <div className="card text-center m-1 mb-5 p-0 w-32-pc">
            <div className="card-header">
                <strong className="text-uppercase">Reporte de Licencias</strong>
                
            </div>
            <Formik
                enableReinitialize
                initialValues={{
                    software:[],
                    estado:[],
                    desde:'',
                    hasta: '',
                    lote: ''
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendLicencias(values, actions)}
            >
                <Form>
                    <div className="card-body">
                        <div className="col-md-12 text-start">
                            <label htmlFor="software" className="form-label fw-bold text-uppercase">Software</label>
                            {
                                softwares.map((value, index) =>{
                                return <div className="form-check" key={index}>
                                    <Checkbox className="form-check-input" value={value.id} id={index} name="software"/>
                                    <label className="form-check-label" htmlFor={index}>
                                        {value.name}
                                    </label>
                                </div>
                                })
                            }
                            <ErrorMessage
                                name='software'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <hr/>
                        <div className="col-md-12 text-start">
                            <label htmlFor="estado" className="form-label fw-bold text-uppercase">Estados</label>
                            {
                                estados.map((value, index) =>{
                                    return <div className="form-check" key={index+10}>
                                        <Checkbox className="form-check-input" value={value.id} id={index+10} name="estado"/>
                                        <label className="form-check-label text-capitalize" htmlFor={index+10}>
                                            {value.name}
                                        </label>
                                    </div>
                                })
                            }
                            <ErrorMessage
                                name='estado'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <hr/>
                        <div className="col-md-12">
                            <label htmlFor="desde" className="form-label fw-bold text-uppercase">Desde:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="desde" name="desde"/>
                            </div>
                            <ErrorMessage
                                name='desde'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="hasta" className="form-label fw-bold text-uppercase">hasta:</label>
                            <div className="col-12">
                                <Field className="form-control" type="date" id="hasta" name="hasta"/>
                            </div>
                            <ErrorMessage
                                name='hasta'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                        <hr/>
                        <div className="col-md-12">
                            <label htmlFor="lote" className="form-label fw-bold text-uppercase">Lote:</label>
                            <div className="col-12">
                                <Field className="form-control" type="text" id="lote" name="lote"/>
                            </div>
                            <ErrorMessage
                                name='lote'
                                component='div'
                                className='field-error text-danger'
                            />
                        </div>
                    </div>
                    <div className="card-footer text-muted">
                        <button type="submit" className="btn btn-dark text-uppercase fw-bold">Obtener reporte</button>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default Licencia
