// import React, {useState, useEffect, useContext} from 'react'
import React, {useState, useEffect} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import {urlApi} from './global/Global'
import './App.css';
import "animate.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";

/*VISTAS*/
import Login from './views/auth/Login';
/*PAGOS*/
import Pagos from './views/pagos/Pagos';
import EditPagos from './views/pagos/EditPagos';
import Resolver from './views/pagos/Resolver';
/*REGISTRADOS*/
import Registrados from './views/registrados/Registrados';
import EditRegistrado from './views/registrados/EditRegistrado';
/*Users */
import Users from './views/users/Users';
import AddUser from './views/users/AddUser';
import EditUser from './views/users/EditUser';
/*Licencias*/
import Licencias from './views/licencias/Licencias';
import AddLicencia from './views/licencias/AddLicencia';
import EditLicencia from './views/licencias/EditLicencia';
import RegistrarLicencia from './views/licencias/RegistrarLicencia';
import PendientesLicencia from './views/licencias/Pendientes';
/*software*/
import Softwares from './views/softwares/Softwares';
import AddSoftware from './views/softwares/AddSoftware';
import EditSoftware from './views/softwares/EditSoftware';
/*CLIENTES*/
import Clientes from './views/clientes/Clientes';
import Detalles from './views/clientes/Detalles';
import EditCliente from './views/clientes/EditCliente';
/*FTP*/
import Ftp from './views/ftp/Ftp';
import EditFtp from './views/ftp/EditFtp';
import AddFtp from './views/ftp/AddFtp';
import DashboardFtp from './views/ftp/DashboardFtp';
/*REPORTES*/
import Reportes from './views/reportes/Reportes';
/*DESCUENTOS*/
import Descuentos from './views/descuentos/Descuentos';
import AddDescuento from './views/descuentos/AddDescuento';
import EditDescuento from './views/descuentos/EditDescuento';
/*Home*/
import Home from './views/home/Home';
import Comprar from './views/home/Comprar';
import Main from './views/home/Main';
import Provisional from './views/home/Provisional';
/*LAyouts*/
import Header from './views/layouts/Header';
import Footer from './views/layouts/Footer';
// Mantenimiento
import Mantenimiento from './views/mantenimiento/Mantenimiento';

/*TEST***************************************************************************/
import Test from './views/test/MercadoPago';
/*TEST**************************************************************************/
//Google analytics
import ReactGA from 'react-ga';
import Swal from 'sweetalert2';

// //Importar state de context
// import LoginContext from './global/login/loginContext';

const App = () => {

//ELIMINA consoles logs en produccion
if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}
  //User
  const [user, setUser]       = useState('');
  const [exist, setExist]     = useState(false);
  const [headers, setHeaders] = useState(false);
  //Redirect
  let history = useHistory();
  ReactGA.initialize('UA-212971689-2');
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  /****/ const [mantenimiento, setMantenimiento] = useState(false) /****/
  /****/ const [fechaMan, setfechaMan] = useState('2022-04-19T16:00:00')
  // /****/ const [fechaMan, setfechaMan] = useState('24/03/2022 01:30 PM')
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/

  /*CONTEXT DE USUARIO */
  // const { agregarUser } = useContext(LoginContext);

  //Userefect comprueba si un usuario esta logueado
  useEffect(() => {
    // ReactGA.pageview("/");
    // ReactGA.pageview("/payments");
    // history.listen(location => ReactGA.pageview(location.pathname));
    console.log('inicio');
    const verificar = async () =>{
      try {
        const log = await AsyncStorage.getItem('@login');
        if (typeof log =='string') {
          const log_json = JSON.parse(log);
          let headers = '';

          if(log_json !==null){
            console.log(log_json);
            headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
            await axios.get(urlApi+'auth/user', {headers: headers})
                      .then(async (response) =>  { 
                        console.log(response);
                        // await AsyncStorage.removeItem(
                        //   '@login'
                        //   );
                        setUser(log_json);
                        setExist(true);
                        console.log('correcto');
                      })
                      .catch(async(error) => {
                        // console.log(error);
                        await AsyncStorage.removeItem(
                          '@login'
                          );
                      });
                      ;
          }

        }
      } catch (error) {
        console.log(error)
      }
    }

    const second = () => { 
      if(document.cookie.indexOf('no_mostrar_name2=')==-1){
        //Alerta de sistema actualmente en mantenimiento COMENTAR SI YA NO HAY MANTENIMIENTO
             Swal.fire({
             html: `<h2><span>Aviso de Mantenimiento<span></h2>
             <p>Con la finalidad de seguir brindando un servicio de excelencia <b>El sistema de pagos (payments) y activaciones</b> estará en mantenimiento el 
                dia <b>viernes 16 de diciembre del año 2022 de 9:00pm a 11:59pm</b>. Reanudando así
                los servicios el dia <b>17 de diciembre del 2022 a las 12:00am</b>
             </p>

             <p>Agradecemos su comprensión</p>

            <p>Equipo de <strong>LabelDictate Technologies LLC</strong></p>`,
          confirmButtonText:
              'OK',
            showCloseButton: true,
            showCancelButton: true,
            cancelButtonText: 'No volver a mostrar',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          }).then((result) => {
            if (result.value) {
              return true;
            }else if(result.dismiss=="cancel"){
              console.log('adasd');
                  let expiresdate =  new Date('2068', '1', '02', '11', '20');
                  let cookievalue = "no_mostrar_value2";
                  document.cookie = "no_mostrar_name2=" + encodeURIComponent( cookievalue ) + ";expires=" + expiresdate.toUTCString()+'; SameSite=Strict';
            }
        });
        }else{
            console.log(document.cookie);
      } 
    }


      verificar();
      // second();
  }, [])
  //Fin use efect
  return (
        <Router>
          <Header
              setUser={setUser} 
              setExist={setExist}
              user={user}
              exist={exist}
              headers={headers}
          />

          <Mantenimiento 
            mantenimiento={mantenimiento}
            fechaMan={fechaMan}
          />

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
            <Switch>
              <Route exact path="/test" render={() => <Test />}/>
              
              <Route exact path="/" render={() => 
                <Home 
                  mantenimiento={mantenimiento}
              />}/>
              <Route exact path="/payments" render={() => 
                <Home
                  mantenimiento={mantenimiento}
              />}/>
              {/* <Route exact path="/home" render={() => <Home />}/> */}
              <Route exact path="/login" render={() => <Login setExist={setExist} setUser={setUser}/>}/>
              <Route exact path="/main/:id" render={(props) => <Main mantenimiento={mantenimiento} {...props}/>}/> 
              <Route exact path="/licencias/registrar" render={() => <RegistrarLicencia/>} />
              <Route exact path="/promocional/:id" render={(props) => <Provisional mantenimiento={mantenimiento} {...props}/>}/>
              {/* <Route exact path="/comprar/" component={Comprar}/>  */}
              <Route exact path="/comprar/:id" component={Comprar}/> 
              {
                (user.role === 1 || user.role === 2) &&
                <Route exact path="/licencias" render={() => <Licencias/>}/>
              }

              {
                user.role === 1 &&
              <>
                <Route exact path="/users" render={() => <Users/>} />
                <Route exact path="/users/create" render={() => <AddUser/>}/>
                <Route exact path="/users/edit" component={EditUser}/> 
                <Route exact path="/licencias/create" render={() => <AddLicencia/>} />
                <Route exact path="/licencias/edit" component={EditLicencia}/> 
                <Route exact path="/licencias/pendientes" component={PendientesLicencia}/> 
                <Route exact path="/softwares" render={() => <Softwares/>}/>
                <Route exact path="/softwares/create" render={() => <AddSoftware/>}/>
                <Route exact path="/softwares/edit" component={EditSoftware}/>
                <Route exact path="/clientes" render={() => <Clientes/>}/>
                <Route exact path="/clientes/detalles" component={Detalles}/>
                <Route exact path="/clientes/edit" component={EditCliente}/>
                <Route exact path="/ftp" render={() => <Ftp/>}/>
                <Route exact path="/ftp/edit" component={EditFtp}/>
                <Route exact path="/ftp/create" component={AddFtp}/>
                <Route exact strict path="/ftp/dashboard" component={DashboardFtp}/>
                <Route exact path="/pagos" render={() => <Pagos/>}/>
                <Route exact path="/pagos/edit" component={EditPagos}/>
                <Route exact path="/pagos/resolver" component={Resolver}/>
                <Route exact path="/registrados" component={Registrados}/>
                <Route exact path="/registrados/edit" component={EditRegistrado}/>
                <Route exact path="/reportes" component={Reportes}/>
                <Route exact path="/descuentos" component={Descuentos}/>
                <Route exact path="/descuentos/create" component={AddDescuento}/>
                <Route exact path="/descuentos/edit" component={EditDescuento}/>
              </>
              }
            </Switch>
          <Footer/>
      </Router>
  );
}

export default App;
