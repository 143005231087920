// import React, {useEffect, useState, useContext} from 'react'
import React, {useEffect, useState} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import AsyncStorage from '@react-native-async-storage/async-storage';

/*Vista tabla*/
import Table from './Table';
import customStyles from '../../styles/styles'
import mainTheme from '../../styles/mainTheme'

// import Auth from '../auth/Auth';

/* Datatable*/
import DataTable, { createTheme } from 'react-data-table-component';
/* Agregar caracter */
import {agregarCaracter} from '../../global/agregarCaracter';

import {
    NavLink, Link
} from "react-router-dom";
/*Globales*/
import {urlApi} from '../../global/Global'
import Loading from '../../styles/Loading'

 //Filtrar texto
const FilterComponent = ({ filterText, onFilter, onClear, cargaBtn }) => (
    <div className="input-group col-md-6 my-3 w-32-pc">
        <input className="form-control" id="search" type="text" placeholder="Filtro de busqueda" aria-label="Example text with button addon" aria-describedby="button-addon1" value={filterText} onChange={onFilter}/>
        
        {
            !cargaBtn ?
                <button className="btn btn-danger" type="button" id="button-addon1" onClick={onClear}>
                    X
                </button>
            :
            <button className="btn btn-danger" type="button" id="button-addon1" disabled>
                    X
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                <span className="visually-hidden">Loading...</span>
            </button>
        }
        
        
    </div> 
);

const Licencias = () => {

    const [cargaBtn, setCargaBtn]         = useState(false);
    const [licencias, setLicencias]       = useState([]);     //data
    const [perlicencias, setperLicencias] = useState([]);     //data
    const [licDis, setLicDis]             = useState(0);      //data rows
    const [licDisN, setLicDisN]           = useState(0);      //data rows no web pay
    const [cuenta, setCuenta]             = useState(0)       //total arrows
    const [cuentaPer, setCuentaPer]       = useState([]);     //cuenta
    const [carga, setCarga]               = useState(false);
    const [rol, setrol]                   = useState('');
    //Pendientes
    const [pendientes, setPendientes] = useState(-1);
    const [dis, setDis]               = useState(false);
    //Datatable
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    /*Filtros*/
    const [filterText, setFilterText]                       = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const filteredItems = licencias.filter(item => (item.codigo_activacion && item.codigo_activacion.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.cod_car && item.cod_car.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.webpay_activation && item.webpay_activation.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.created_at && item.created_at.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.estado.name && item.estado.name.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.vigencia && item.vigencia.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.lote && item.lote.toLowerCase().includes(filterText.toLowerCase()))
                                                 || (item.observaciones && item.observaciones.toLowerCase().includes(filterText.toLowerCase()))
                                                 );
    //Paramas de string a enviar
    let string = '';
    /*Saber si hay activos*/
    const [activos, setactivos] = useState(false);
    /*CONTEXT DE USUARIO */
    // const { headers } = useContext(LoginContext);
    /*Thema principal datatable*/
    createTheme('solarized', mainTheme);

    //**************************************************************************************** */
    const getLicencias = async (params) => {
        setLoading(true);
        try {
            const log      = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers  = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            } 
            setrol(log_json.role);
            const get_licencias = await axios.get(urlApi+'licencias', {headers: headers});
            get_licencias.data.licencia.map((el, index) =>{
                get_licencias.data.licencia[index].cod_car = agregarCaracter(el.codigo_activacion,'-',4)
            })
            console.log(get_licencias.data.licencia);
            setLicencias(get_licencias.data.licencia);
            setperLicencias(get_licencias.data.licencia);
            setCuenta(get_licencias.data.lic_count);
            setCuentaPer(get_licencias.data.lic_count);
            setLicDis(get_licencias.data.lic_dis);
            setLicDisN(get_licencias.data.lic_disn);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    //**************************************************************************************** */
    const getPendientes = async (params = false) => {
        try {
            if (params) {
                setDis(true)
            }
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            const get_pendientes = await axios.get(urlApi+'licencias/pendientes', {headers: headers});
            console.log(get_pendientes.data);
            setPendientes(get_pendientes.data);
            if (params) {
                setDis(false)
            }
        } catch (error) {
            console.log(error);
            if (params) {
                setDis(false)
            }
        }
    }
    
    
    //primer funcion*************************************************************************

    useEffect(() => {
        getLicencias();
        getPendientes();
    }, [])

    //primer funcion*************************************************************************
 
    const handleChangeActivos = async (e) => {
        // console.log(e.target.checked);
        setactivos(e.target.checked);     
        setFilterText('');   
        // setLicencias(perlicencias);
              string   = '';
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'licencias/getlicencias', {page:1, perPage:10, activos:!activos}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.licencia.map((el, index) =>{
                    response.data.licencia[index].cod_car = agregarCaracter(el.codigo_activacion,'-',4)
                })
                setLicencias(response.data.licencia);
                setCuenta(response.data.lic_count);
                setLoading(false);
                setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    //***************************************************************************************** */
    const handleChangeFilter = async (e) => {
        console.log(e);
        setCargaBtn(true);
        setFilterText(e);
        string += e;
        console.log("activos: "+activos);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'licencias/getlicenciasfilter', {page:1, perPage:10, activos:activos, string:string}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.licencia.map((el, index) =>{
                    response.data.licencia[index].cod_car = agregarCaracter(el.codigo_activacion,'-',4)
                })
                setLicencias(response.data.licencia);
                setCuenta(response.data.lic_count);
                setLoading(false);
                // setResetPaginationToggle(!resetPaginationToggle);
                console.log(response.data);
                setCargaBtn(false);
            }).catch((error) => {
                console.log(error);
            }).then(() => {
                setCargaBtn(false);
            });
    }
    

    //Componente de cabecera*******************************************************************
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                setLicencias(perlicencias);
                setCuenta(cuentaPer);
                string = '';
            }
        };
    
        return <FilterComponent 
                    onFilter={e => handleChangeFilter(e.target.value)} 
                    onClear={handleClear} 
                    filterText={filterText} 
                    cargaBtn={cargaBtn}
                />;
    }, [filterText, resetPaginationToggle, cargaBtn]);
      
    //COLUMNAS**********************************************************************************
    const columns = [
        {
          name: 'lote',
          selector: 'lote',
          sortable: true,
          width: '10%'
        },
        {
          name: 'Codigo activacion',
        //   selector: 'codigo_activacion',
          sortable: true,
          width: '12%',
          cell: row => {
                    return <span data-bs-toggle="tooltip" data-bs-placement="bottom" title={agregarCaracter(row.codigo_activacion, '-', 4)}>
                        {agregarCaracter(row.codigo_activacion, '-', 4)}
                    </span>
          }
        },
        {
          name: 'Obs',
        //   selector: 'observaciones',
          sortable: true,
          width: '13%',
          cell: row =>{
            return <span data-bs-toggle="tooltip" data-bs-placement="bottom" title={row.observaciones}>
                {row.observaciones}
            </span>
          }
        },
        {
          name: 'Estado',
        //   selector: 'estado.name',
          sortable: true,
          width: '9%',
          cell: row => {
                    if(row.estado_id==1){
                        return <div className="text-capitalize fw-bold m-1 w-100 rounded bg-success bg-gradient tra-5">
                            <p className="w-100 h-100 my-3 text-center">{row.estado.name}</p>
                        </div>
                    }
                    else if(row.estado_id==2){
                        return <div className="text-capitalize fw-bold m-1 w-100 rounded bg-danger bg-gradient tra-5">
                            <p className="w-100 h-100 my-3 text-center">{row.estado.name}</p>
                        </div>
                    }else if(row.estado_id==3){
                        return <div className="m-1 p-2 rounded bg-primary bg-gradient tra-5">
                                    <p className="mb-0 text-capitalize">
                                        {row.estado.name}
                                    </p>
                                    {
                                    typeof row.clientes[0] !='undefined' &&
                                        <p className="mb-0 fw-bold">
                                            por {row.clientes[0].correo}
                                        </p>
                                    }
                                </div>

                    }else if(row.estado_id==4){
                        return <div className="m-1 p-2 rounded bg-white bg-gradient text-dark tra-5">
                                    <p className="mb-0 text-capitalize">
                                        {row.estado.name}
                                    </p>
                                    {
                                    typeof row.clientes[0] !='undefined' && 
                                        <p className="mb-0 fw-bold">
                                            por {row.clientes[0].correo}
                                        </p>
                                    }
                                </div>
                    }

            }
        },
        {
          name: 'Cliente',
          sortable: true,
          width: '10%',
          cell: row =>(
                <ul className="m-0 p-0 py-2">
                    {
                        row.clientes.length > 0 &&
                        <>
                            <p className="text-capitalize">
                                <b>Software activado: </b>{
                                    
                                    row.registro?.software.name ?? 'Pendiente de activación'
                                }
                            </p>
                            <li className="mb-0 text-capitalize">
                                {row.clientes[0].nombre}
                            </li>
                            <li className="mb-0 text-capitalize">
                                {row.clientes[0].correo}
                            </li>
                        </>
                    }
                </ul> 
          )
        },
        {
          name: 'SW habilitado',
          sortable: true,
          width: '11%',
          cell: row =>(
                <ul className="m-0 p-0 py-2">
                    {
                        row.softwares_without_trash.map((element, i) =>{
                            return <li className="mb-0 text-capitalize" key={i}>
                                        {element.name}
                                    </li>
                        })
                    }
                </ul> 
          )
        },
        {
          name: 'Pay On line',
          selector: 'webpay_activation',
          sortable: true,
          width: '5%'
        },
        {
          name: 'No. Acts',
          selector: 'activaciones',
          sortable: true,
          width: '5%'
        },
        {
            name: 'Fechas',
            sortable: true,
            width: '10%',
            cell: row => {
                    const arr = row.created_at.split('T');
                    return (<div>
                        <label className='fw-bold text-primary mt-2'>Fecha Creación</label>
                        <p className='mb-1'>
                            {arr[0]}
                        </p>

                        <label className='fw-bold text-danger mt-2'>Fecha Vigencia</label>
                        <p className='mb-1'>
                            {row.vigencia}
                        </p>
                        </div>
                    )

            }
        },
 
        {
            name: 'Menu',
            width: '8%',
            cell: row => <Table licencia={row} rol={rol}/>,
        },
      ];
    

    //Cambio de pagina*******************************************************************************
    const handlePageChange = async (page) => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'licencias/getlicencias', {page:page, perPage:perPage, activos:activos}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.licencia.map((el, index) =>{
                    response.data.licencia[index].cod_car = agregarCaracter(el.codigo_activacion,'-',4)
                })
                setLicencias(response.data.licencia);
                setCuenta(response.data.lic_count);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
            .then(() =>{
                setCarga(false);
            })
    };

    //Cambio de rows **********************************************************************************
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        
        await axios.post(urlApi+'licencias/getlicencias', {page:page, perPage:newPerPage, activos:activos}, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                response.data.licencia.map((el, index) =>{
                    response.data.licencia[index].cod_car = agregarCaracter(el.codigo_activacion,'-',4)
                })
                setLicencias(response.data.licencia);
                setCuenta(response.data.lic_count);
                setPerPage(newPerPage);
                setLoading(false);
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
      };
    //Renderizado ***********************************************************************************
    return (
        <>
            <div className="my-5 container">
                <div className="border rounded-3">
                    <div className="p-3 table-responsive">
                        <div className="row">
                            <h1 className="fw-bold text-uppercase col-md-9">Licencias</h1> 
                            <div className="col-md-3">
                                <NavLink className="btn btn-dark w-100 mb-3" exact to="/licencias/create">
                                    <FontAwesomeIcon icon={faPlusCircle}/>
                                    <span className="fw-bold text-uppercase"> Agregar licencias</span>
                                </NavLink>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-9 mb-3">
                                <h4 className="fw-bold text-uppercase text-muted">Disponibles: {licDis} (Pago online)</h4> 
                                <h6 className="fw-bold text-uppercase text-muted">Disponibles: {licDisN} (Sin pago online)</h6> 
                                <div className="d-flex align-items-center">
                                    <Link
                                        style={{textDecoration: 'none', marginRight: '1rem'}}
                                        
                                        to={
                                            {  
                                                pathname:"/licencias/pendientes",
                                            }
                                        }
                                        >
                                        <h6 className="fw-bold text-uppercase link-primary m-0">Envios pendientes: 
                                            {
                                                pendientes<0 ?
                                                <div className="spinner-border spinner-border-sm" role="status" style={{marginLeft:'5px'}}>
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                :
                                                <span className="bg-danger rounded px-1 text-white border border-secondary">
                                                    {pendientes}
                                                </span> 
                                            }
                                        </h6>
                                    </Link> 
                                    
                                    {
                                        !dis ?
                                        <button className="btn btn-primary py-1" type="button" onClick={(e) => getPendientes(true)}>
                                            <FontAwesomeIcon icon={faSyncAlt}/>
                                        </button>
                                        :
                                        <button className="btn btn-primary py-1" type="button" disabled>
                                            <FontAwesomeIcon icon={faSyncAlt}/>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginLeft:'1rem'}}></span>
                                            <span className="visually-hidden">Loading...</span>
                                        </button>
                                    }
                                    
                                </div>
                                
                            </div>
                            <div className="form-check col-md-3 d-flex align-items-center jc-res">
                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => handleChangeActivos(e)}/>
                                <label className="form-check-label text-uppercase fw-bold" style={{marginLeft: '.5rem'}} htmlFor="flexCheckDefault">
                                    Mostrar activos
                                </label>
                            </div>
                        </div>
                        {/* <h6 className="fw-bold text-uppercase text-muted">Disponibles: {licDisN} (Sin pago online)</h6>  */}
                        <hr/>
                        {
                            !activos ?
                                <>
                                    <h3 className="fw-bold text-uppercase">Inactivos</h3>
                                    <DataTable
                                        // title="Licencias"
                                        columns={columns}
                                        data={licencias}
                                        progressPending={loading}
                                        progressComponent={<Loading />}
                                        paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={cuenta}
                                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                        // selectableRows
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        theme='solarized'
                                        customStyles={customStyles}
                                        striped
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                        persistTableHead
                                    />
                                </>
                            :
                            <>
                                <h3 className="fw-bold text-uppercase">Activos</h3>
                                <DataTable
                                    // title="Licencias"
                                    columns={columns}
                                    data={licencias}
                                    progressPending={loading}
                                    progressComponent={<Loading />}
                                    paginationRowsPerPageOptions={[10, 20, 50, 100]}
                                    pagination
                                    paginationServer
                                    paginationTotalRows={cuenta}
                                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                                    // selectableRows
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    theme='solarized'
                                    customStyles={customStyles}
                                    striped
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    persistTableHead
                                />
                            </>
                        }
                         {
                            carga &&
                            <div className="w-100 text-center mt-2">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Licencias
