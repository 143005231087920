import React, {useEffect, useState} from 'react'
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
/* Agregar caracter */
import {agregarCaracter} from '../../global/agregarCaracter';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

const EditPagos = (props) => {

    // const pay = props.location.state.payment;
    // console.log(pay);
    const [pay, setPay] = useState(props.location.state.payment);
    const [estados, setEstados] = useState([]);
    const [softwares, setSoftwares] = useState([]);
    const [descuento, setDescuento] = useState([]);

    useEffect( () => {
    console.log(pay);

        const getSoftwares = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_softwares = await axios.get(urlApi+'softwares/wt', {headers: headers});
                setSoftwares(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getEstados = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_estados = await axios.get(urlApi+'payments/getestados', {headers: headers});
                setEstados(get_estados.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getDescuento = async (pay) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_descuento = await axios.post(urlApi+'descuentos/getdescuento', pay, {headers: headers});
                setDescuento(get_descuento.data);
                console.log(get_descuento.data);
            } catch (error) {
                console.log(error);
            }
        }
        

        getEstados();
        getSoftwares();
        getDescuento(pay);
    }, [])


    return (
        <div className="my-5 container">
            <div className="border rounded-3">
                <div className="p-3 table-responsive">
                    <div className="row mb-3 overflow-auto">
                        <h1 className="fw-bold text-uppercase">Pago: {pay.payment_id}</h1> 
                        <h4 className="fw-bold text-muted">ID de quien pago: {pay.payer_id}</h4> 
                        <h4 className="fw-bold text-muted">Email de pago: {pay.payer_email}</h4> 
                    </div>
                    <hr/>
                    <div className="row mb-3">
                        <div className="col-md-6 border-end mb-3">
                            <h5><strong className="text-uppercase mb-2">Informacion del cliente</strong></h5>
                            {
                                typeof pay.clientes[0] != 'undefined' ?
                                <>
                                    <p className="mb-0"><strong>Nombre: </strong>{pay.clientes[0].nombre}</p>
                                    <p className="mb-0"><strong>Correo: </strong>{pay.clientes[0].correo}</p>
                                    <p className="mb-0"><strong>Empresa: </strong>{pay.clientes[0].empresa}</p>
                                    <p className="mb-0"><strong>Telefono: </strong>{pay.clientes[0].telefono}</p>
                                </>
                                :
                                <>
                                    <p className="mb-0"><strong>---</strong></p>
                                    <p className="mb-0"><strong>---</strong></p>
                                    <p className="mb-0"><strong>---</strong></p>
                                    <p className="mb-0"><strong>---</strong></p>
                                </>
                            }
                            {/* <p className="mb-0"><strong>Correo: </strong>{pay.clientes[0].correo}</p> */}
                            
                        </div>

                        <div className="col-md-6 mb-3">
                            <h5><strong className="text-uppercase mb-2">Informacion general</strong></h5>
                            <p className="mb-0"><strong>Costo total: </strong> ${pay.amount}</p>
                            <p className="mb-0"><strong>Moneda: </strong> {pay.currency}</p>
                            <p className="mb-0"><strong>Cantidad de licencias: </strong> {pay.clientes[0].pivot.cantidad}</p>
                            {
                                softwares.map((sof, index) =>{
                                    return sof.id == pay.clientes[0].pivot.software_id &&
                                    <p className="mb-0" key={index}><strong>Software adquirido: </strong>{sof.name}</p>
                                })
                            }
                        </div>
                    </div>
                    
                    {
                        descuento.length != 0 &&
                        <div className="row">
                            <div className="col-md-12 border-top py-3 animate__animated animate__slideInLeft">
                                <h6>
                                    <strong className="text-uppercase text-success mb-2">Esta venta incluye un descuento <FontAwesomeIcon icon={faCheckCircle} /></strong>
                                </h6>
                                <div>
                                    <p className="mb-0"><strong>Codigo utilizado:</strong> {descuento[0].nombre}</p>
                                    <p className="mb-0"><strong>Descuento de:</strong> {descuento[0].cantidad}{descuento[0].tipo_descuento === 'porcentaje' ? '% Por ciento' : '$ Dolares'}</p>
                                    <p className="mb-0"><strong>Cantidad real:</strong> {descuento[0].precio_sd}$ Dolares</p>
                                    <p className="mb-0"><strong>Descuento:</strong> {descuento[0].descuento}$ Dolares</p>
                                    <p className="mb-0"><strong>Tipo de descuento:</strong> <span className=" text-capitalize">{descuento[0].individual_general}</span></p>
                                </div>
                            </div>
                        </div>
                    }
                    
                    <div className="accordion" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Licencias
                            </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse h-500px overflow-auto" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <h5><strong className="text-uppercase mb-2">Informacion de licencias</strong></h5>
                                    {
                                        pay.licencias.map((element, index) =>{
                                            return <div key={index}>
                                                    <p className="mb-0"><strong>Lote: </strong>{element.lote}</p>
                                                    <p className="mb-0"><strong>Codigo de activación: </strong>{agregarCaracter(element.codigo_activacion, '-', 4)}</p>
                                                    {
                                                        estados.map((e, i) =>{
                                                            return e.id==element.estado_id &&
                                                             <p className="mb-0" key={i}><strong>Estado: </strong>{e.name}</p>
                                                        })
                                                    }
                                                    <p className="mb-0"><strong>¿Acepta pago web?: </strong>{element.webpay_activation}</p>
                                                    <p className="mb-0"><strong>Precio al adquirirlo: $</strong>{element.pivot.precio}</p>
                                                    <hr/>
                                                   </div>
                                        })
                                    }

                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPagos
