import React, {useEffect, useState, useRef} from 'react'
// import React, {useEffect, useState, useContext} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*CHARTS*/
import Chart from './Chart'

/*Vistas */
import Checkbox from '../layouts/Checkbox'

/*Globales*/
import {urlApi} from '../../global/Global'
// import LoginContext from '../../global/login/loginContext';

const AddLicencia = () => {

    const elements = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 1000];
    const [softwares, setSoftwares] = useState([]);
    const [licencias, setLicencias] = useState([]);
    const [observaciones, setObservaciones] = useState('');
    const obs_obli = useRef();
    /*CONTEXT DE USUARIO */
    // const { user } = useContext(LoginContext);
    const [carga, setCarga] = useState(false);
    //Fecha actual
    const f = new Date();
    const v = new Date();
    v.setYear(f.getFullYear()+100);
    let mes = v.getMonth()+1;
    let dia = v.getDate();
    let anio = v.getFullYear();
    if(v.getMonth()+1 <= 9) {
        mes = '0'+ (parseInt(v.getMonth())+1);
    } 
    if(v.getDate() <= 9) {
        dia = '0'+v.getDate();
    } 
    let fecha_vigencia = anio+'-'+mes+'-'+dia;
    /*Fin de fechas */

    /*Sweet alert*/
    const MySwal = withReactContent(Swal);


    useEffect(() => {
        const getSoftwares = async (params) => {
            try {
                const get_softwares = await axios.get(urlApi+'softwares');
                setSoftwares(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getLicencias = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_softwares = await axios.get(urlApi+'licencias/getlicenciacantidad', {headers: headers});
                console.log(get_softwares.data);
                setLicencias(get_softwares.data);
            } catch (error) {
                console.log(error);
            }
        }
        

        getSoftwares();
        getLicencias();

    }, [])

    const getExcel = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        await axios({
            url: urlApi+'licencias/exportar',
            method: 'POST',
            data: params,
            headers: headers,
            responseType: 'blob', // important
        }).then(function (response) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'licencias.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
            console.log(response.data);
        })
        .catch(function (error) {
            console.log(error.data);
        });
    }
    
    //Envia licencias a la API
    const sendLicencias = async (params, actions) => {
        setCarga(true);
        if(observaciones.trim() === '') {
            console.log(obs_obli.current.classList.contains('d-none'));
            if(obs_obli.current.classList.contains('d-none')){
                obs_obli.current.classList.remove('d-none')
            }
            return;
        }else{
            params.observaciones = observaciones
            if(!obs_obli.current.classList.contains('d-none')){
                obs_obli.current.classList.add('d-none')
            }
        }
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        await axios.post(urlApi+'licencias', params, {headers: headers})
                            .then(function (response) {
                                if(response.data.respuesta ==='correcto'){
                                    setCarga(false);
                                    setLicencias(response.data.cantidad);
                                    actions.resetForm({
                                        values: {
                                          // the type of `values` inferred to be Blog
                                            cantidad:elements[0],
                                            software:[],
                                            webpay:"0",
                                            vigencia: fecha_vigencia
                                        },
                                        // you can also set the other form states here
                                      });
                                    getExcel(response.data.licencias);
                                    MySwal.fire({
                                        icon: 'success',
                                        title: 'Correcto',
                                        text: 'Se realizo la accion correctamente',
                                      })
                                }else{
                                    setCarga(false);
                                    MySwal.fire({
                                        icon: 'error',
                                        title: 'Error!',
                                        text: 'Ocurrio un error en la acción',
                                      })
                                }
                                console.log(response.data);
                            })
                            .catch(function (error) {
                                setCarga(false);
                                MySwal.fire({
                                    icon: 'error',
                                    title: 'Error!',
                                    text: 'Ocurrio un error en la acción',
                                  })
                                console.log(error.data);
                            });
            
    }
    
    const handleClickRadio = (params) => {
        setObservaciones(params.target.value);
    }
    
    const handleChangeTextArea = (params) => {
        setObservaciones(params.target.value);
    }
    
    
    //Validador de datos
    const formSchema = Yup.object().shape({
        cantidad: Yup.number()
            .required('Campo requerido'),
        software: Yup.array()
            .required('Campo requerido'),
        vigencia: Yup.string()
            .required('Campo requerido'),
        // observaciones: Yup.string()
        //     .required('Campo requerido'),
    });

    return (
        <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Añadir licencias</h2>
                <div className="row">
                    <div className="card m-auto mt-5">
                        <div className="card-body">
                            <Formik
                                // enableReinitialize
                                initialValues={{
                                    cantidad:elements[0],
                                    software:[],
                                    webpay:"0",
                                    vigencia: fecha_vigencia,
                                    // observaciones: observaciones
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => sendLicencias(values, actions)}
                            >
                                <Form>
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title text-uppercase">Licencia</h5>
                                </div>                                {/* Datos de usuario */}
                                <div className="row g-3">
                                    <div className="col-md-3">
                                        <label htmlFor="cantidad" className="form-label fw-bold text-uppercase">Cantidad</label>
                                        <Field as="select" className="form-control select-custom" name="cantidad">
                                            {
                                                elements.map((element,index) => {
                                                    return <option
                                                                value={element} 
                                                                key={index}
                                                                >{element}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='cantidad'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="software" className="form-label fw-bold text-uppercase">Software</label>
                                        {
                                            softwares.map((value, index) =>{
                                            return <div className="form-check" key={index}>
                                                <Checkbox className="form-check-input" value={value.id} id={index+'as'} name="software"/>
                                                <label className="form-check-label" htmlFor={index+'as'}>
                                                    {value.name}
                                                </label>
                                            </div>
                                            })
                                        }
                                        
                                        {/* <Field type="text" className="form-control" name="software" placeholder="John Smith"/> */}
                                        <ErrorMessage
                                            name='software'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-3">
                                        <label htmlFor="webpay" className="form-label fw-bold text-uppercase">Pago web</label>
                                        <div className="form-check">
                                            <Field className="form-check-input" type="checkbox" value="1" id="webpay" name="webpay"/>
                                            <label className="form-check-label" htmlFor="webpay">
                                                Si
                                            </label>
                                        </div>
                                        <ErrorMessage
                                            name='webpay'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                    
                                    <div className="col-md-3">
                                        <label htmlFor="vigencia" className="form-label fw-bold text-uppercase">Fecha vigencia</label>
                                        <div className="col-10">
                                            <Field className="form-control" type="date" id="vigencia" name="vigencia"/>
                                        </div>
                                        <ErrorMessage
                                            name='vigencia'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                </div>
                                <div className="row flex-row-reverse mt-3">
                                    
                                    <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold text-uppercase text-center">Observaciones</label>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <textarea name="observaciones" className="form-control" id="exampleFormControlTextarea1" rows="4" value={observaciones} onChange={(e) => handleChangeTextArea(e)}></textarea>
                                            <div className='p-1 field-error text-danger fw-bold d-none' ref={obs_obli}>
                                                *Observaciones obligatorias*
                                            </div>
                                        </div>
                                        {/* <p className="text-muted" style={{fontSize: '14px'}}>*Las siguientes opciones no son obligatorias, son solo una ayuda para llenar las observaciones*</p> */}
                                    </div>

                                    <div className="col-md-6">  
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault1" value="Licencias creadas para distribuidor tectronic" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                Licencias creadas para <strong className="text-uppercase">distribuidor tectronic</strong>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault2" value="Licencias creadas para recepcion tectronic" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                Licencias creadas para <strong className="text-uppercase">tectronic León</strong>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault3"  value="Licencias creadas distribuidor ribetec" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                Licencias creadas para <strong className="text-uppercase">distribuidor ribetec</strong>
                                            </label>
                                        </div>
                                        <div className="form-check d-none">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault4"  value="Licencias creadas para distribuidor etifast (fisicas)" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                Licencias creadas para <strong className="text-uppercase">distribuidor etifast (fisicas)</strong>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault5"  value="Licencias creadas para distribuidor etifast (digitales)" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                Licencias creadas para <strong className="text-uppercase">distribuidor etifast (digitales)</strong>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault6"  value="Uso interno sistemas" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                Uso interno <strong className="text-uppercase">Sistemas</strong>
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radios" id="flexRadioDefault7"  value="Licencia pago online" onChange={(e) => handleClickRadio(e)}/>
                                            <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                Licencia pago <strong className="text-uppercase">online</strong>
                                            </label>
                                        </div>
                                    </div>
                                    <p className="text-muted" style={{fontSize: '14px'}}>*Las siguientes opciones no son obligatorias, son solo una ayuda para llenar las observaciones*</p>

                                </div>
                                {/* FIN Datos de usuario */}
                                     
                                <div className="my-4 col-md-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                            // onClick={sendDataPaypal}
                                    >Crear codigos de activación</button>
                                </div>
                                <hr/>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </Form>
                        </Formik>
                        <div className="my-4 col-md-6">
                            <Chart
                                licencias={licencias}
                            />
                        </div>
                    </div>
                </div>
            </div>
    
        </div>
    )
}

export default AddLicencia
