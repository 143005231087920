import React, {useEffect, useState} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
const AddUser = () => {

    const [roles, setRoles] = useState([]);
    const [carga, setCarga] = useState(false);
    const MySwal = withReactContent(Swal);

    useEffect(() => {
        
        const getRoles = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
    
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            try {
                const get_roles = await axios.get(urlApi+'users/getroles', {headers: headers});
                console.log(get_roles.data);
                setRoles(get_roles.data);
            } catch (error) {
                console.log(error);
            }
        }
        getRoles();
    }, [])

    const sendUser = async (params, actions) => {
        setCarga(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'users', params, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                if(response.data.respuesta ==='correcto'){
                    setCarga(false);
                    actions.resetForm({
                        values: {
                          // the type of `values` inferred to be Blog
                            name:"",
                            description:"",
                            costo:"",
                        },
                      });
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else{
                    setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                }
                console.log(response.data);
            })
            .catch((error) => {
                setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                console.log(error.data);
            });
    }
    

    //Validador de datos
    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo requerido'),
        correo: Yup.string()
            .email('Debe ser un correo')
            .required('Campo requerido'),
        passwd: Yup.string()
            .min(8, 'Debe ser minimo 8 caracteres')
            .required('Campo requerido'),
        passwdConfirmation: Yup.string()
            .required('Campo requerido')
            .test('passwords-match', 'Las contraseñas deben ser iguales', function(value){
                return this.parent.passwd === value
              }),
        rol: Yup.number()
            .required('Campo requerido'),
    });

    return (
         <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Añadir usuario</h2>
            <Formik
                enableReinitialize
                initialValues={{
                    name:"",
                    correo:"",
                    passwd:"",
                    passwdConfirmation:"",
                    rol:2,
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendUser(values, actions)}
            >
                <Form>
                    <div className="row">
                        <div className="card m-auto mt-5 w-50-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title text-uppercase">Añadir usuario</h5>
                                </div>
                                <div className="row mb-3">
                                </div>
                                <p className="card-text text-muted">Llene los campos para añadir un usuario</p>
                                {/* Datos de usuario */}
                                <div className="mb-3">
                                    <label htmlFor="name" className="form-label">Nombre</label>
                                    <Field type="text" className="form-control" name="name" placeholder="Eren Jaeger"/>
                                    <ErrorMessage
                                        name='name'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="correo" className="form-label">Correo</label>
                                    <Field type="email" className="form-control" name="correo" placeholder="levi@ackerman.com"/>
                                    <ErrorMessage
                                        name='correo'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="passwd" className="form-label">Contraseña</label>
                                    <Field type="password" className="form-control" name="passwd"/>
                                    <ErrorMessage
                                        name='passwd'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="passwdConfirmation" className="form-label">Confirmar contraseña</label>
                                    <Field type="password" className="form-control" name="passwdConfirmation"/>
                                    <ErrorMessage
                                        name='passwdConfirmation'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="rol" className="form-label">Rol</label>
                                    <Field as="select" className="form-control select-custom" name="rol">
                                        {
                                            roles.map((element,index) => {
                                                return  <option
                                                            value={element.id} 
                                                            key={index}
                                                            >{element.name}
                                                        </option>
                                            })
                                        }
                                    </Field>
                                    <ErrorMessage
                                        name='rol'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>
                                {/* FIN Datos de usuario */}
                                <hr/>
                                <div className="my-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                    >Añadir usuario</button>
                                </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    )
}

export default AddUser
