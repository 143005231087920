import React, {useEffect, useState, useRef} from 'react'
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";
import Contacto from './Contacto';
/*Globales*/
import {url as urlno, urlApi} from '../../global/Global'
import { useHistory } from "react-router-dom";
import Particles from 'react-particles-js';

const Main = (props) => {
    console.log(props);
    const [software, setSoftware] = useState('')
    const [softwares, setSoftwares] = useState('')
    const [image, setImage] = useState('')
    const [cargar, setCargar] = useState(false)
    const [styles, setStyles] = useState(false)
    const [listas, setListas] = useState([]);


    const [styleDin, setstyleDin] = useState('animate__fadeIn');
    const [textDin, settextDin] = useState('');
    let comp = 0;

    const refParticles = useRef();
    /*Redireccion */
    const history = useHistory();

    useEffect(() => {
        const getSoftware = async () => {
            window.scrollTo(0, 0)
            const url = urlApi+'softwares/'+props.match.params.id;
            const headers = {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            
            await axios.get(url, {headers: headers})
                    .then(response => {
                        if (response.data.respuesta==='error') {
                            history.push('/payments');
                        }
                        setCargar(true);
                        setSoftware(response.data);
                        setListas(JSON.parse(response.data.lista));
                        setTimeout(() => {
                            
                            setImage(urlno+'storage/'+response.data.image);
                        }, 1000);
                    })
                    .catch(error =>{
                        // if (response.data.respuesta==='error') {
                        history.push('/payments');
                        // }
                        console.log(error);
                    });
        }

        const getSoftwares = async () => {
            await axios.get(urlApi+'softwares')
                .then(response =>{
                    setSoftwares(response.data);
                    // console.log(response.data);
                    // console.log(response.data.length);
                    settextDin(response.data[0].name)
                }).catch(error => {
                    console.log(error);
                })
                
        }
        
       
        getSoftwares();
        getSoftware();
        

    }, [])


    return (

            <>
                <div className="banner-sw pb-5">
                {
                image!=='' ?
                    <div>
                        <div className="container pt-5 animate__animated animate__fadeInDown">
                            <h1 className="fw-bold text-white text-center text-uppercase animate__animated animate__fadeInDown">{software.name}</h1>
                            <h3 className="text-white">
                                {software.description}
                            </h3>
                            <ul className="text-white h4">
                                {   
                                    listas.map((e, i) =>{
                                        return <li className="p-2" key={i}>{e.name}</li>
                                    })
                                    
                                }
                            </ul>

                        </div>
                        <div className="container">
                            <div className="text-center my-5">
                            
                                <div className="animate__animated animate__fadeIn" id="compras">
                                    <img className="img-fluid" src={image} alt="logo-producto" style={{width: '200px'}}/>
                                </div>
                               

                            </div>
                        </div>
                    </div>
                :
                            <div className="row justify-content-center my-5">
                                <div className="spinner-grow text-white" style={{width: '3rem', height: '3rem'}} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                }
                    <div className="d-flex justify-content-center font-size-5rem blue-arrow">
                        <FontAwesomeIcon icon={faArrowCircleDown} id="anime"/>
                    </div>
                    <div className="text-center mt-5"  >

                    <Link 
                            className={props.mantenimiento ? `btn btn-success 200 m-auto border border-secondary disabled` : `btn btn-success 200 m-auto border border-secondary`}
                            to={
                                {  
                                    pathname:"/comprar/"+software.id,
                                    state:{software: software}
                                }
                            }
                            >
                        <p className="h2 text-white fw-bold text-uppercase">Buy now!</p>
                        {/* <p className="h2 text-white fw-bold text-uppercase">¡Comprar ahora!</p> */}
                        </Link> 
                    </div>
                </div>
                <div className="h-75 tt">
                    <Particles
                        params={{
                            "particles": {
                                "number": {
                                "value": 50
                            },
                            "size": {
                                "value": 2.5
                                }
                            },
                            "interactivity": {
                                "events": {
                                    "onhover": {
                                        "enable": true,
                                        "mode": "grab"
                                    },
                                    "onclick": {
                                        "enable": true,
                                        "mode": "repulse"
                                    }
                                }
                            }
                            
                        }}
                        style={{
                            width: '100%',
                            background: '#6eb446' 
                        }}
                        />
                    <p className={`h1 textote2 text-uppercase fw-bold contorno`} ref={refParticles} data={styleDin}>Variety of applications</p>
                    {/* <p className={`h1 textote2 animate__animated `+styleDin} ref={refParticles} data={styleDin}>{textDin}</p> */}
                </div>
 
            <Contacto
                main={true}
            />
        </>
    )
}

export default Main
