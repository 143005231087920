import React, {useEffect, useState, useRef} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global'
/*Vistas */
import Checkbox from '../layouts/Checkbox'
const AddDescuento = () => {

    const [carga, setCarga] = useState(false);
    const [descuento, setDescuento] = useState(1); //Cantidad descuento
    const [placeholder, setPlaceholder] = useState('');
    const MySwal = withReactContent(Swal);
    const [softwares, setSoftwares] = useState([]);
    const [appEmpty, setAppEmpty] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        console.log(ref.current.values.tipo_descuento);
        ref.current.values.tipo_descuento == 'porcentaje' ?
            setPlaceholder('%')
        :
            setPlaceholder('$');

            const getSoftwares = async () => {
                await axios.get(urlApi+'softwares')
                        .then(response =>{
                            setSoftwares(response.data);
                            console.log(response.data);
                        }).catch(error => {
                            console.log(error);
                        })
                
            }
    
            getSoftwares();
    }, [])


    //Funcion que previene letras
    const handleChange = (e) => {
        /*Validamos solo numeros*/
        e.target.value == 'porcentaje' ?
            setPlaceholder('%')
        :
            setPlaceholder('$');

    }


    const sendDescuento = async (params, actions) => {
        setCarga(true);
        console.log(params.software.length);
        if(params.software.length === 0){
            setAppEmpty(true);
            return;
        }
        setAppEmpty(false);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'descuentos/store', params, {headers: headers})
            .then((response) => {
                console.log(response.data);
                //Actualiza estados del formulario
                if(response.data.respuesta ==='correcto'){
                    setCarga(false);
                    actions.resetForm({
                        values: {
                          // the type of `values` inferred to be Blog
                            nombre:"",
                            tipo_descuento:"porcentaje",
                            descripcion:"",
                            cantidad:"",
                            individual_general:"individual",
                            estado:"1",
                            automatizacion:"0",   
                            software:[],                         
                        },
                      });
                    MySwal.fire({
                        icon: 'success',
                        title: 'Correcto',
                        text: 'Se realizo la accion correctamente',
                      })
                }else if(response.data.respuesta =='error-Fail'){
                    setCarga(false);
                    if(response.data.message.nombre[0] === 'The nombre has already been taken.'){
                        MySwal.fire({
                            icon: 'warning',
                            title: 'Nombre de promoción duplicado',
                            text: 'El nombre ya existe en la base de datos, elija otro',
                          })
                    }
                    else{
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Ocurrio un error en la acción',
                          })
                    }
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                }
            })
            .catch((error) => {
                setCarga(false);
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                      })
                console.log(error);
            });
    }
    

    //Validador de datos
    const formSchema = Yup.object().shape({
        nombre: Yup.string()
            .min(5, '¡Muy corto!')
            .max(15, '¡Muy largo!')
            .required('Campo requerido'),
        cantidad: Yup.number()
            .min(1, 'no puede ser menor a 1')
            .max(90, 'No puede ser mayor a 90')
            .typeError('Ingrese un número valido')
            .required("Campo requerido"),
        descripcion: Yup.string()
            .required("Campo requerido"),
        tipo_descuento: Yup.string()
            .required('Campo requerido'),
            individual_general: Yup.string()
            .required('Campo requerido'),
        estado: Yup.number()
            .required('Campo requerido'),
        automatizacion: Yup.number()
            .required('Campo requerido'),
        software: Yup.array()
            .required('Campo requerido'),
    });

    return (
         <div className="container my-5">
            <h2 className="fw-bold text-uppercase">Añadir usuario</h2>
            <Formik
                // enableReinitialize
                innerRef={ref}
                initialValues={{
                    nombre:"",
                    tipo_descuento:"porcentaje",
                    descripcion:"",
                    cantidad:"",
                    individual_general:"individual",
                    estado:"1",
                    automatizacion:"0",
                    software:[],
                }}
                validationSchema={formSchema}
                onSubmit={(values, actions) => sendDescuento(values, actions)}
            > 
            {/* {({
                setFieldValue,
                setFieldTouched,
                values,
                errors,
                touched,
              }) => ( */}
                <Form>
                    <div className="row">
                        <div className="card m-auto mt-5 w-50-100">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <h5 className="card-title text-uppercase">Añadir código de descuento</h5>
                                </div>
                                <div className="row mb-3">
                                </div>
                                <p className="card-text text-muted">Llene los campos para añadir un código de descuento</p>
                                {/* Datos de usuario */}
                                <div className="mb-3">
                                    <label htmlFor="nombre" className="form-label fw-bold">Nombre código de promocion</label>
                                    <Field type="text" className="form-control" name="nombre" placeholder="PROMO2021"/>
                                    <ErrorMessage
                                        name='nombre'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="tipo_descuento" className="form-label fw-bold">Tipo de descuento</label>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="tipo_descuento" id="porcent" value="porcentaje" onClick={handleChange}/>
                                        <label className="form-check-label" htmlFor="porcent">
                                            Porcentaje
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="tipo_descuento" id="dollars" value="dolares" onClick={handleChange}/>
                                        <label className="form-check-label" htmlFor="dollars">
                                            Dolares
                                        </label>
                                    </div>
                                </div>         

                                <div className="mb-3">
                                    <label htmlFor="cantidad" className="form-label fw-bold">Cantidad a descontar</label>
                                    <Field 
                                        type="text" 
                                        className="form-control"
                                        name="cantidad"
                                        pattern="^-?[0-9]\d*\.?\d*$" 
                                        placeholder={placeholder} 
                                        />
                                    <ErrorMessage
                                        name='cantidad'
                                        component='div'
                                        className='field-error text-danger'
                                    />
                                </div>

                                <hr/>
                                <div className="mb-3">
                                {
                                    softwares.map((value, index) =>{
                                    return <div className="form-check" key={index}>
                                        <Checkbox className="form-check-input" value={value.id} id={index+'as'} name="software"/>
                                        <label className="form-check-label" htmlFor={index+'as'}>
                                            {value.name}
                                        </label>
                                    </div>
                                    })
                                }
                                
                                <ErrorMessage
                                    name='software'
                                    component='div'
                                    className='field-error text-danger'
                                />
                                <div className={appEmpty ? `field-error text-danger` : `field-error text-danger d-none`}>
                                    <p>Debe seleccionar al menos una aplicación</p>
                                </div>
                                </div>
                                <hr/>
                                <div className="mb-3">
                                    <label htmlFor="descripcion" className="form-label fw-bold">Descripción</label>
                                    <div className=" form-floating">
                                        <Field as="textarea" className="form-control" placeholder="Añade una descripcion"  id="floatingTextarea" name="descripcion"></Field>
                                        <label htmlFor="floatingTextarea">Agrega una descripción</label>
                                        <ErrorMessage
                                            name='descripcion'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>
                               
                                <div className="mb-3">
                                    <label htmlFor="individual_general" className="form-label fw-bold">Aplica descuento a licencia individual o al total de la compra (general)</label>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="individual_general" id="flexRadioDefault1" value="individual" />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            Individual
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="individual_general" id="flexRadioDefault2" value="general"/>
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            General
                                        </label>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="estado" className="form-label fw-bold">Estatus</label>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="estado" id="acti" value="1"/>
                                        <label className="form-check-label" htmlFor="acti">
                                            Activo
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="estado" id="desacti" value="0"/>
                                        <label className="form-check-label" htmlFor="desacti">
                                            Inactivo
                                        </label>
                                    </div>
                                </div>
                                <hr/>
                                <div className="mb-3">
                                    <label htmlFor="automatizacion" className="form-label fw-bold text-uppercase text-danger">Desactivar codigo despues del primero uso</label>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="automatizacion" id="desactivado" value="0"/>
                                        <label className="form-check-label" htmlFor="desactivado">
                                            No, no desactivar
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <Field className="form-check-input" type="radio" name="automatizacion" id="activado" value="1"/>
                                        <label className="form-check-label" htmlFor="activado">
                                            Si, desactivar despues del primer uso
                                        </label>
                                    </div>
                                </div>
                                {/* FIN Datos de descuento */}
                                <hr/>
                                <div className="my-4">
                                    <button type="submit" 
                                            className="btn btn-dark w-100 fw-bold text-uppercase"
                                            disabled={
                                                carga
                                            }
                                    >Añadir Descuento</button>
                                </div>
                                {
                                    carga &&
                                    <div className="w-100 text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Form>
                {/* )} */}
            </Formik>
        </div>
    )
}

export default AddDescuento
