import React from 'react'

const Footer = () => {
    return (
        <footer className="mt-auto py-4 bg-dark box-sh">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="text-white text-center w-100">
                        <p className="m-0">© Copyright <strong>Label Dictate Technologies LLC.</strong> All right reserved</p>
                        <span className="text-muted">
                            Designed by <a href="/" className="link-secondary">Label Dictate Technologies LLC</a> 
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
