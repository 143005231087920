import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faEdit, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {
    Link
} from "react-router-dom";

const Table = ({licencia, rol}) => {

    return (
        <>
            {
                (rol == 1) ?
                    <div className="d-flex justify-content-around w-100">     
                        <Link className="btn btn-success"
                            to={
                                {  
                                    pathname:"/licencias/edit",
                                    state:{licencia: licencia}
                                }
                            }

                        >
                                <FontAwesomeIcon icon={faEdit} />  <span className="fw-bold"></span>     
                        </Link>
                        {/* <button className="btn btn-danger">
                                <FontAwesomeIcon icon={faTrash} />  <span className="fw-bold">Eliminar</span>     
                        </button> */}
                    </div>
                :
                <div className="d-flex justify-content-around w-100">     
                    <button className="btn btn-warning cursor-ban" type="button" disabled>
                            <FontAwesomeIcon icon={faTimesCircle} />  <span className="fw-bold"></span>     
                    </button>
                </div>

            }
        </>
             
    )
}

export default Table
