import React, {useEffect, useState} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
/*Globales*/
import {urlApi} from '../../global/Global'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const MercadoPago = () => {

    const public_key = 'TEST-c339230f-b4ae-46eb-b743-7b763d9db42d';
    const [doSubmit, setDoSubmit] = useState(false);
    const [paramss, setparamss] = useState('');
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    let kk='';
    useEffect(() => {
        window.Mercadopago.setPublishableKey(public_key);
    }, [])

    useEffect(() => {
            kk = paramss;
    }, [paramss])

    const guessPaymentMethod = (event) => {
        let cardnumber = document.getElementById("cardNumber").value;
        if (cardnumber.length >= 6) {
            let bin = cardnumber.substring(0,6);
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, setPaymentMethod);
        }
     };
     
     const setPaymentMethod = (status, response) => {
        if (status == 200) {
            let paymentMethod = response[0];
            document.getElementById('paymentMethodId').value = paymentMethod.id;
     
            getIssuers(paymentMethod.id);
        } else {
            alert(`payment method info error: ${response}`);
        }
     }
/************************************************************************************ */
     const getIssuers = (paymentMethodId) => {
        window.Mercadopago.getIssuers(
            paymentMethodId,
            setIssuers
        );
     }
     
     const setIssuers = (status, response) => {
        if (status == 200) {
            let issuerSelect = document.getElementById('issuer');
            response.forEach( issuer => {
                let opt = document.createElement('option');
                opt.text = issuer.name;
                opt.value = issuer.id;
                issuerSelect.appendChild(opt);
            });
     
            getInstallments(
                document.getElementById('paymentMethodId').value,
                document.getElementById('transactionAmount').value,
                issuerSelect.value
            );
        } else {
            alert(`issuers method info error: ${response}`);
        }
     }
    /************************************************************************** */

    const getInstallments = (paymentMethodId, transactionAmount, issuerId) =>{
        window.Mercadopago.getInstallments({
            "payment_method_id": paymentMethodId,
            "amount": parseFloat(transactionAmount),
            "issuer_id": parseInt(issuerId)
        }, setInstallments);
    }
    
    const setInstallments = (status, response) =>{
        if (status == 200) {
            document.getElementById('installments').options.length = 0;
            response[0].payer_costs.forEach( payerCost => {
                let opt = document.createElement('option');
                opt.text = payerCost.recommended_message;
                opt.value = payerCost.installments;
                document.getElementById('installments').appendChild(opt);
            });
        } else {
            alert(`installments method info error: ${response}`);
        }
    }
    /********************************************************************* */
    const getCardToken = (params) =>{
        params.installments = 1;
        params.issuer = 158;
        params.paymentMethodId = 1;
        console.log(params);
        // return;
        setparamss(params)
        // event.preventDefault();
        if(!doSubmit){
            let $form = document.getElementById('paymentForm');
            window.Mercadopago.createToken($form, setCardTokenAndPay);
            return false;
        }
     };
     
     const setCardTokenAndPay = async (status, response) => {
        if (status == 200 || status == 201) {
            let form = document.getElementById('paymentForm');
            let card = document.createElement('input');
            card.setAttribute('name', 'token');
            card.setAttribute('type', 'hidden');
            card.setAttribute('value', response.id);
            form.appendChild(card);
            setDoSubmit(true);

            const id = response.id;
            const send_json ={
                description: "des",
                docNumber: "test-1",
                email: "test@test.com",
                installments: 1,
                issuer: 158,
                paymentMethodId: 1,
                transactionAmount: 100,
                id: id
            }

            console.log(card);
            // return;
            await axios.post(urlApi+'mercadopago', send_json)
                            .then(function (response) {
                                // if(response.data.respuesta ==='correcto'){
                                //     MySwal.fire({
                                //         icon: 'success',
                                //         title: 'Correcto',
                                //         text: 'Se realizo la accion correctamente',
                                //       })
                                // }else{
                                //     MySwal.fire({
                                //         icon: 'error',
                                //         title: 'Error!',
                                //         text: 'Ocurrio un error en la acción',
                                //       })
                                // }
                                console.log(response.data);
                                // console.log(response);
                            })
                            .catch(function (error) {
                                MySwal.fire({
                                    icon: 'error',
                                    title: 'Error!',
                                    text: 'Ocurrio un error en la acción',
                                  })
                                console.log(error);
                            });
        } else {
            alert("Verify filled data!\n"+JSON.stringify(response, null, 4));
        }
     };

    /********************************************************************* */
    //Validador de datos
    const formSchema = Yup.object().shape({
        email: Yup.string(),
            // .required('Campo requerido'),
        docNumber: Yup.string(),
            // .required('Campo requerido'),
        issuer: Yup.number(),
            // .required('Campo requerido'),
        installments: Yup.string(),
            // .required('Campo requerido'),
        transactionAmount: Yup.number(),
            // .required('Campo requerido'),
        paymentMethodId: Yup.number(),
            // .required('Campo requerido'),
        description: Yup.string(),
            // .required('Campo requerido'),

    });

    return (
        <div className="container my-5 d-flex justify-content-center">
            <Formik
            // enableReinitialize
            initialValues={{
                email: "test@test.com",
                docNumber: "test-1",
                issuer: "1",
                installments: "1",
                transactionAmount: "100",
                paymentMethodId: "1",
                description: "des"
                
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => getCardToken(values, actions)}
            >
                {/* 4075595716483764 */}
                <Form id="paymentForm">
                    <h3>Detalles del comprador</h3>
                        <div>
                            <div>
                                <label htmlFor="email">E-mail</label>
                                <Field id="email" name="email" type="text"/>
                            </div>
                            <div>
                                <label htmlFor="docNumber">Número de documento</label>
                                <Field id="docNumber" name="docNumber" data-checkout="docNumber" type="text" />
                            </div>
                        </div>
                    <h3>Detalles de la tarjeta</h3>
                        <div>
                            <div>
                                <label htmlFor="cardholderName">Titular de la tarjeta</label>
                                <input id="cardholderName" data-checkout="cardholderName" type="text" value="Kev mar"/>
                            </div>
                            <div>
                                <label htmlFor="">Fecha de vencimiento</label>
                                <div>
                                <input type="text" placeholder="MM" id="cardExpirationMonth" data-checkout="cardExpirationMonth"
                                    onselectstart="return false" onpaste="return false"
                                    oncopy="return false" oncut="return false"
                                    ondrag="return false" ondrop="return false" autocomplete="off" 
                                    
                                    />
                                <span className="date-separator">/</span>
                                <input type="text" placeholder="YY" id="cardExpirationYear" data-checkout="cardExpirationYear"
                                    onselectstart="return false" onpaste="return false"
                                    oncopy="return false" oncut="return false"
                                    ondrag="return false" ondrop="return false" autocomplete="off" />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="cardNumber">Número de la tarjeta</label>
                                <input type="text" id="cardNumber" data-checkout="cardNumber"
                                    onselectstart="return false" onpaste="return false"
                                    oncopy="return false" oncut="return false"
                                    ondrag="return false" ondrop="return false" autocomplete="off"
                                    onChange={guessPaymentMethod}
                                    />
                            </div>
                            <div>
                                <label htmlFor="securityCode">Código de seguridad</label>
                                <input id="securityCode" data-checkout="securityCode" type="text"
                                    onselectstart="return false" onpaste="return false"
                                    oncopy="return false" oncut="return false"
                                    ondrag="return false" ondrop="return false" autocomplete="off"/>
                            </div>
                            <div id="issuerInput">
                                <label htmlFor="issuer">Banco emisor</label>
                                <Field as="select" id="issuer" name="issuer" data-checkout="issuer"></Field>
                            </div>
                            <div>
                                <label htmlFor="installments">Cuotas</label>
                                <select type="text" id="installments" name="installments"></select>
                            </div>
                            <div>
                                <Field type="hidden" name="transactionAmount" id="transactionAmount"/>
                                <Field type="hidden" name="paymentMethodId" id="paymentMethodId" />
                                <Field type="hidden" name="description" id="description" />
                                <br/>
                                <button type="submit">Pagar</button>
                                <br/>
                            </div>
                        </div>
                </Form>
            </Formik>
            {/* <script
                src="https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js"
                data-preference-id={kk}>
            </script> */}
        </div>
    )
}

export default MercadoPago
