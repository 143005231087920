const mainTheme = {
    text: {
        primary: '#ffffff',
        secondary: '#ffffd6',
    },
    background: {
        default: '#212529',
    },
    context: {
        background: '#cb4b16',
        text: '#FFFFFF',
    },
    divider: {
        default: '#373b3e',
    },
    
};

export default mainTheme;