import React, { useEffect, useState } from 'react'
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons'
/*VISTAS*/
import Softwares from './Softwares';
import Contacto from './Contacto';
/*Globales*/
import {urlApi} from '../../global/Global'
/*img*/
import paypal from '../../img/paypal.png'
import Message from './Message';


const Home = ({mantenimiento}) => {

    const [softwares, setSoftwares] = useState([]);

    useEffect( () => {
        window.scrollTo(0, 0);
        const getSoftwares = async () => {
            await axios.get(urlApi+'softwares')
                    .then(response =>{
                        setSoftwares(response.data);
                        console.log(response.data);
                    }).catch(error => {
                        console.log(error);
                    })
            
        }

        getSoftwares();
        
    }, [])


    return (
        <>

            <Message/>
            
            <div className={softwares.length > 0 ? `banner z-index-1000` : ''}>
                <div className="container pt-3 z-index-1000 d-none" id="compras">
                    <div className="row my-5">

                        {
                        softwares.length > 0 ?
                            softwares.map((software, index) =>(
                                
                                <Softwares
                                    key={software.id}
                                    software={software}
                                    mantenimiento={mantenimiento}
                                />
                            ))
                        :
                        <div className="row justify-content-center mb-5">
                            <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>
            <Contacto
                main={false}
            />
        </>

            
    )
}

export default Home
