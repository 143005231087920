import React from 'react'
import { Bar } from '@reactchartjs/react-chart.js'

const VerticalBarExp = ({countExp}) => {

  const {activo, expirado, por_expirar} = countExp;

  const data = {
    labels: ['Expirado', 'Por expirar', 'Activo'],
    datasets: [
      {
        label: 'Vigencias',
        data: [expirado, por_expirar, activo],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
  
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
  
        ],
        borderWidth: 1,
      },
    ],
  }
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  return (
    <>
      <div className='header'>
        <h1 className='title'>Vigencias</h1>
      </div>
      <Bar data={data} options={options} />
    </>
  )
}
export default VerticalBarExp
