import React from 'react'
import Ftp from './Ftp'
import Licencia from './Licencia'
import Registro from './Registro'
import Pago from './Pago'
import Cliente from './Cliente'
import PrimerActivacion from './PrimerActivacion'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const Reportes = () => {

    //SweetAlert2
    const MySwal = withReactContent(Swal);

    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        allowOutsideClick: false, 
        didOpen: (toast) => {
            MySwal.showLoading();
            // toast.addEventListener('click', () => {return false;})
        }
        })

    return (
        <div className="my-5 container">
            <div className="border rounded-3">
                <div className="p-3 table-responsive">
                    <div className="row">
                        <h1 className="fw-bold text-uppercase col-md-9">Reportes</h1> 
                    </div>
                    <hr/>
                </div>
                <div className="row mx-4">
                
                    <Cliente
                        Toast={Toast}
                    />

                    <Pago
                        Toast={Toast}
                    />

                    <Ftp
                        Toast={Toast}
                    />

                    <Registro
                        Toast={Toast}
                    />

                    <Licencia
                        Toast={Toast}
                    />

                    <PrimerActivacion
                        Toast={Toast}
                    />
                    
                </div>
            </div>
        </div>
    )
}

export default Reportes
