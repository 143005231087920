import React, {useReducer} from 'react';

import LoginReducer from './loginReducer';
import LoginContext from './loginContext';

import { AGREGAR_USER, ELIMINAR_USER } from '../types'


const LoginState = (props) =>{

    const initialState = {
        user: '',
        exist: false,
        headers: ''
    }

    //userReducer con dispatch para ejecutar las funciones
    const [state, dispatch] = useReducer(LoginReducer, initialState);

    //Agrega el usuario (USER) al estado
    const agregarUser = (params) => {
        dispatch({
            type: AGREGAR_USER,
            payload: params
        })
        // console.log(params);
    }

    //Agrega el usuario (USER) al estado
    const eliminarUser = (params) => {
        dispatch({
            type: ELIMINAR_USER,
            payload: ''
        })
        // console.log(params);
    }

    return(
        <LoginContext.Provider
            value={{
                user: state.user,
                exist: state.exist,
                headers: state.headers,
                agregarUser,
                eliminarUser
            }}
        >
            {props.children}
        </LoginContext.Provider>
    )
}

export default LoginState;