import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {url, urlApi} from '../../global/Global'
import { useHistory } from "react-router-dom";


/************************************************************************************************ */
const Resolver = (props) => {

    const [pay, setPay] = useState(props.location.state.payment);
    const MySwal = withReactContent(Swal);
    const [carga, setCarga] = useState(false);
    const [softwares, setSoftwares] = useState([]);
    const [financialGoal, setfinancialGoal] = useState(1);
    const [precioIndividual, setprecioIndividual] = useState(0);
    //Redirect
    let history = useHistory();
/************************************************************************************************ */
    useEffect(() => {
        
        const getSoftware = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
                const get_softwares = await axios.get(urlApi+'softwares');
                setSoftwares(get_softwares.data);
        }
        
        getSoftware();

        console.log(pay);
    }, [])

/************************************************************************************************/
    const handleChange = (e) =>{
        let _financialGoal = (e.target.validity.valid) ? e.target.value : financialGoal;
        if(_financialGoal<=0) {
            _financialGoal=1;
        }
        setfinancialGoal(_financialGoal);
      }

    const handleChange2 = (e) =>{
        let _financialGoal = (e.target.validity.valid) ? e.target.value : precioIndividual;
        if(_financialGoal<=0) {
            _financialGoal=1;
        }
        setprecioIndividual(_financialGoal);
      }
    
/************************************************************************************************ */

      const sendResolve = async (params) => {
          setCarga(true);
          console.log(params);
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          const headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
          }
  

        await axios.post(urlApi+'payments/resolve', params, {headers: headers})
                    .then(function (response) {
                        console.log(response.data);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Correcto',
                            text: 'Se realizo la accion correctamente',
                          });
                          history.push('/pagos');
                        
                    })
                    .catch(function (error) {
                        console.log(error);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: 'Ocurrio un error en la acción',
                          })
                    })
                    .then(() =>{
                        setCarga(false);
                    });
      }
      

/************************************************************************************************ */
    //Validador de datos
    const formSchema = Yup.object().shape({
        payid: Yup.string()
            .required('Campo requerido'),
        payeremail: Yup.string()
            .required('Campo requerido'),
        cantidad: Yup.number()
            .required('Campo requerido'),
        software: Yup.number()
            .required('Campo requerido'),
    }); 
/************************************************************************************************ */

    return (
        <div className="container my-5">
        <h2 className="fw-bold text-uppercase">Pagos</h2>
        <Formik
            enableReinitialize
            initialValues={{
                payid: pay.payment_id,
                payeremail: pay.payer_email,
                cantidad: financialGoal,
                software: 1,
                pre_ind: precioIndividual,
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => sendResolve(values, actions)}
            render={({ values, errors, touched, handleReset }) => {
                console.group("formik");
                    console.log("touched", touched);
                    console.log("values", values);
                    console.groupEnd("formik");
                return (
                    <Form>
                        <div className="row">
                            <div className="card m-auto mt-5 w-50-100">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="card-title text-uppercase">Resolver pago</h5>
                                        <span className="card-title text-muted">Costo: ${pay.amount}</span>
                                    </div>
                                    <div className="row mb-3">
                                    </div>
                                    {/* Datos de usuario */}
                                    <div className="mb-3">
                                        <label htmlFor="payid" className="form-label">PAYID</label>
                                        <Field type="text" className="form-control" name="payid" placeholder="payid"/>
                                        <ErrorMessage
                                            name='payid'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="payeremail" className="form-label">PAYER email</label>
                                        <Field type="text" className="form-control" name="payeremail" placeholder="payeremail"/>
                                        <ErrorMessage
                                            name='payeremail'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="cantidad" className="form-label">Cantidad</label>
                                        <Field onChange={handleChange} type="text" pattern="[0-9]*" className="form-control" name="cantidad" placeholder="19.20"/>
                                        <ErrorMessage
                                            name='cantidad'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="pre_ind" className="form-label">Precio individual</label>
                                        <Field onChange={handleChange2} type="text" pattern="^\d*(\.\d{0,2})?$" className="form-control" name="pre_ind" placeholder="19.20"/>
                                        <ErrorMessage
                                            name='pre_ind'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                        <label htmlFor="software" className="form-label">Software</label>
                                        <Field as="select" className="form-select" name="software">
                                            {
                                                softwares.map((element,index) => {
                                                    return <option
                                                                value={element.id} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                                })
                                            }
                                        </Field>
                                        <ErrorMessage
                                            name='software'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    {/* FIN Datos de usuario */}
                                    <hr/>
                                    
                                    <div className="my-4">
                                        <button type="submit" 
                                                className="btn btn-dark w-100 fw-bold text-uppercase"
                                                disabled={carga}
                                                // onClick={sendDataPaypal}
                                        >Resolver conflicto</button>
                                    </div>
                                    {
                                        carga &&
                                        <div className="w-100 text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Form>
                ); 
            }}
        />
    </div>
    )
}

export default Resolver
